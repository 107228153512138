import React, { Component } from "react";
import "./LoginModal.css";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { FACEBOOK_APP_ID, GOOGLE_AUTH_ID } from "../../../../utils/constants";
import { requestHandler } from "../../../../utils/RequestHandler";
import { receiveLogin } from "../../../../store/actions";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AuthModal from "../AuthModal/AuthModal";
import PrimaryAuthInput from "../../../UI/Inputs/PrimaryAuthInput/PrimaryAuthInput";
import TribePrimaryBtn from "../../../UI/Buttons/TribePrimaryBtn/TribePrimaryBtn";
import PrimaryBtn from "../../Buttons/PrimaryBtn/PrimaryBtn";
import CheckBox from "../../../UI/Inputs/CheckBox/CheckBox";
import HorizontalLine from "../../../UI/HorizontalLine/HorizontalLine";
import User from "../../../../Models/User";
import sweetalert from "sweetalert";

class LoginModal extends Component {
  state = {
    activeScreen: 1,
    totalScreens: 2,
    email: "",
    password: "",
    errorMessage: "",
    redirect: null,
    failedWithEmail: null,
    failedLoginAttempts: null,
  };

  onChangeScreen = (toScreen) => {
    const { activeScreen, totalScreens } = this.state;

    if (toScreen === "next" && activeScreen < totalScreens) {
      this.setState({ activeScreen: activeScreen + 1 });
    } else if (toScreen === "prev" && activeScreen > 1) {
      this.setState({ activeScreen: activeScreen - 1 });
    }
  };

  resetPassword = () => {
    if (this.state.email) {
      User.forgotPasswordEmailRequest(this.state.email).then(
        (result) => {
          sweetalert(
            "Resest Password",
            "Reset password link has been sent to your email",
            "success"
          );
          this.props.toggleModal();
        },
        (err) => sweetalert("Info", err.message, "info")
      );
    } else {
      sweetalert("Valid Email", "Enter a valid Email Addresss", "info");
    }
  };

  renderScreen = () => {
    switch (this.state.activeScreen) {
      case 1:
        return this.screenOneHTML();
      case 2:
        return this.screenTwoHTML();
      default:
        return null;
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  signIn = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    if (email === this.state.failedWithEmail) {
      sweetalert(
        "Error",
        "Too many failed login attempts for this user. Please try again later or refresh the page.",
        "error"
      );
      return;
    }

    requestHandler("login", {
      email: email.toLowerCase(),
      password,
      userType: "other",
    })
      .then((result) => {
        console.log(result);
        if (result.status === 200) {
          localStorage.setItem("auth", JSON.stringify(result.data));
          this.props.loginUser(result.data);
          this.setState({
            redirect: `/${this.props.dashboardMode}-dashboard`,
          });
        } else {
          if (result.failedLoginAttempts > 4) {
            this.setState({
              fauledAttempts: result.failedLoginAttempts,
              failedWithEmail: email,
            });

            sweetalert(
              "Error",
              "Too many failed login attempts for this user. Please try again later or refresh the page.",
              "error"
            );
            return;
          }
          sweetalert("Info", result.message, "info");
        }
      })
      .catch((e) => {
        sweetalert(
          "Error",
          "Server side error occured while requesting for login.",
          "error"
        );
      });
  };

  socialMediaLogin = (data, socialNetwork) => {
    requestHandler("socialLogin", { data, socialNetwork }).then(
      (result) => {
        if (result.status === 200) {
          localStorage.setItem("auth", JSON.stringify(result.data));
          this.props.loginUser(result.data);
        } else {
          sweetalert("Error", result.message, "error");
        }
      },
      (e) => {
        console.log("ERROR: ", e);
      }
    );
    return;
  };

  responseFacebook = (response) => {
    console.log("Facebook Login: ", response);
    if (!response.accessToken) return console.log("Login not successful");
    let data = {
      firstName: response.name,
      lastName: "",
      email: response.email ? response.email : "",
      socialId: response.userID,
      socialObj: response,
    };
    this.socialMediaLogin(data, "facebook");
  };

  responseGoogle = (response) => {
    console.log("Google Login: ", response);
    if (!response.accessToken) return console.log("Login not successful");
    let data = {
      firstName: response.profileObj.givenName
        ? response.profileObj.givenName
        : "",
      lastName: response.profileObj.familyName
        ? response.profileObj.familyName
        : "",
      email: response.profileObj.email ? response.profileObj.email : "",
      socialId: response.googleId,
      socialObj: response,
    };
    this.socialMediaLogin(data, "google");
  };

  screenOneHTML = () => {
    const { email, password } = this.state;
    return (
      <form className="LoginForm">
        <div className="wrap-input">
          <PrimaryAuthInput
            id="email"
            value={email}
            onChange={this.onChange}
            borderStyle="rounded"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="wrap-input">
          <PrimaryAuthInput
            id="password"
            value={password}
            onChange={this.onChange}
            borderStyle="rounded"
            type="password"
            placeholder="Password"
          />
        </div>
        <div className="side-options">
          <CheckBox size="sm" label="Remember Me" color="dark" />
          <p onClick={() => this.onChangeScreen("next")} className="forgotLink">
            Forgot Password
          </p>
        </div>
        <TribePrimaryBtn onClick={this.signIn} type="dark">
          Sign In
        </TribePrimaryBtn>
        <HorizontalLine />
        <div className="mb-3">
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            fields="name,email,picture"
            callback={this.responseFacebook}
            render={(renderProps) => (
              <TribePrimaryBtn onClick={renderProps.onClick} type="facebook">
                Sign In with Facebook
              </TribePrimaryBtn>
            )}
          />
        </div>
        <div className="mb-2">
          <GoogleLogin
            clientId={GOOGLE_AUTH_ID}
            render={(renderProps) => (
              <TribePrimaryBtn
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                type="google"
              >
                Sign In with Google
              </TribePrimaryBtn>
            )}
            buttonText="Login"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      </form>
    );
  };

  screenTwoHTML = () => {
    const { email } = this.state;
    return (
      <div className="LoginForm">
        <div className="wrap-input">
          <PrimaryAuthInput
            id="email"
            value={email}
            onChange={this.onChange}
            borderStyle="rounded"
            type="email"
            placeholder="Email"
          />
        </div>

        <TribePrimaryBtn onClick={this.resetPassword} type="dark">
          Reset Password
        </TribePrimaryBtn>
        <p
          style={{ color: "#000", fontSize: "12px", textAlign: "center" }}
          className="mt-3"
        >
          An Email with a Reset Password Link will be sent to your email.
        </p>
        <HorizontalLine />
        <TribePrimaryBtn
          onClick={() => this.onChangeScreen("prev")}
          type="dark"
        >
          Log In
        </TribePrimaryBtn>
      </div>
    );
  };

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <AuthModal
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        title="Sign In to Tribe"
      >
        {this.renderScreen()}
      </AuthModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    user: state.auth.user,
    language: state.language.language,
    dashboardMode: state.dashboard.mode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginUser: (user) => dispatch(receiveLogin(user)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
