import React, { useEffect, useState } from "react";
import "./MarkerFilter.css";
import { Dropdown } from "react-bootstrap";

function MarkerFilter(props) {
  const {
    handleUsers,
    handleCauses,
    handleEnterprises,
    showUsers,
    showCauses,
    showEnterprises,
  } = props;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width < 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  let desktopFilter = (
    <div className="marker-filter">
      <p>
        <span>Show Users</span>
        <input
          type="checkbox"
          id="users"
          name="users"
          value="users"
          onChange={handleUsers}
          checked={showUsers}
        />
      </p>
      <p>
        <span>Show Causes</span>
        <input
          type="checkbox"
          id="causes"
          name="causes"
          value="causes"
          onChange={handleCauses}
          checked={showCauses}
        />
      </p>
      <p>
        <span>Show Enterprises</span>
        <input
          type="checkbox"
          id="enterprises"
          name="enterprises"
          value="enterprises"
          onChange={handleEnterprises}
          checked={showEnterprises}
        />
      </p>
    </div>
  );

  let mobileFilter = (
    <Dropdown>
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        Show
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="filter-mobile">
          <p>
            Show Users
            <input
              type="checkbox"
              id="users"
              name="users"
              value="users"
              onChange={handleUsers}
              checked={showUsers}
            />
          </p>
          <p>
            Show Causes{" "}
            <input
              type="checkbox"
              id="causes"
              name="causes"
              value="causes"
              onChange={handleCauses}
              checked={showCauses}
            />
          </p>
          <p>
            Show Enterprises{" "}
            <input
              type="checkbox"
              id="enterprises"
              name="enterprises"
              value="enterprises"
              onChange={handleEnterprises}
              checked={showEnterprises}
            />
          </p>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );

  return <>{isMobile ? mobileFilter : desktopFilter}</>;
}

export default MarkerFilter;
