import { IMPACT_SCALE_LABELS } from "./constants";

function generateDefaultCustomUrlFromTitle(template) {
  console.log(
    template.split(" ").join("-").split("--").join("-").toLowerCase()
  );
  return template.split(" ").join("-").split("--").join("-").toLowerCase();
}

function createDateAndTimeFromISO(dateTimeInISO, _date, _time, _seconds) {
  try {
    let [date, time] = dateTimeInISO.split("T");
    // eslint-disable-next-line no-unused-vars
    let [hour, minutes, seconds] = time.split(":");
    seconds = seconds.split(".");
    let result = "";
    if (_date) {
      result += date;
    }
    if (_time) {
      result += " " + hour + ":" + minutes;
    }
    if (_seconds) {
      result += seconds;
    }
    return result;
  } catch (e) {
    console.log("createDateAndTimeFromISO", e);
    return dateTimeInISO;
  }
}

function getImpactLabel(key) {
  return IMPACT_SCALE_LABELS[key];
}

function getDefaultImpactLabelsAndValues() {
  const labels = [];
  const values = [];
  for (let k in IMPACT_SCALE_LABELS) {
    labels.push(k);
    values.push(0);
  }
  return [labels, values];
}
export {
  generateDefaultCustomUrlFromTitle,
  createDateAndTimeFromISO,
  getImpactLabel,
  getDefaultImpactLabelsAndValues,
};
