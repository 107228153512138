import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import User from "../../../../Models/User";
import "./ChatUserCard.css";
import UserAvatar from "../../../UserAvatar/index";

const ChatUserCard = (props) => {
  const [opponent, setOpponent] = useState(null);

  useEffect(async () => {
    const { userA, userB } = props.conversation;
    const toGetUser = userA === props.user._id ? userB : userA;
    const user = await User.GetUserCard(toGetUser);
    setOpponent(user);
  }, [props.user]);

  return opponent ? (
    <div className="ChatUserCard" onClick={props.onClick}>
      <div className="wrap-img">
        <UserAvatar avatar={opponent.avatar} />
      </div>
      <div className="wrap-content">
        <div className="box box1">
          <div className="name">{opponent.firstName}</div>
          <div className="last-online">Just Now</div>
        </div>
        <div className="box box2">
          <div className="message">How are you?</div>
        </div>
      </div>
    </div>
  ) : null;
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(ChatUserCard);
