import React, { Component } from "react";
import { connect } from "react-redux";
import sweetalert from "sweetalert";
import Hero from "../../../../Models/Hero";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout/DashboardLayout";
import CharitiesList from "../../../../components/Sections/CharitiesList/CharitiesList";

class ManageEnterprises extends Component {
  state = {
    enterprises: [],
  };

  componentDidMount() {
    this.getEnterprises();
  }

  getEnterprises = () => {
    Hero.fetchEnterprises(this.props.user._id)
      .then((data) => this.setState({ enterprises: data.reverse() }))
      .catch((err) => sweetalert("Error", "Some error occured.", "error"));
  };

  render() {
    return (
      <DashboardLayout>
        <main className="ManageEnterprises">
          <CharitiesList
            title="Manage Enterprises"
            charities={this.state.enterprises}
            campaign="enterprise"
          />
        </main>
      </DashboardLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(ManageEnterprises);
