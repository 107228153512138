import React, { useRef, useState, useEffect, useCallback } from "react";
import "./StoryForm.css";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";
import { tinyCloudAPIKey } from "../../../../utils/constants";
import PrimaryBtn from "../../Buttons/PrimaryBtn/PrimaryBtn";
import LabelledInput from "../../Inputs/LabelledInput/LabelledInput";
import placeholder from "../../../../../src/assets/images/image-placehoder.png";
import { useDropzone } from "react-dropzone";

const StoryForm = (props) => {
  // drop zone
  const [file, setFile] = useState([]);
  const { onFileInputChange } = props;

  useEffect(() => {
    onFileInputChange(file);
  }, [file]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/",
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
      onFileInputChange(file);
    },
  });

  const images = file.map((image) => (
    <div key={image.name}>
      <div className="preview-image">
        <img src={image.preview} alt={image.name} />
      </div>
    </div>
  ));

  return (
    <div className="StoryForm">
      <div className="story-cover">
        <p className="story-titles">Add a Cover photo</p>

        <div {...getRootProps()} class="image-upload-wrap">
          <input {...getInputProps()} />

          {images && images.length !== 0 ? (
            images
          ) : (
            <div>
              <div className="image-placeholder">
                <img src={placeholder} alt="image-placeholder" />
              </div>
              <div className="image-text">
                <p className="drag-text">Drop your image here, or browse</p>
                <p className="image-type">Support: JPG, PNG</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="story-cover">
        <p className="story-titles">Add a Cover photo</p>

        <div onClick={onbtnclick} class="image-upload-wrap">
          <input
            style={{ display: "none" }}
            type="file"
            accept="images/*"
            ref={inputFileref}
            onChange={(event) => {
              props.onFileInputChange(event);
            }}
          />
          {previewImage ? (
            <div className="preview-image">
              <img src={previewImage} alt="cover-image-preivew" />
            </div>
          ) : (
            <div>
              <div className="image-placeholder">
                <img src={placeholder} alt="image-placeholder" />
              </div>
              <div className="image-text">
                <p className="drag-text">Drop your image here, or browse</p>
                <p className="image-type">Support: JPG, PNG</p>
              </div>
            </div>
          )}
        </div>
      </div> */}
      <div className="story-editor">
        <p className="story-titles">Write a Story</p>
        <Editor
          id="story"
          name="story"
          apiKey={tinyCloudAPIKey}
          init={{
            height: 300,
            menubar: false,
            placeholder: "Write your Story Here",
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar: `undo redo | formatselect | bold italic | 
                                        alignleft aligncenter alignright alignjustify | 
                                        bullist numlist outdent indent | removeformat | help `,
          }}
          onEditorChange={props.storyChangeHandler}
          value={props.story}
        />
      </div>
      <div className="add-url">
        <p className="story-titles">Edit your page link</p>
        <div className="input-wrap">
          <p>tribeworld.com/</p>

          <LabelledInput
            dashboardMode={props.dashboardMode}
            inputType="input"
            size="fullWidth"
            label=""
            input={{
              type: "text",
              placeholder: "Your page link here",
              name: "link",
              onChange: props.inputChangeHandler,
              value: props.inputs.link,
            }}
          />
        </div>
      </div>
      {props.for === "creation" ? (
        <div className="btn-wrapper">
          <span>
            <PrimaryBtn
              type="button"
              size="md"
              color="tranparentLinearBlue"
              onClick={() => props.changeStep("prev")}
            >
              Previous
            </PrimaryBtn>
          </span>
          <span>
            <PrimaryBtn
              type="button"
              size="md"
              color="LinearBlue"
              onClick={() => props.changeStep("next")}
            >
              Next Step
            </PrimaryBtn>
          </span>
        </div>
      ) : props.for === "edit" ? (
        <div className="btn-wrapper">
          <PrimaryBtn type="button" size="md" color="yellow">
            Edit
          </PrimaryBtn>
        </div>
      ) : null}
    </div>
  );
};

StoryForm.propTypes = {
  for: PropTypes.oneOf(["creation", "edit"]).isRequired,
  changeStep: PropTypes.func,
  onEditHandler: PropTypes.func,
};

export default StoryForm;
