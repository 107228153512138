import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import LabelledInput from "../../../../UI/Inputs/LabelledInput/LabelledInput";
import PrimaryBtn from "../../../../UI/Buttons/PrimaryBtn/PrimaryBtn";
import sweetalert from "sweetalert";
import { requestHandler } from "../../../../../utils/RequestHandler";

function RewardModals(props) {
  const {
    enterpriseId,
    id,
    rewardInputs,
    showRewardModal,
    setshowRewardModal,
    showRewardEditModal,
    setshowRewardEditModal,
    addReward,
    inputChangeHandler,
    getRewards,
  } = props;

  const [selectedReward, setseletecReward] = useState();
  const [updateRewardInputs, setupdateRewardInputs] = useState({
    title: "",
    price: "",
    retailPrice: "",
    description: "",
    quantity: "",
    perkItem: "",
    enterpriseId: enterpriseId,
    deliveryDays: "",
  });

  const getSingleReward = () => {
    if (id) {
      return requestHandler("getRewards", { id })
        .then((res) => {
          if (res.status === 200) {
            setseletecReward(res.data);
          }
        })
        .catch((err) => {
          sweetalert("Error", "Error  while fetching Reward", "error");
        });
    }
  };

  const inputHandler = (event) => {
    const { name, value } = event.target;
    setupdateRewardInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const updateReward = () => {
    if (
      updateRewardInputs.title !== "" &&
      updateRewardInputs.price !== "" &&
      // updateRewardInputs.perkItem !== "" &&
      updateRewardInputs.retailPrice !== "" &&
      updateRewardInputs.description !== "" &&
      updateRewardInputs.quantity !== "" &&
      updateRewardInputs.deliveryDays !== ""
    ) {
      requestHandler("editReward", updateRewardInputs, id).then((res) => {
        if (res.status === 200) {
          sweetalert("Success", "Reward Updated", "success");
          setshowRewardEditModal(false);
          getRewards();
        }
      });
    } else {
      sweetalert("Error", "Kindly Fill the required Fields.", "error");
    }
  };
  useEffect(() => {
    getSingleReward();
  }, [id, enterpriseId]);

  useEffect(() => {
    if (selectedReward) {
      setupdateRewardInputs({
        title: selectedReward["0"].title,
        price: selectedReward["0"].price,
        retailPrice: selectedReward["0"].retailPrice,
        description: selectedReward["0"].description,
        quantity: selectedReward["0"].quantity,
        // perkItem: selectedReward["0"].perkItem,
        deliveryDays: selectedReward["0"].deliveryDays,
      });
    }
  }, [selectedReward, enterpriseId]);

  // let productStage = null;
  // if (props.productStage) {
  //   productStage = JSON.parse(props.productStage);
  // }

  // console.log(productStage);
  return (
    <div>
      {/* Add Reward Modal */}
      <Modal
        show={showRewardModal}
        onHide={() => {
          setshowRewardModal(false);
        }}
      >
        <Modal.Body>
          <div className="addReward-modal">
            <div className="modal-top">
              <p className="modal-title">Add Reward</p>
              <button
                onClick={() => {
                  setshowRewardModal(false);
                }}
                className="close-btn"
              >
                X
              </button>
            </div>
            <div className="modal-main">
              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  label="Title"
                  input={{
                    type: "text",
                    placeholder: "Title",
                    name: "title",
                    value: rewardInputs.title,
                    onChange: inputChangeHandler,
                  }}
                />
              </div>
              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  label="Price"
                  inputIcon="inputIcon"
                  input={{
                    type: "number",
                    placeholder: "Price",
                    name: "price",
                    value: rewardInputs.price,
                    onChange: inputChangeHandler,
                  }}
                />
              </div>
              {/* <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="select"
                  label="Select Item for the Perk"
                  input={{
                    placeholder: "Select Item",
                    name: "perkItem",
                    value: rewardInputs.perkItem,
                    onChange: inputChangeHandler,
                  }}
                >
                  <option value="" disabled>
                    Select Perk Item
                  </option>
                  <option value="item 1">Item 1</option>
                  <option value="item 2">Item 2</option>
                  <option value="item 3">Item 3</option>
                </LabelledInput>
              </div> */}
              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  label="Retail Price"
                  inputIcon="inputIcon"
                  input={{
                    type: "number",
                    placeholder: "Retail Price",
                    name: "retailPrice",
                    value: rewardInputs.retailPrice,
                    onChange: inputChangeHandler,
                  }}
                />
              </div>

              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="textarea"
                  label="Short Description"
                  input={{
                    type: "text",
                    placeholder: "Description",
                    name: "description",
                    value: rewardInputs.description,
                    onChange: inputChangeHandler,
                  }}
                />
              </div>
              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  label="Quantity"
                  input={{
                    type: "number",
                    placeholder: "Quantity",
                    name: "quantity",
                    value: rewardInputs.quantity,
                    onChange: inputChangeHandler,
                  }}
                />
              </div>
              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  label="Estimated Delivery Time(in Days)"
                  input={{
                    type: "number",
                    placeholder: "Number in Days",
                    name: "deliveryDays",
                    value: rewardInputs.deliveryDays,
                    onChange: inputChangeHandler,
                  }}
                />
              </div>
              {/* {productStage ? (
                productStage.label ? (
                  productStage.label.toLowerCase() === "shipping" ? null : (
                    <div className="reward-input-wrap">
                      <LabelledInput
                        size="fullWidth"
                        inputType="input"
                        label="Estimated Delivery Time(in Days)"
                        input={{
                          type: "number",
                          placeholder: "Number in Days",
                          name: "deliveryDays",
                          value: rewardInputs.deliveryDays,
                          onChange: inputChangeHandler,
                        }}
                      />
                    </div>
                  )
                ) : productStage.toLowerCase() === "shipping" ? null : (
                  <div className="reward-input-wrap">
                    <LabelledInput
                      size="fullWidth"
                      inputType="input"
                      label="Estimated Delivery Time(in Days)"
                      input={{
                        type: "number",
                        placeholder: "Number in Days",
                        name: "deliveryDays",
                        value: rewardInputs.deliveryDays,
                        onChange: inputChangeHandler,
                      }}
                    />
                  </div>
                )
              ) : null} */}
            </div>
            <div className="save-changes-btn">
              <PrimaryBtn
                type="button"
                size="md"
                color="LinearBlue"
                onClick={addReward}
                // onClick={() => console.log(rewardInputs)}
              >
                Submit
              </PrimaryBtn>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Add Reward Modal */}
      {/* Edit Reward Modal */}
      <Modal
        show={showRewardEditModal}
        onHide={() => {
          setshowRewardEditModal(false);
        }}
      >
        <Modal.Body>
          <div className="addReward-modal">
            <div className="modal-top">
              <p className="modal-title">Edit Reward</p>
              <button
                className="close-btn"
                onClick={() => {
                  setshowRewardEditModal(false);
                }}
              >
                X
              </button>
            </div>
            <div className="modal-main">
              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  label="Title"
                  input={{
                    type: "text",
                    placeholder: "Title",
                    name: "title",
                    value: updateRewardInputs.title,
                    onChange: inputHandler,
                  }}
                />
              </div>
              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  label="Price"
                  inputIcon="inputIcon"
                  input={{
                    type: "number",
                    placeholder: "Price",
                    name: "price",
                    value: updateRewardInputs.price,
                    onChange: inputHandler,
                  }}
                />
              </div>
              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="select"
                  label="Select Item for the Perk"
                  input={{
                    placeholder: "Select Item",
                    name: "perkItem",
                    value: updateRewardInputs.perkItem,
                    onChange: inputHandler,
                  }}
                >
                  <option value="" disabled>
                    Select Perk Item
                  </option>
                  <option value="item 1">Item 1</option>
                  <option value="item 2">Item 2</option>
                  <option value="item 3">Item 3</option>
                </LabelledInput>
              </div>
              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  label="Retail Price"
                  inputIcon="inputIcon"
                  input={{
                    type: "number",
                    placeholder: "Retail Price",
                    name: "retailPrice",
                    value: updateRewardInputs.retailPrice,
                    onChange: inputHandler,
                  }}
                />
              </div>

              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="textarea"
                  label="Short Description"
                  input={{
                    type: "text",
                    placeholder: "Description",
                    name: "description",
                    value: updateRewardInputs.description,
                    onChange: inputHandler,
                  }}
                />
              </div>
              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  label="Quantity"
                  input={{
                    type: "number",
                    placeholder: "Quantity",
                    name: "quantity",
                    value: updateRewardInputs.quantity,
                    onChange: inputHandler,
                  }}
                />
              </div>
              <div className="reward-input-wrap">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  label="Estimated Delivery Time(in Days)"
                  input={{
                    type: "number",
                    placeholder: "Number in Days",
                    name: "deliveryDays",
                    value: updateRewardInputs.deliveryDays,
                    onChange: inputHandler,
                  }}
                />
              </div>
            </div>
            <div className="save-changes-btn">
              <PrimaryBtn
                type="button"
                size="md"
                color="LinearBlue"
                onClick={updateReward}
                // onClick={() => console.log(rewardInputs)}
              >
                Save Changes
              </PrimaryBtn>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Edit Reward Modal */}
    </div>
  );
}

export default RewardModals;
