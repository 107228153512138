import React, { Component } from "react";
import { connect } from "react-redux";
import {
  receiveLogin as loginUser,
  setDashboardMode,
} from "../../../../store/actions";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout/DashboardLayout";
import EnterpriseForm from "../../../../components/UI/Forms/EnterpriseForm/EnterpriseForm";
import sweetalert from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import Hero from "../../../../Models/Hero";
import UserModel from "../../../../Models/User";
import Navbar from "../../../../components/Sections/Navbar/Navbar";
import { requestHandler } from "../../../../utils/RequestHandler";
import { Redirect } from "react-router-dom";

class CreateEnterprise extends Component {
  state = {
    stepOneInputs: {},
    stepTwoInputs: {
      faqs: [],
    },
    stepThreeInputs: {},
    stepFourInputs: {},
    heroFlowStepInputs: {},
    productStageInputs: {
      files: [],
    },
    story: "",
    content: [],
    image: "",
    productStageContent: [],
    password: "",
    failedWithEmail: null,
    failedLoginAttempts: null,
  };

  generatePassword = () => {
    var length = 12,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    this.setState({
      password: retVal,
    });
  };

  componentDidMount() {
    this.generatePassword();
  }

  inputChangeHandler = (stepInputs, event) => {
    this.setState({
      ...this.state,
      [stepInputs]: {
        ...this.state[stepInputs],
        [event.target.name]: event.target.value,
      },
    });
  };

  storyChangeHandler = (content) => {
    this.setState({ story: content });
  };

  createFaqHandler = () => {
    const { question, answer } = this.state.stepTwoInputs;

    if (question && answer) {
      const newFaq = { id: uuidv4(), question, answer };

      this.setState({
        ...this.state,
        stepTwoInputs: {
          ...this.state.stepTwoInputs,
          faqs: [...this.state.stepTwoInputs.faqs, newFaq],
          question: "",
          answer: "",
        },
      });
    } else {
      sweetalert("Error", "The input fields are required.", "error");
    }
  };

  editFaqHandler = (id) => {
    this.deleteFaqHandler(id).then(({ question, answer }) => {
      this.setState({
        stepTwoInputs: {
          ...this.state.stepTwoInputs,
          question,
          answer,
        },
      });
    });
  };

  deleteFaqHandler = (id) => {
    const faqs = [];
    let deleted = null;
    this.state.stepTwoInputs.faqs.forEach((e) =>
      e.id !== id ? faqs.push(e) : (deleted = e)
    );
    return new Promise((resolve) => {
      this.setState(
        {
          stepTwoInputs: {
            ...this.state.stepTwoInputs,
            faqs,
          },
        },
        () => resolve(deleted)
      );
    });
  };

  // onFileInputChange = (event) => {
  //   this.setState({ content: event.target.files });
  //   const file = event.target.files[0];
  //   if (file) {
  //     this.setState({ image: file });
  //   } else {
  //     this.setState({ image: null });
  //   }
  // };

  onFileInputChange = (file) => {
    this.setState({ content: file });
    console.log(this.state.content);
  };

  productStageFileChange = (file) => {
    this.setState({ productStageContent: file });
  };

  signInRegistration = (email, password) => {
    return requestHandler("login", {
      email: email.toLowerCase(),
      password,
      userType: "other",
    }).then(
      (result) => {
        if (result.status === 200) {
          localStorage.setItem("auth", JSON.stringify(result.data));
          this.props.loginUser(result.data);
          this.createEnterprise();
        } else {
          if (result.failedLoginAttempts > 4) {
            sweetalert(
              "Error",
              "Too many failed login attempts for this user. Please try again later or refresh the page",
              "error"
            );
            return;
          }
          sweetalert("Info", result.message, "info");
        }
      },
      (e) => {
        console.log("ERROR: ", e);
      }
    );
  };

  createWithSignIn = () => {
    const { heroFlowStepInputs } = this.state;
    const { email, password } = heroFlowStepInputs;
    if (email === this.state.failedWithEmail) {
      sweetalert(
        "Error",
        "Too many failed login attempts for this user. Please try again later or refresh the page.",
        "error"
      );
      return;
    }
    if (email && password) {
      requestHandler("login", {
        email: email.toLowerCase(),
        password,
        userType: "other",
      })
        .then(async (result) => {
          console.log(result);
          if (result.status === 200) {
            localStorage.setItem("auth", JSON.stringify(result.data));
            await this.props.loginUser(result.data);

            this.createEnterprise();
          } else {
            if (result.failedLoginAttempts > 4) {
              this.setState({
                fauledAttempts: result.failedLoginAttempts,
                failedWithEmail: email,
              });

              sweetalert(
                "Error",
                "Too many failed login attempts for this user. Please try again later or refresh the page.",
                "error"
              );
              return;
            }
            sweetalert("Info", result.message, "info");
          }
        })
        .catch((e) => {
          sweetalert(
            "Error",
            "Server side error occured while requesting for login.",
            "error"
          );
        });
    } else {
      sweetalert("Error", "Kindly fill the required fields.", "error");
    }
  };

  createWithRegistration = () => {
    const { heroFlowStepInputs } = this.state;
    const { firstName, lastName, email } = heroFlowStepInputs;

    const newUser = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: this.state.password,
      terms: true,
      promotionalEmails: true,
    };
    if (firstName && lastName && email) {
      UserModel.registerUser(newUser)
        .then(async (result) => {
          if (result.status === 200) {
            await this.signInRegistration(newUser.email, newUser.password);
          } else {
            sweetalert("Error", result.message, "error");
          }
        })
        .catch((err) => {
          sweetalert(
            "Error",
            "Server side error occured while registering user",
            "error"
          );
        });
    } else {
      sweetalert("Error", "Kindly fill the required fields.", "warning");
    }
  };

  createEnterprise = () => {
    if (this.props.isAuthenticated) {
      const { stepOneInputs, stepTwoInputs, stepThreeInputs, stepFourInputs } =
        this.state;

      const {
        category,
        goal,
        lat,
        lng,
        location,
        description,
        title,
        duration,
      } = stepOneInputs;

      const { link } = stepThreeInputs;

      const customUrl = link; // this needs implementation in ui

      Hero.createEnterprise(
        title,
        goal,
        customUrl,
        category,
        location,
        [lng, lat],
        this.state.story,
        duration,
        description,
        this.state.productStageInputs,
        stepTwoInputs.faqs,
        stepFourInputs,
        this.state.content
      ).then(
        (result) => {
          console.log(result);
          sweetalert("Success", "Enterprise Added", "success");
          this.props.history.push("/hero-manageenterprises");
        },
        (err) => sweetalert("Info", err.message, "info")
      );
    } else {
      this.toggleUserModal();
    }
  };

  render() {
    return (
      <>
        {this.props.isAuthenticated ? (
          <DashboardLayout>
            <main className="CreateEnterprise">
              <div className="cause-mobile-only"></div>
              <div className="createcause-wrap row">
                <div className="col-12 col-lg-8 createCause-left">
                  <EnterpriseForm
                    dashboardMode={this.props.dashboardMode}
                    isAuthenticated={this.props.isAuthenticated}
                    stepOneInputs={this.state.stepOneInputs}
                    productStageInputs={this.state.productStageInputs}
                    stepTwoInputs={this.state.stepTwoInputs}
                    stepThreeInputs={this.state.stepThreeInputs}
                    stepFourInputs={this.state.stepFourInputs}
                    inputChangeHandler={this.inputChangeHandler}
                    onFileInputChange={this.onFileInputChange}
                    productStageFileChange={this.productStageFileChange}
                    content={this.state.content}
                    productStageContent={this.state.productStageContent}
                    productStageFile={this.state.productStageFile}
                    image={this.state.image}
                    createFaqHandler={this.createFaqHandler}
                    editFaqHandler={this.editFaqHandler}
                    deleteFaqHandler={this.deleteFaqHandler}
                    onSubmitHandler={this.createEnterprise}
                    storyChangeHandler={this.storyChangeHandler}
                    story={this.state.story}
                  />
                </div>
                <div className="col-4 createCause-right"></div>
              </div>
            </main>
          </DashboardLayout>
        ) : (
          <div className="notlogged-detailsPage">
            <div className="navbar-wrap">
              <Navbar mode="dark" />
            </div>
            <main className="CreateEnterprise">
              <div className="cause-mobile-only"></div>
              <div className="createcause-wrap row">
                <div className="col-12 col-lg-8 createCause-left">
                  <EnterpriseForm
                    dashboardMode={this.props.dashboardMode}
                    isAuthenticated={this.props.isAuthenticated}
                    stepOneInputs={this.state.stepOneInputs}
                    productStageInputs={this.state.productStageInputs}
                    stepTwoInputs={this.state.stepTwoInputs}
                    storyChangeHandler={this.storyChangeHandler}
                    story={this.state.story}
                    stepThreeInputs={this.state.stepThreeInputs}
                    stepFourInputs={this.state.stepFourInputs}
                    heroFlowStepInputs={this.state.heroFlowStepInputs}
                    inputChangeHandler={this.inputChangeHandler}
                    onFileInputChange={this.onFileInputChange}
                    productStageFileChange={this.productStageFileChange}
                    content={this.state.content}
                    productStageContent={this.state.productStageContent}
                    productStageFile={this.state.productStageFile}
                    image={this.state.image}
                    createFaqHandler={this.createFaqHandler}
                    editFaqHandler={this.editFaqHandler}
                    deleteFaqHandler={this.deleteFaqHandler}
                    onSubmitHandler={this.createEnterprise}
                    createWithSignIn={this.createWithSignIn}
                    createWithRegistration={this.createWithRegistration}
                  />
                </div>
                <div className="col-4 createCause-right"></div>
              </div>
            </main>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    dashboardMode: state.dashboard.mode,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginUser: (user) => dispatch(loginUser(user)),
    setDashboardMode: (dashboardMode) => {
      localStorage.setItem("dashboardMode", JSON.stringify({ dashboardMode }));
      dispatch(setDashboardMode(dashboardMode));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEnterprise);
