import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout/DashboardLayout";
import { connect } from "react-redux";
import UserProfileCard from "../../../../components/Dashboard/UserProfileCard/UserProfileCard";
import WorldImpact from "../../../../components/Dashboard/PreviewDetails/WorldImpact/WorldImpact";
import "./HeroProfile.css";
import Hero from "../../../../Models/Hero";
import sweetalert from "sweetalert";
import User from "../../../../Models/User";
import Navbar from "../../../../components/Sections/Navbar/Navbar";
import ProjectCard from "../../../../components/UI/Cards/ProjectCard/ProjectCard";
import HeroCauses from "../HeroCauses/HeroCauses";
import HeroEnterprises from "../HeroEnterprises/HeroEnterprises";
import Title from "../../../../components/UI/Typography/Title/Title";

function HeroProfile(props) {
  const [enterprises, setEnterprises] = useState();
  const [causes, setCauses] = useState();
  const [userCard, setuserCard] = useState();
  const [heroCauses, setHeroCauses] = useState(false);
  const [heroEnterprises, setHeroEnterprises] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [isCause, setIsCause] = useState(true);

  let userId = props.match.params.id.trim();

  //get hero card for avatar
  useEffect(() => {
    User.GetUserCard(userId.trim()).then(
      (result) => setuserCard(result),
      (err) => alert(err)
    );
  }, [userId]);

  useEffect(() => {
    if (window.screen.width < 991) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, []);

  const getEnterprises = () => {
    Hero.fetchEnterprises(userId.trim())
      .then((res) => setEnterprises(res))
      .catch((err) =>
        sweetalert(
          "Error",
          "Error occured while fetching user enterprises.",
          "error"
        )
      );
  };

  const getCauses = () => {
    Hero.fetchCauses(userId.trim())
      .then((res) => setCauses(res))
      .catch((err) =>
        sweetalert(
          "Error",
          "Error occured while fetching user causes.",
          "error"
        ),
      );
  };

  
  useEffect(() => {
    getEnterprises();
    getCauses();
  }, [userId]);


  let limitedCauses = null;
  if (causes) {
    if (mobileView) {
      limitedCauses = causes;
    } else {
      limitedCauses = causes.slice(0, 3);
    }
  }

  let limitedEnterprises = null;
  if (enterprises) {
    if (mobileView) {
      limitedEnterprises = enterprises;
    } else {
      limitedEnterprises = enterprises.slice(0, 3);
    }
  }

  let desktopCampaigns = (
    <div className="desktopCampaigns" style={{ minHeight: "60vh" }}>
      {/* causes */}
      <div className="user_campaigns " style={{ marginBottom: "1rem" }}>
        <div className="campaigns_top">
          <p>Hero's Causes</p>
          <button onClick={() => setHeroCauses(true)}>View More</button>
        </div>
        <main className="campaigns-container">
          <div className="row">
            {limitedCauses !== null && limitedCauses.length !== 0 ? (
              limitedCauses.map((cause) => (
                <div className="col card-wrapper" key={cause._id}>
                  <ProjectCard for="hero" charity={cause} campaign="cause" />
                </div>
              ))
            ) : (
              <Title size="sm" color="blue" isResponsive="true">
                This Hero is yet to create a Cause
              </Title>
            )}
          </div>
        </main>
      </div>
      {/* Enterprises */}
      <div className="user_campaigns" style={{ marginBottom: "1rem" }}>
        <div div className="campaigns_top">
          <p>Hero's Enterprises</p>
          <button onClick={() => setHeroEnterprises(true)}>View More</button>
        </div>
        <main className="campaigns-container">
          <div className="row">
            {limitedEnterprises !== null && limitedEnterprises.length !== 0 ? (
              limitedEnterprises.map((enterprise) => (
                <div className="col card-wrapper" key={enterprise._id}>
                  <ProjectCard
                    for="hero"
                    charity={enterprise}
                    campaign="enterprise"
                  />
                </div>
              ))
            ) : (
              <Title size="sm" color="blue" isResponsive="true">
                This Hero is yet to create an Enterprise
              </Title>
            )}
          </div>
        </main>
      </div>
    </div>
  );

  let mobileCampaigns = (
    <div className="mobile-only ">
      <div className="top-buttons d-flex justify-content-around">
        <p
          className={`${isCause ? "active" : ""}`}
          onClick={() => setIsCause(true)}
        >
          Causes({causes ? causes.length : ""})
        </p>
        <p
          className={`${isCause ? "" : "active"}`}
          onClick={() => setIsCause(false)}
        >
          Enterprises({enterprises ? enterprises.length : ""})
        </p>
      </div>
      {/* causes */}
      <main className={`campaigns-container ${isCause ? "active" : ""}`}>
        <div className="row">
          {causes && causes.length !== 0 ? (
            causes.map((cause) => (
              <div className="col card-wrapper mb-4 " key={cause._id}>
                <ProjectCard for="hero" charity={cause} campaign="cause" />
              </div>
            ))
          ) : (
            <Title size="sm" color="blue" isResponsive="true">
              This Hero is yet to create a Cause
            </Title>
          )}
        </div>
      </main>
      {/* enterprises */}
      <main className={`campaigns-container ${isCause ? "" : "active"}`}>
        <div className="row">
          {enterprises && enterprises.length !== 0 ? (
            enterprises.map((cause) => (
              <div className="col card-wrapper mb-4" key={cause._id}>
                <ProjectCard for="hero" charity={cause} campaign="cause" />
              </div>
            ))
          ) : (
            <Title size="sm" color="blue" isResponsive="true">
              This Hero is yet to create an Enterprise
            </Title>
          )}
        </div>
      </main>
    </div>
  );

  return (
    <>
      <HeroCauses
        heroCauses={heroCauses}
        setHeroCauses={setHeroCauses}
        causes={causes}
        hero={userCard}
        isAuthenticated={props.isAuthenticated}
      />
      <HeroEnterprises
        heroEnterprises={heroEnterprises}
        setHeroEnterprises={setHeroEnterprises}
        enterprises={enterprises}
        hero={userCard}
        isAuthenticated={props.isAuthenticated}
      />
      {props.isAuthenticated ? (
        <DashboardLayout for="heroProfile">
          <div className="hero_profile_container row">
            <div
              className="col-lg-4 col-md-12 "
              style={{ marginBottom: "1rem" }}
            >
              <UserProfileCard
                userCard={userCard}
                isAuthenticated={props.isAuthenticated}
              />
            </div>
            <div
              className="col-lg-8 col-md-12"
              style={{ marginBottom: "1rem" }}
            >
              <WorldImpact />
            </div>
            {/* campaigns */}
            {mobileView ? mobileCampaigns : desktopCampaigns}
          </div>
        </DashboardLayout>
      ) : (
        <div className="notlogged-detailsPage">
          <div className="navbar-wrap">
            <Navbar mode="dark" />
          </div>
          <div className="hero_profile_container row">
            <div
              className="col-lg-4 col-md-12 "
              style={{ marginBottom: "1rem" }}
            >
              <UserProfileCard userCard={userCard} />
            </div>
            <div
              className="col-lg-8 col-md-12"
              style={{ marginBottom: "1rem" }}
            >
              <WorldImpact />
            </div>
            {/* campaigns */}
            {mobileView ? mobileCampaigns : desktopCampaigns}
          </div>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    dashboardMode: state.dashboard.mode,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(HeroProfile);
