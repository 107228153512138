import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import UserAvatar from "../../../../../components/UserAvatar";
import PrimaryBtn from "../../../../UI/Buttons/PrimaryBtn/PrimaryBtn";
import { Link } from "react-router-dom";

function HeroDetails({ heroCard }) {
  return (
    <div className="hero-details">
      <div className="hero-info row d-flex justify-content-center text-center">
        <div className="hero-avatar">
          <UserAvatar avatar={heroCard.avatar} />
        </div>
        <p className="hero-name">
          {heroCard.firstName} {heroCard.lastName}
        </p>
      </div>
      <div className="hero-profile">
        <div className="hero-counts">
          <p>{heroCard.charities}</p>
          <p>Causes</p>
        </div>
        <div className="hero-counts">
          <p>{heroCard.enterprises}</p>
          <p>Enterprises</p>
        </div>
      </div>
      <div className="hero-location d-flex justify-content-center">
        Location:{" "}
        <span>
          {heroCard.address ? heroCard.address.location : "City,Country"}
        </span>
      </div>
      <div className="responsibleHero-btns ">
        <div className="profile-btn">
          <PrimaryBtn
            size="md"
            type="link"
            color="tranparentLinearBlue"
            to={`/hero-profile/${heroCard._id}`}
          >
            View Profile
          </PrimaryBtn>
        </div>

        <Link className="location-btn" to="/tribe-world">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="location-btn-icon"
          />
        </Link>
      </div>
    </div>
  );
}

export default HeroDetails;
