import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { requestHandler } from "../../../../../utils/RequestHandler";
import React, { useEffect, useState } from "react";
import "./Donations.css";
import sweetalert from "sweetalert";
import { createDateAndTimeFromISO } from "../../../../../utils/helpers";

function Donations({ campaignId }) {
  const [donations, setdonations] = useState([]);
  console.log(donations);
  const getDonations = (id) => {
    return requestHandler("getDonationsForCharity", { id })
      .then((res) => {
        if (res.status === 200) {
          setdonations(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        sweetalert("Error", "Error occured while fetching donations.", "error");
      });
  };

  useEffect(() => {
    getDonations(campaignId);
  }, [campaignId]);

  return (
    <>
      {/* Desktop */}
      <div className="donations-section desktop">
        <p className="donations-heading ">Last Donations</p>
        <table className="donations-table">
          <tbody>
            <tr className="table-headings">
              <th>
                Name
                <span>
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </th>
              <th>
                Amount
                <span>
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </th>
              <th>
                Date
                <span>
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </th>
              <th>
                Details
                <span>
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </th>
            </tr>
            {donations && donations.length !== 0
              ? donations.map((donation) => {
                  return (
                    <tr className="table-data" key={donation._id}>
                      <td>
                        {donation.donateAnonymously
                          ? "Anonymous"
                          : donation.user
                          ? donation.user.firstName +
                            " " +
                            donation.user.lastName
                          : "Anonymous"}
                      </td>
                      <td>${donation.donationAmount}</td>
                      <td>
                        {createDateAndTimeFromISO(donation.createdAt, true)}
                      </td>
                      <td>
                        {donation.message ? donation.message : "No Message"}{" "}
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
      {/* Mobile */}
      <div className="donations-section mobile">
        {donations.length !== 0 ? (
          donations.map((donation) => {
            return (
              <div className="donation-wrap" key={donation._id}>
                <div className="donation-left">
                  <div className="donation-top">
                    <p className="donar-name">
                      {donation.donateAnonymously
                        ? "Anonymous"
                        : donation.user
                        ? donation.user.firstName + " " + donation.user.lastName
                        : "Anonymous"}
                    </p>
                    <p className="amount-donated">${donation.donationAmount}</p>
                  </div>
                  <div className="donation-bottom">
                    <p className="donar-message">
                      {donation.message ? donation.message : "No Message"}
                    </p>
                    <p className="donation-date">{donation.createdAt}</p>
                  </div>
                </div>
                <div className="donation-right">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            );
          })
        ) : (
          <p>No Donations</p>
        )}
      </div>
    </>
  );
}

export default Donations;
