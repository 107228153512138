import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ChatMessageModel from "../../../../Models/ChatMessage";
import PrimaryBtn from "../../../UI/Buttons/PrimaryBtn/PrimaryBtn";
import ChatMessage from "../../../UI/Cards/ChatMessage/ChatMessage";
import SelectedChatUser from "../../../UI/Cards/SelectedChatUser/SelectedChatUser";
import PrimaryAuthInput from "../../../UI/Inputs/PrimaryAuthInput/PrimaryAuthInput";
import "./ChatTimeline.css";

const ChatTimeline = (props) => {
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");

  useEffect(async () => {
    ChatMessageModel.FetchChatMessages(props.conversation._id)
      .then(setMessages)
      .catch((error) => {
        console.log(error);
        alert("Error while fetching chat messages");
      });
  }, [props.conversation]);

  const sendMessage = () => {
    new ChatMessageModel(null, props.conversation._id, null, text, "text", null)
      .postMessage()
      .then(
        (newMessage) => {
          setText("");
          // messages.push(newMessage)
          setMessages([...messages, newMessage]);
        },
        (error) => {
          console.log({ error });
          alert("Message could not be sent at this moment.");
        }
      );
  };

  const { user, dashboardMode } = props;
  return (
    <div className="ChatTimeline">
      <div className="head">
        <SelectedChatUser conversation={props.conversation} />
      </div>
      <div className="body">
        {messages.map((message) => (
          <ChatMessage
            key={message._id}
            message={message}
            toSide={user._id === message.senderId ? "right" : "left"}
            dashboardMode={dashboardMode}
          />
        ))}
      </div>
      <div className="footer">
        <div className="wrap-input">
          <PrimaryAuthInput
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Write a text message..."
            // borderStyle="rounded"
          />
        </div>
        <div className="wrap-btn">
          <PrimaryBtn
            type="button"
            size="sm"
            color={props.dashboardMode === "angel" ? "blue" : "yellow"}
            onClick={sendMessage}
          >
            Send
          </PrimaryBtn>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    dashboardMode: state.dashboard.mode,
  };
}

export default connect(mapStateToProps)(ChatTimeline);
