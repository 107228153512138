import React, { useState, useEffect, useRef } from "react";
import "./EnterpriseStepOne.css";
import LabelledInput from "../../../Inputs/LabelledInput/LabelledInput";
import LocationAutoComplete from "../../../Inputs/LocationAutoComplete/LocationAutoComplete";
import PrimaryBtn from "../../../Buttons/PrimaryBtn/PrimaryBtn";
import Hero from "../../../../../Models/Hero";
import { Modal } from "react-bootstrap";
import placeholder from "../../../../../assets/images/placeholder-image.png";
import sweetalert from "sweetalert";
import { useDropzone } from "react-dropzone";
import {
  faLightbulb,
  faCogs,
  faBoxOpen,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import IconRadioBtn from "../../../Buttons/IconRadioBtn/IconRadioBtn";

const EnterpriseStepOne = (props) => {
  const inputChangeHandler = (e) => props.inputChangeHandler(e);
  // drop zone
  const [file, setFile] = useState([]);
  const { productStageFileChange } = props;

  useEffect(() => {
    productStageFileChange(file);
  }, [file]);

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
      productStageFileChange(file);
    },
  });

  const images = file.map((image) => (
    <div key={image.name}>
      <div className="preview-image">
        <img src={image.preview} alt={image.name} />
      </div>
    </div>
  ));

  // drop zone ends

  const locationInputChangeHander = (location, latLng) => {
    props.inputChangeHandler({
      target: {
        name: "location",
        value: location,
      },
    });
    if (latLng) {
      const { lat, lng } = latLng;
      props.inputChangeHandler({
        target: {
          name: "lat",
          value: lat,
        },
      });
      props.inputChangeHandler({
        target: {
          name: "lng",
          value: lng,
        },
      });
    }
  };

  const [count, setCount] = useState("0");
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [stage, setStage] = useState();
  let imageInputRef = useRef();

  const textAreaOnChange = (e) => {
    setCount(e.target.value.length);
    inputChangeHandler(e);
  };

  const validateLocation = () => {
    if (props.inputs.lat && props.inputs.lng) {
      props.changeStep("next");
    } else {
      sweetalert(
        "Location",
        "Select a valid Location from the list",
        "warning"
      );
    }
  };

  let ProductStageModal = (
    <div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div className="productStageModal">
            <div className="modal-top">
              <p className="modal-title">Product Stage</p>
              <button
                onClick={() => {
                  setShow(false);
                }}
                className="close-btn"
              >
                X
              </button>
            </div>
            <div className="modal-main-body">
              <div className="input-wrapper">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  readonly
                  input={{
                    type: "text",
                    placeholder: "Product Stage",
                    name: "label",
                    value: stage,
                  }}
                />
              </div>
              <div className="input-wrapper">
                <LabelledInput
                  size="fullWidth"
                  inputType="textarea"
                  label="Description"
                  input={{
                    type: "text",
                    placeholder: "Description",
                    name: "description",
                    onChange: props.productInputChangeHandler,
                  }}
                />
              </div>
              {/* Image upload */}
              <div className="story-cover">
                <p className="story-titles">Add Files</p>

                <div {...getRootProps()} class="enterprise-image-upload-wrap">
                  <input {...getInputProps()} />
                  {images && images.length !== 0 ? (
                    images
                  ) : (
                    <div>
                      <div className="image-placeholder">
                        <img src={placeholder} alt="image-placeholder" />
                      </div>
                      <div className="image-text">
                        <p className="drag-text">
                          Drop your files here, or browse
                        </p>
                        <p className="image-type">
                          Support: JPG, PNG, ZIP, PDF, DOC, TXT{" "}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <p className="fileSize-note">
                <span>Note :</span> File Size shouldn't be greater than 100mb
              </p>
              {/* Image upload */}
            </div>
            <div className="save-changes-btn">
              <PrimaryBtn
                type="button"
                size="md"
                color="LinearBlue"
                onClick={() => setShow(false)}
              >
                Done
              </PrimaryBtn>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );

  useEffect(
    () =>
      Hero.fetchFundraiserCategories().then((categories) =>
        setCategories(categories)
      ),
    []
  );
  if (!props.inputs.category && categories.length) {
    inputChangeHandler({
      target: { value: categories[0]._id, name: "category" },
    });
  }
  return (
    <div className="EnterpriseStepOne">
      <div className="row">
        <div className="input-wrapper col-12 col-md-6">
          <LocationAutoComplete
            setLocatoinAutocomplete={locationInputChangeHander}
            location={props.inputs.location || ""}
          />
        </div>
        <div className="input-wrapper col-12 col-md-6">
          <LabelledInput
            size="fullWidth"
            dashboardMode={props.dashboardMode}
            inputType="input"
            label="Goal"
            inputIcon="inputIcon"
            input={{
              type: "number",
              placeholder: "1000",
              name: "goal",
              onChange: inputChangeHandler,
              value: props.inputs.goal,
            }}
          ></LabelledInput>
        </div>
      </div>
      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="input"
          label="Title"
          input={{
            type: "text",
            placeholder: "Enterprise Title",
            name: "title",
            onChange: inputChangeHandler,
            value: props.inputs.title,
          }}
        />
      </div>
      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="select"
          label="Campaign duration"
          input={{
            type: "number",
            placeholder: "Duration of the campaign",
            name: "duration",
            onChange: inputChangeHandler,
            value: props.inputs.duration,
          }}
        >
          <option value="30">30</option>
          <option value="60">60</option>
          <option value="90">90</option>
        </LabelledInput>
      </div>
      <div className="input-wrapper product-stage ">
        {ProductStageModal}
        <p style={{ marginBottom: "0px" }}>
          Select the Stage of your company/product is in
        </p>
        <div className="radiobtns-inputs row">
          <div className="productStage-radio-input col-6  col-lg-3 ">
            <IconRadioBtn
              input={{
                onChange: props.productInputChangeHandler,
              }}
              name="label"
              id="concept"
              label="Concept"
              icon={faLightbulb}
              value="Concept"
              onClick={() => {
                setShow(true);
                setStage("Concept");
              }}
            />
          </div>
          <div className="productStage-radio-input col-6  col-lg-3 ">
            <IconRadioBtn
              input={{
                onChange: props.productInputChangeHandler,
              }}
              name="label"
              id="prototype"
              label="Prototype"
              icon={faCogs}
              value="Prototype"
              onClick={() => {
                setShow(true);
                setStage("Prototype");
              }}
            />
          </div>
          <div className="productStage-radio-input col-6  col-lg-3 ">
            <IconRadioBtn
              input={{
                onChange: props.productInputChangeHandler,
              }}
              name="label"
              id="production"
              label="Production"
              icon={faBoxOpen}
              value="Production"
              onClick={() => {
                setShow(true);
                setStage("Production");
              }}
            />
          </div>
          <div className="productStage-radio-input col-6  col-lg-3 ">
            <IconRadioBtn
              input={{
                onChange: props.productInputChangeHandler,
              }}
              name="label"
              id="shipping"
              label="Shipping"
              icon={faTruck}
              value="Shipping"
              onClick={() => {
                setShow(true);
                setStage("Shipping");
              }}
            />
          </div>
        </div>
      </div>
      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="textarea"
          label="Short Description"
          input={{
            placeholder: "Describe what your Enterprise is about",
            name: "description",
            onChange: textAreaOnChange,
            maxlength: "160",
            value: props.inputs.description,
          }}
        />
        <p className="textarea-count">{count}/160 characters left</p>
      </div>

      {/* file upload */}
      {/* <div className="story-cover">
        <p className="story-titles">Add Files</p>
        <div onClick={fileBrowse} class="enterprise-image-upload-wrap">
          <div className="image-input-field">
            <LabelledInput
              className="input-image"
              size="fullWidth"
              dashboardMode={props.dashboardMode}
              inputType="input"
              ref={inputFileref}
              label=""
              input={{
                type: "file",
                name: "file",
                multiple: true,
                image: "storyImage",
                onChange: props.onFileInputChange,
                ref: imageInputRef,
              }}
            />
          </div>
          <button onClick={onbtnclick}>Click</button> 
          <div className="image-placeholder">
            <img src={placeholder} alt="image-placeholder" />
          </div>
          <div className="image-text">
            <p className="drag-text">Drop your files here, or browse</p>
            <p className="image-type">Support: JPG, PNG, ZIP, PDF, DOC, TXT </p>
          </div>
        </div>
      </div> */}

      {/* file upload */}

      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="select"
          label="Cause Category"
          input={{
            placeholder: "Select a category",
            name: "category",
            onChange: inputChangeHandler,
            value: props.inputs.category,
          }}
        >
          {categories.map((cat) => (
            <option value={cat._id} key={cat._id}>
              {" "}
              {cat.name}{" "}
            </option>
          ))}
        </LabelledInput>
      </div>

      <div className="btn-wrapper">
        <PrimaryBtn
          type="button"
          size="md"
          color="LinearBlue"
          // onClick={() => props.changeStep("next")}
          onClick={validateLocation}
        >
          Next
        </PrimaryBtn>
      </div>
    </div>
  );
};

export default EnterpriseStepOne;
