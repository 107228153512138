import React, { useState } from "react";
import "./UserCardSlider.css";
import PropTypes from "prop-types";
import Slider from "react-slick";
import SectionHeader from "../../../Layouts/SectionHeader/SectionHeader";
import UserCard from "../../../UI/Cards/UserCard/UserCard";
import SliderArrow from "../../../UI/SliderArrow/SliderArrow";

const UserCardSlider = (props) => {
    const [sliderSettings, setSliderSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <SliderArrow direction="right" fromBottom="-30" />,
        prevArrow: <SliderArrow direction="left" fromBottom="-30" />,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });

    return (
        <section className="UserCardSlider mt-5">
            <div className="header">
                <SectionHeader
                    title={props.title}
                    text={props.text}
                    btnText={props.btnText}
                />
            </div>
            <div className="UserCardSlider-body">
                <div className="container">
                    <div className="row">
                        <div className="col mx-auto">
                            <Slider {...sliderSettings}>
                                {props.data
                                    ? props.data.map((record) => (
                                          <UserCard user={record} />
                                      ))
                                    : null}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

UserCardSlider.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    btnText: PropTypes.string.isRequired,
    onBtnClick: PropTypes.func.isRequired,
    data: PropTypes.array,
};

export default UserCardSlider;
