import React, { Component } from "react";
import "./LoginMobile.css";
import $ from "jquery";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FACEBOOK_APP_ID, GOOGLE_AUTH_ID } from "../../../../utils/constants";
import { requestHandler } from "../../../../utils/RequestHandler";
import { receiveLogin } from "../../../../store/actions";
import User from "../../../../Models/User";

// Components
import TribePrimaryBtn from "../../../../components/UI/Buttons/TribePrimaryBtn/TribePrimaryBtn";
import HorizontalLine from "../../../../components/UI/HorizontalLine/HorizontalLine";
import PrimaryAuthInput from "../../../../components/UI/Inputs/PrimaryAuthInput/PrimaryAuthInput";
import Title from "../../../../components/UI/Typography/Title/Title";
import sweetalert from "sweetalert";
import PrimaryBtn from "../../../../components/UI/Buttons/PrimaryBtn/PrimaryBtn";

class LoginMobile extends Component {
  state = {
    activeScreen: 1,
    totalScreens: 2,
    email: "",
    password: "",
    errorMessage: "",
    redirect: null,
    failedWithEmail: null,
    failedLoginAttempts: null,
  };

  onChangeScreen = (toScreen) => {
    const { activeScreen, totalScreens } = this.state;

    if (toScreen === "next" && activeScreen < totalScreens) {
      this.setState({ activeScreen: activeScreen + 1 });
    } else if (toScreen === "prev" && activeScreen > 1) {
      this.setState({ activeScreen: activeScreen - 1 });
    }
  };

  resetPassword = () => {
    if (this.state.email) {
      User.forgotPasswordEmailRequest(this.state.email).then(
        (result) => {
          sweetalert(
            "Resest Password",
            "Reset password link has been sent to your email",
            "success"
          );
          this.props.history.push("/");
        },
        (err) => sweetalert("Info", err.message, "info")
      );
    } else {
      sweetalert("Valid Email", "Enter a valid Email Addresss", "info");
    }
  };

  screenOneHTML = () => {
    const { email, password } = this.state;
    return (
      <div className="form">
        <div className="wrap-input">
          <label htmlFor="email">Email</label>
          <PrimaryAuthInput
            id="email"
            value={email}
            onChange={this.onChange}
            borderStyle="rounded"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="wrap-input">
          <label htmlFor="password">Password</label>
          <PrimaryAuthInput
            id="password"
            value={password}
            onChange={this.onChange}
            borderStyle="rounded"
            type="password"
            placeholder="Password"
          />
        </div>
        <div className="other-options">
          <p
            style={{ color: "#EB982D" }}
            onClick={() => this.onChangeScreen("next")}
          >
            Forgot Password
          </p>

          <div className="login-checkBox">
            <input id="rememberMeCheckbox" type="checkbox" />
            <label htmlFor="rememberMeCheckbox">Remember me</label>
          </div>
        </div>
        <div className="group-buttons">
          <TribePrimaryBtn type="hero" onClick={this.signIn}>
            SIGN IN
          </TribePrimaryBtn>

          <HorizontalLine color="white" />

          <div className="mt-3">
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              fields="name,email,picture"
              callback={this.responseFacebook}
              render={(renderProps) => (
                <TribePrimaryBtn onClick={renderProps.onClick} type="facebook">
                  SIGN IN WITH FACEBOOK
                </TribePrimaryBtn>
              )}
            />
          </div>

          <div className="mt-3">
            <GoogleLogin
              clientId={GOOGLE_AUTH_ID}
              render={(renderProps) => (
                <TribePrimaryBtn
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  type="google"
                >
                  SIGN IN WITH GOOGLE
                </TribePrimaryBtn>
              )}
              buttonText="Login"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </div>
        </div>
      </div>
    );
  };

  screenTwoHTML = () => {
    const { email } = this.state;
    return (
      <div>
        <div className="forgotPassword">
          <PrimaryAuthInput
            id="email"
            value={email}
            onChange={this.onChange}
            borderStyle="rounded"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="btn-wrapper mt-3">
          <PrimaryBtn
            size="md"
            color="transparentYellow"
            onClick={this.resetPassword}
            type="button"
          >
            Reset Password
          </PrimaryBtn>
        </div>
        <p
          style={{ color: "#fff", fontSize: "12px", textAlign: "center" }}
          className="mt-3"
        >
          An Email with a Reset Password Link will be sent to your email.
        </p>

        <HorizontalLine color="white" />

        <div className="">
          <TribePrimaryBtn
            type="newBtn"
            onClick={() => this.onChangeScreen("prev")}
          >
            Log In
          </TribePrimaryBtn>
        </div>
      </div>
    );
  };

  renderScreen = () => {
    switch (this.state.activeScreen) {
      case 1:
        return this.screenOneHTML();
      case 2:
        return this.screenTwoHTML();
      default:
        return null;
    }
  };

  componentDidMount() {
    if (document.body.clientWidth > 992) {
      this.props.history.push("/");
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  signIn = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    if (email === this.state.failedWithEmail) {
      sweetalert(
        "Error",
        "Too many failed login attempts for this user. Please try again later or refresh the page.",
        "error"
      );
      return;
    }

    requestHandler("login", {
      email: email.toLowerCase(),
      password,
      userType: "other",
    })
      .then((result) => {
        console.log(result);
        if (result.status === 200) {
          localStorage.setItem("auth", JSON.stringify(result.data));
          this.props.loginUser(result.data);
          this.setState({
            redirect: `/${this.props.dashboardMode}-createcause`,
          });
        } else {
          if (result.failedLoginAttempts > 4) {
            this.setState({
              fauledAttempts: result.failedLoginAttempts,
              failedWithEmail: email,
            });

            sweetalert(
              "Error",
              "Too many failed login attempts for this user. Please try again later or refresh the page.",
              "error"
            );
            return;
          }
          sweetalert("Info", result.message, "info");
        }
      })
      .catch((e) => {
        sweetalert(
          "Error",
          "Server side error occured while requesting for login.",
          "error"
        );
      });
  };

  socialMediaLogin = (data, socialNetwork) => {
    requestHandler("socialLogin", { data, socialNetwork }).then(
      (result) => {
        if (result.status === 200) {
          localStorage.setItem("auth", JSON.stringify(result.data));
          this.props.loginUser(result.data);
        } else {
          sweetalert("Error", result.message, "error");
        }
      },
      (e) => {
        console.log("ERROR: ", e);
      }
    );
    return;
  };

  responseFacebook = (response) => {
    console.log("Facebook Login: ", response);
    if (!response.accessToken) return console.log("Login not successful");
    let data = {
      firstName: response.name,
      lastName: "",
      email: response.email ? response.email : "",
      socialId: response.userID,
      socialObj: response,
    };
    this.socialMediaLogin(data, "facebook");
  };

  responseGoogle = (response) => {
    console.log("Google Login: ", response);
    if (!response.accessToken) return console.log("Login not successful");
    let data = {
      firstName: response.profileObj.givenName
        ? response.profileObj.givenName
        : "",
      lastName: response.profileObj.familyName
        ? response.profileObj.familyName
        : "",
      email: response.profileObj.email ? response.profileObj.email : "",
      socialId: response.googleId,
      socialObj: response,
    };
    this.socialMediaLogin(data, "google");
  };

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <main className="LoginMobile">
        <section className="LoginMobile-Section">
          <div className="container">
            <div className="row">
              <div className="col-10 col-sm-8 col-md-7 col-lg-6 col-xl-5 mx-auto">
                <div className="LoginMobile-Body">
                  <div className="header">
                    <div className="header-title">
                      <Title size="md" color="white">
                        Sign into tribe
                      </Title>
                    </div>
                    <Link to="/">
                      <div className="header-times">&times;</div>
                    </Link>
                  </div>
                  <div className="form">{this.renderScreen()}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    user: state.auth.user,
    language: state.language.language,
    dashboardMode: state.dashboard.mode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginUser: (user) => dispatch(receiveLogin(user)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginMobile);
