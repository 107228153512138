import React, { useState, useEffect } from "react";
import "./PreviewDetailsMain.css";
import CardImg from "../../../../assets/images/cardImg.png";
import PrimaryBtn from "../../../UI/Buttons/PrimaryBtn/PrimaryBtn";
import ModalEditBtn from "../../../UI/Buttons/ModalEditBtn/ModalEditBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { faEllipsisV, faShareAlt } from "@fortawesome/free-solid-svg-icons";

function PreviewDetailsMain(props) {
  console.log(props.campaign);
  const [btnsize, setbtnsize] = useState("md");

  const cardImg = props.campaign.content
    ? props.campaign.content.length !== 0
      ? props.campaign.content[0]
      : CardImg
    : CardImg;

  const category = props.categories.filter(
    (category) => category._id === props.campaign.categoryId
  );

  useEffect(() => {
    if (window.screen.width < 768) {
      setbtnsize("sm");
    } else {
      setbtnsize("md");
    }
  }, []);

  return (
    <div className="causedetails-main">
      <div className="cause-image">
        <img src={cardImg} alt="cause" />
      </div>
      <div className="causedetails-description">
        <div className="causedescription-top">
          <p className="cause-category">
            {category.length !== 0 ? category["0"].name : "Category"}
          </p>
          <div className="causedescription-buttons">
            <button
              className="button button-share"
              onClick={() => props.setshowShareModal(true)}
            >
              <FontAwesomeIcon icon={faShareAlt} />
            </button>
            <ModalEditBtn
              onClick={() => props.setshowCauseUpdateModal(true)}
              isAuthenticated={props.isAuthenticated}
              userId={props.user._id}
              causeUserId={props.campaign.creatorId}
            />
          </div>
        </div>
        <div className="causedetails-wrap">
          <p className="causedetails-title">{props.campaign.title}</p>
          <p className="causedescription-text">
            {props.campaign.shortDescription}
          </p>
          <p className="cause-location">
            Location : <span>{props.campaign.locationName}</span>
          </p>
        </div>
        <div className="cause-primarybtns">
          <div className="causeBtn-wrapper">
            {props.user._id !== props.campaign.creatorId ? (
              <PrimaryBtn
                type="link"
                size={btnsize}
                color="LinearBlue"
                to={`/campaign/donate/${props.campaign._id}`}
              >
                Donate Now
              </PrimaryBtn>
            ) : null}
          </div>
          <div className="causeBtn-wrapper">
            <PrimaryBtn
              type="link"
              size={btnsize}
              color="tranparentLinearBlue"
              to="/tribe-world"
            >
              Show Map
            </PrimaryBtn>
          </div>
          <div className="causeBtn-wrapper">
            <button className="morebtn-icon">
              <FontAwesomeIcon icon={faEllipsisV} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

PreviewDetailsMain.propTypes = {
  campaign: PropTypes.object.isRequired,
  dashboardMode: PropTypes.string.isRequired,
};

export default PreviewDetailsMain;
