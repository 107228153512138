import React from "react";
import "./IconRadioBtn.css";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./IconRadioBtn";

const IconRadioBtn = (props) => {
  return (
    <div className="icon-radio-btn">
      <input
        {...props.input}
        name={props.name}
        className="radio-tools"
        type="radio"
        value={props.value}
        id={props.id}
        onClick={props.onClick}
      />
      <label htmlFor={props.id} className="for-radio-tools">
        <FontAwesomeIcon className="radio-icon" icon={props.icon} />
        {props.label}
      </label>
    </div>
  );
};

IconRadioBtn.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.object,
};

export default IconRadioBtn;
