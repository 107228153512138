import React, { Component } from "react";
import "./FindCause.css";
import { requestHandler } from "../../../utils/RequestHandler";
import BackdropImg from "../../../assets/images/backdropTest.png";
import Footer from "../../../components/Sections/Footer/Footer";
import NavbarWithBackdrop from "../../../components/Sections/NavbarWithBackdrop/NavbarWithBackdrop";

class FindCause extends Component {
  state = {
    causes: [],
    enterprises: [],
  };

  componentDidMount() {
    const search = new URLSearchParams(this.props.location.search).get(
      "search"
    );

    if (search) {
      this.getCampaigns(search);
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    const currentSearch = new URLSearchParams(this.props.location.search).get(
      "search"
    );
    const prevSearch = new URLSearchParams(prevProps.location.search).get(
      "search"
    );

    if (prevSearch !== currentSearch) {
      if (currentSearch) {
        this.getCampaigns(currentSearch);
      }
    }
  }

  getCampaigns = (title) => {
    requestHandler("searchCampaignsByTitle", { title: title })
      .then((result) => {
        if (result.status === 200) {
          this.setState({
            causes: result.data.charities,
            enterprises: result.data.enterprises,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    // const { enterprises, causes } = this.state;

    return (
      <main className="FindCause">
        <NavbarWithBackdrop
          navbarMode="white"
          backdropImg={BackdropImg}
          text="Find Cause"
          textColor="white"
        />

        <div style={{ height: "100vh", margin: "50px 0px" }}>
          <div className="container">
            <div className="row">
              <div className="col">
                <h2>Causes</h2>
                {/* <Campaigns causes charities={causes} /> */}
              </div>
              <div className="col">
                <h2>Enterprises</h2>
                {/* <Campaigns
									enterprises
									charities={enterprises}
								/> */}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </main>
    );
  }
}

export default FindCause;
