import React, { Component } from "react";
import { connect } from "react-redux";
import Hero from "../../../../Models/Hero";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout/DashboardLayout";
import HeroDashboard from "../../../../components/Sections/HeroDashboard/HeroDashboard";

class Dashboard extends Component {
  state = {
    cause: null,
  };

  componentDidMount() {
    this.getCauses();
  }

  getCauses = () => {
    Hero.fetchCauses(this.props.user._id)
      .then((result) =>
        this.setState({ cause: result.length ? result[0] : null })
      )
      .catch((err) => console.log(err));
  };

  render() {
    console.log(this.state);
    return (
      <DashboardLayout>
        <HeroDashboard cause={this.state.cause} />
      </DashboardLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(Dashboard);
