import React, { Component } from "react";
import { generateCharityDonationReciptPDF } from "../../../../utils/generatePDF";
import Angel from "../../../../Models/Angel";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout/DashboardLayout";
import ResponsiveTable from "../../../../components/UI/ResponsiveTable/ResponsiveTable";
import RefundModal from "../../../../components/UI/Modals/RefundModal/RefundModal";

class Donations extends Component {
  state = {
    headers: [
      "Cause Title",
      "Cause Goal",
      "Donation Amount",
      "Donation for Platform",
      "Total Payment",
      "Date",
      "Actions",
    ],
    records: [],
    showRefunds: false,
  };

  componentDidMount() {
    const refunds = new URLSearchParams(this.props.location.search).get(
      "refunds"
    );
    this.setState({ showRefunds: refunds === "true" ? true : false });
    this.getDonations();
  }

  getDonations = () => {
    Angel.fetchDonations()
      .then((data) => this.setState({ records: data.reverse() }))
      .catch((err) => console.log(err));
  };

  downloadReceiptPDF = (donation) => {
    const { totalPayment, donationForPlatform, donationAmount } = donation;

    generateCharityDonationReciptPDF(
      donation.charity.title,
      donationAmount,
      donationForPlatform,
      totalPayment
    );
  };

  setRefundModal = (donation) => {
    this.setState({
      showRefundModal: donation ? true : false,
      refundDonation: donation,
    });
  };

  render() {
    return (
      <DashboardLayout>
        <main className="Donations" style={{ margin: "0px 40px" }}>
          <ResponsiveTable
            for="angelDonations"
            headers={this.state.headers}
            records={this.state.records}
            showRefunds={this.state.showRefunds}
            setRefundModal={this.setRefundModal}
            downloadReceiptPDF={this.downloadReceiptPDF}
          />
          <RefundModal
            show={this.state.showRefundModal}
            donation={this.state.refundDonation}
            onClose={this.setRefundModal}
            reloadData={this.getDonations}
          />
        </main>
      </DashboardLayout>
    );
  }
}

export default Donations;
