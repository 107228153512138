import React, { Fragment } from "react";
import "./StepsBar.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const StepsBar = (props) => {
  return (
    <Fragment>
      {props.labels && props.labels.length ? (
        <div className={`StepsBar ${props.dashboardMode}`}>
          {window.screen.width > 991 ? (
            <div className="steps-list">
              {props.labels.map((stepLabel, index) => {
                const count = index + 1;

                return (
                  <Fragment key={Math.random()}>
                    <div className="step-item">
                      <div
                        className={`count ${
                          count <= props.currentStep ? "activeCount" : ""
                        }`}
                      >
                        {count >= props.currentStep ? (
                          count
                        ) : (
                          <FontAwesomeIcon icon={faCheck} />
                        )}
                      </div>
                      <div className="label">{stepLabel}</div>
                    </div>
                    {count !== props.labels.length ? (
                      <div
                        className={`divider ${
                          count < props.currentStep
                            ? "completed"
                            : count === props.currentStep
                            ? "activeDivider"
                            : ""
                        }`}
                      ></div>
                    ) : null}
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <div className="steps-list">
              <div className="step-item">
                <div className="count">
                  {/* {props.currentStep}/{props.labels.length} */}
                  {props.currentStep}
                </div>
                <div className="label">
                  {props.labels[props.currentStep - 1]}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </Fragment>
  );
};

StepsBar.propTypes = {
  labels: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    dashboardMode: state.dashboard.mode,
  };
}

export default connect(mapStateToProps)(StepsBar);
