import React from "react";
import "./RadioBtn.css";
import PropTypes from "prop-types";

const RadioBtn = (props) => {
  return (
    <div classNameName="RadioBtn">
      <div className="row radio-wrap">
        <div className="col-lg-6 col-12 radio-heading">{props.heading}</div>
        <div className=" col-lg-6 col-12 ">
          <div className="radiobtns-inputs">
            <div className="input-item">
              <input
                {...props.input}
                name={props.name}
                className="radio-input"
                type="radio"
                value="High"
                id="High"
              />
              <br />
              <label htmlFor="High" className="radio-label">
                High
              </label>
            </div>
            <div className="input-item">
              <input
                {...props.input}
                name={props.name}
                type="radio"
                value="Medium"
                id="Medium"
              />
              <br />
              <label htmlFor="Medium" className="radio-label">
                Medium
              </label>
            </div>
            <div className="input-item">
              <input
                {...props.input}
                name={props.name}
                type="radio"
                value="Low"
                id="Low"
              />
              <br />
              <label htmlFor="Low" className="radio-label">
                Low
              </label>
            </div>
            <div className="input-item">
              <input
                {...props.input}
                name={props.name}
                type="radio"
                value="N/A"
                id="N/A"
              />
              <br />
              <label htmlFor="N/A" className="radio-label">
                N/A
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RadioBtn.prototype = {
  input: PropTypes.object.isRequired,
  dashboardMode: PropTypes.oneOf(["hero", "angel"]),
  heading: PropTypes.string.isRequired,
};

export default RadioBtn;
