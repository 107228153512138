import React, { useState, useEffect } from "react";
import "./CharitiesList.css";
import PropTypes from "prop-types";
import ProjectCard from "../../UI/Cards/ProjectCard/ProjectCard";
import ProjectCardList from "../../UI/Cards/ProjectCardList/ProjectCardList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../UI/Pagination/Pagination";
import {
  faList,
  faThLarge,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";

const CharitiesList = (props) => {
  const { charities } = props;
  let campaigns = charities;

  const [grid, setGrid] = useState(true);
  const [layout, setLayout] = useState();
  const [isReverse, setisReverse] = useState(false);

  useEffect(() => {
    if (window.screen.width < 991) {
      setLayout(false);
    } else {
      setLayout(true);
    }
  }, []);

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setprojectsPerPage] = useState(7);

  // Get current posts
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = campaigns.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const changeLayout = () => {
    setGrid(!grid);
  };

  const sortCampaigns = () => {
    campaigns = campaigns.reverse();
    setisReverse(!isReverse);
  };

  let listLayout = (
    <div className="list-layout">
      <div className="header_row row">
        <div className="headings col-1">
          <span>Category</span>
          <span>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
        <div className="headings col-1">
          <span>Img</span>
          <span>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
        <div className="headings col-2">
          <span>Title</span>
          <span>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
        <div className="headings col-2">
          <span>Location</span>
          <span>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
        <div className="headings col-1">
          <span>Goal</span>
          <span>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
        <div className="headings col-3">
          <span>Achieved</span>
          <span>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
        <div className="headings col-1">
          <span>Comment</span>
          <span>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
        <div className="headings col-1">
          <span>Donates</span>
          <span>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </div>
      <div className="projectCard_list">
        {charities
          ? currentProjects.map((record) => (
              <div className="list-card-wrapper" key={record._id}>
                <ProjectCardList
                  for="hero"
                  charity={record}
                  campaign={props.campaign}
                />
              </div>
            ))
          : null}
      </div>
    </div>
  );

  let gridLayout = (
    <div className="row">
      {campaigns
        ? campaigns.map((record) => (
            <div className="col card-wrapper" key={record._id}>
              <ProjectCard
                for="hero"
                charity={record}
                campaign={props.campaign}
              />
            </div>
          ))
        : null}
    </div>
  );

  return (
    <div className="fluid-container CharitiesList">
      <div className="row">
        <div className="campaign_options">
          <div className="campaign_sorting">
            Sort by{" "}
            <select name="sort" id="sort" form="sort" onChange={sortCampaigns}>
              <option value="recent">Recent</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
          {layout ? (
            <div className="campaign_layout">
              <div
                className={`grid ${grid ? "active" : ""}`}
                onClick={changeLayout}
              >
                <FontAwesomeIcon icon={faThLarge} />
              </div>
              <div
                className={`list ${grid ? "" : "active"}`}
                onClick={changeLayout}
              >
                <FontAwesomeIcon icon={faList} />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="displaying_projects">
          {grid ? gridLayout : listLayout}
        </div>
      </div>
      {grid ? (
        ""
      ) : (
        <div className="pagination-wrapper">
          <Pagination
            projectsPerPage={projectsPerPage}
            totalProjects={charities.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

CharitiesList.propTypes = {
  charities: PropTypes.array.isRequired,
  campaign: PropTypes.oneOf(["enterprise", "cause"]),
};

export default CharitiesList;
