import React from "react";
import "./CauseImpactForm.css";
import PropTypes from "prop-types";
import PrimaryBtn from "../../../Buttons/PrimaryBtn/PrimaryBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import RadioBtn from "../../../Inputs/RadioBtn/RadioBtn";

function CauseImpactForm(props) {
  return (
    <div className="impactForm">
      <div className="impactForm-list">
        <p className="impact-title">
          How do you see your enterprise impacting the world?
        </p>
        <div className="radio-values">
          <ul className="values-list">
            <li>High</li>
            <li>Medium</li>
            <li>Low</li>
            <li>N/A</li>
          </ul>
        </div>
        <div className="impact-questions">
          <RadioBtn
            heading="Poverty, Hunger & Health"
            name="poverty_hunger_and_health"
            input={{
              onChange: props.inputChangeHandler,
              value: props.inputs.poverty_hunger_and_health,
            }}
          />
          <RadioBtn
            heading="Education and Equality"
            name="education_and_equality"
            input={{
              onChange: props.inputChangeHandler,
            }}
          />

          <RadioBtn
            heading="Climate Conservation"
            name="climate_conservation"
            input={{
              onChange: props.inputChangeHandler,
            }}
          />
          <RadioBtn
            heading="clean water and sanitation"
            name="cleanwater_and_sanitation"
            input={{
              onChange: props.inputChangeHandler,
            }}
          />
          <RadioBtn
            heading="Animals,Birds and Plants"
            name="animal_birds_and_plants"
            input={{
              onChange: props.inputChangeHandler,
            }}
          />
          <RadioBtn
            heading="Innovative Growth"
            name="innovative_growth"
            input={{
              onChange: props.inputChangeHandler,
            }}
          />
        </div>
      </div>

      {props.for === "creation" ? (
        <div className="btn-wrapper">
          <span>
            <PrimaryBtn
              type="button"
              size="md"
              color="tranparentLinearBlue"
              onClick={() => props.changeStep("prev")}
            >
              Previous
            </PrimaryBtn>
          </span>
          <span>
            <PrimaryBtn
              type="button"
              size="md"
              color="LinearBlue"
              onClick={
                props.isAuthenticated
                  ? props.onSubmitHandler
                  : () => props.changeStep("next")
              }
            >
              <FontAwesomeIcon icon={faCheck} className="addCause-icon" />
              {props.isAuthenticated ? "Become a Hero" : "Next Step"}
            </PrimaryBtn>
          </span>
        </div>
      ) : props.for === "edit" ? (
        <div className="btn-wrapper">
          <PrimaryBtn
            type="button"
            size="md"
            color="yellow"
            onClick={props.onEditHandler}
          >
            Edit
          </PrimaryBtn>
        </div>
      ) : null}
    </div>
  );
}

CauseImpactForm.propTypes = {
  for: PropTypes.oneOf(["creation", "edit"]).isRequired,
  dashboardMode: PropTypes.string.isRequired,
  inputs: PropTypes.object.isRequired,
  inputChangeHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
};

export default CauseImpactForm;
