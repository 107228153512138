import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./Pagination.css";

const Pagination = (props) => {
  const { projectsPerPage, totalProjects, currentPage, setCurrentPage } = props;
  const [pageNumberLimit, setPageNumberLimit] = useState(4);
  const [maxPageNumber, setmaxPageNumer] = useState(4);
  const [minPageNumber, setMinPageNumber] = useState(0);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumber) {
      setmaxPageNumer(maxPageNumber + pageNumberLimit);
      setMinPageNumber(minPageNumber + pageNumberLimit);
    }
  };

  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumer(maxPageNumber - pageNumberLimit);
      setMinPageNumber(minPageNumber - pageNumberLimit);
    }
  };

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalProjects / projectsPerPage); i++) {
    pageNumbers.push(i);
  }

  const pageNumberList = pageNumbers.map((number) => {
    if (number < maxPageNumber + 1 && number > minPageNumber) {
      return (
        <li
          key={number}
          className={`page-list-item ${
            currentPage === number ? "active" : ""
          } `}
        >
          <a onClick={() => paginate(number)}>{number}</a>
        </li>
      );
    } else {
      return null;
    }
  });

  return (
    <nav>
      <ul className="pagination">
        <li>
          <button
            onClick={handlePrev}
            className="change-btns"
            disabled={currentPage == pageNumbers[0] ? true : false}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
        </li>
        {pageNumberList}

        <li style={{ color: "#858794" }}>
          {pageNumbers.length <= 4
            ? null
            : currentPage >= pageNumbers[pageNumbers.length - 2]
            ? null
            : "..."}
        </li>

        <li>
          {pageNumbers.length <= 4
            ? null
            : currentPage >= pageNumbers[pageNumbers.length - 2]
            ? null
            : pageNumbers[pageNumbers.length - 1]}
        </li>
        <li>
          <button
            onClick={handleNext}
            className="change-btns"
            disabled={
              currentPage == pageNumbers[pageNumbers.length - 1] ? true : false
            }
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
