import React from "react";
import GoogleMapReact from "google-maps-react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./TribeWorldMapV2.css";
import Hero from "../../../Models/Hero";
import User from "../../../Models/User";
import EnterpriseMarker from "../../Dashboard/TribeWorld/EnterpriseMarker/EnterpriseMarker";
import CauseMarker from "../../Dashboard/TribeWorld/CauseMarker/CauseMarker";
import { GOOGLE_MAP_API } from "../../../utils/constants";
import UserMarker from "../../Dashboard/TribeWorld/UserMarker/UserMarker";
import MarkerFilter from "../../Dashboard/TribeWorld/MarkerFilter/MarkerFilter";

const TribeWorldMap = (props) => {
  const [userMarkers, setUserMarkers] = useState([]);
  const [showUsers, setShowUsers] = useState(true);
  const [causeMarkers, setCauseMarkers] = useState([]);
  const [showCauses, setShowCauses] = useState(true);
  const [enterpriseMarkers, setEnterpriseMarkers] = useState([]);
  const [showEnterprises, setShowEnterprises] = useState(true);

  const getEnterprises = () => {
    Hero.fetchAllEnterprises().then((enterprises) => {
      setEnterpriseMarkers(enterprises);
    });
  };

  const getCauses = () => {
    Hero.fetchAllCauses().then((causes) => {
      setCauseMarkers(causes);
    });
  };

  const getUsers = () => {
    User.GetAllUserCard().then((users) => {
      setUserMarkers(users.data);
    });
  };
  useEffect(() => {
    getCauses();
    getEnterprises();
    getUsers();
  }, []);

  const [defaultProps, setdefaultprops] = useState({
    zoom: 11,
    center: {
      lat: props.user.location ? props.user.location.coordinates[1] : 51.5072,
      lng: props.user.location ? props.user.location.coordinates[0] : 0.1276,
    },
    mapTypeId: "roadmap",
  });

  var mapOptions = {
    mapTypeId: defaultProps.mapTypeId, //roadmap, terrain, satellite  , hybrid
    zoomControl: "true",
  };

  const handleUsers = () => {
    setShowUsers(!showUsers);
  };
  const handleCauses = () => {
    setShowCauses(!showCauses);
  };
  const handleEnterprises = () => {
    setShowEnterprises(!showEnterprises);
  };

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAP_API }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={mapOptions}
      >
        <div className="marker-filter-wrapper">
          <MarkerFilter
            handleUsers={handleUsers}
            handleCauses={handleCauses}
            handleEnterprises={handleEnterprises}
            showCauses={showCauses}
            showEnterprises={showEnterprises}
            showUsers={showUsers}
          />
        </div>
        {userMarkers && showUsers
          ? userMarkers.map((user, index) => (
              <UserMarker
                key={index}
                user={user}
                lat={user.location ? user.location.coordinates[1] : ""}
                lng={user.location ? user.location.coordinates[0] : ""}
              />
            ))
          : null}
        {causeMarkers && showCauses
          ? causeMarkers.map((cause, index) => (
              <CauseMarker
                key={index}
                cause={cause}
                lat={cause.location.coordinates[1]}
                lng={cause.location.coordinates[0]}
              />
            ))
          : null}
        {enterpriseMarkers && showEnterprises
          ? enterpriseMarkers.map((enterprise, index) => (
              <EnterpriseMarker
                key={index}
                enterprise={enterprise}
                lat={enterprise.location.coordinates[1]}
                lng={enterprise.location.coordinates[0]}
              />
            ))
          : null}
      </GoogleMapReact>
    </>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    dashboardMode: state.dashboard.mode,
  };
}

export default connect(mapStateToProps)(TribeWorldMap);
