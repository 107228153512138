import React, { useState, useRef } from "react";
import UserAvatar from "../../../UserAvatar";
import { Link } from "react-router-dom";
import { Popover, Overlay } from "react-bootstrap";
import "./UserMarker.css";

function UserMarker(props) {
  const { user } = props;

  // popover
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  return (
    <>
      <div ref={ref}>
        <div onClick={handleClick} className="user-marker">
          {show ? (
            <div className="bottom-circle">
              <div className="outer-circle"></div>
              <div className="firstInner-circle"></div>
              <div className="secondInner-circle"></div>
              <div className="thirdInner-circle"></div>
            </div>
          ) : (
            <div className="bottom-circle">
              <div className="secondInner-circle"></div>
              <div className="thirdInner-circle"></div>
            </div>
          )}
        </div>

        {!show ? (
          <div className="user-avatar-top">
            <UserAvatar avatar={user.avatar ? user.avatar : null} />
          </div>
        ) : null}

        <Overlay show={show} target={target} placement="top" container={ref}>
          <Popover id="popover-contained">
            <Popover.Body>
              <div className="user-details row">
                <div className="user-avatar col-4">
                  <UserAvatar avatar={user.avatar ? user.avatar : null} />
                </div>
                <div className="about-user col-8">
                  <p className="user-name">
                    {user.firstName} {user.lastName}
                  </p>
                  <p className="userInfo">
                    Hero to: <span>{user.charities} Causes</span>
                  </p>
                  <p className="userInfo">
                    Hero to: <span>{user.enterprises} Enterprises</span>
                  </p>
                  <p className="userInfo location">
                    Location:{" "}
                    <span>{user.address ? user.address.location : ""}</span>
                  </p>
                  <p className="view-profile">
                    <Link to={`hero-profile/${user._id}`}>View Profile</Link>
                  </p>
                </div>
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </>
  );
}

export default UserMarker;
