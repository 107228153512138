import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

// Global Stylesheets
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Protected Routes
import ProtectedUserRoute from "./utils/ProtectedRoute/ProtectedUserRoute";

// Index Pages Imports
import Home from "./containers/IndexPages/Home/Home";
import About from "./containers/IndexPages/About/About";
import News from "./containers/IndexPages/News/News";
import ContactPage from "./containers/IndexPages/ContactPage/ContactPage";
import Faqs from "./containers/IndexPages/Faqs/Faqs";
import Legal from "./containers/IndexPages/Legal/Legal";
import LoginMobile from "./containers/IndexPages/Auth/LoginMobile/LoginMobile";
import SignupMobile from "./containers/IndexPages/Auth/SignupMobile/SignupMobile";
import UpdatePassword from "./containers/IndexPages/Auth/UpdatePassword/UpdatePassword";
import FindCause from "./containers/IndexPages/FindCause/FindCause";
import HowItWorks from "./containers/IndexPages/HowItWorks/HowItWorks";
import NotFound from "./containers/NotFound/NotFound";
import Charities from "./containers/IndexPages/Charities/Charities";

// Dashboard Pages Imports
import DashboardHero from "./containers/DashboardPages/Hero/Dashboard/Dashboard";
import DashboardAngel from "./containers/DashboardPages/Angel/Dashboard/Dashboard";
import Donations from "./containers/DashboardPages/Angel/Donations/Donations";
import RewardTraking from "./containers/DashboardPages/Angel/RewardTraking/RewardTraking";
import StartFundraiser from "./containers/DashboardPages/Hero/StartFundraiser/StartFundraiser";
import Settings from "./containers/DashboardPages/Settings/Settings";
import CreateCause from "./containers/DashboardPages/Hero/CreateCause/CreateCause";
import CreateEnterprise from "./containers/DashboardPages/Hero/CreateEnterprise/CreateEnterprise";
import ManageCauses from "./containers/DashboardPages/Hero/ManageCauses/ManageCauses";
import ManageEnterprises from "./containers/DashboardPages/Hero/ManageEnterprises/ManageEnterprises";
import Inbox from "./containers/DashboardPages/Inbox/Inbox";
import CauseDetails from "./containers/DashboardPages/CauseDetails/CauseDetails";
import EnterpriseDetails from "./containers/DashboardPages/EnterpriseDetails/EnterpriseDetails";
import HeroProfile from "./containers/DashboardPages/Hero/HeroProfile/HeroProfile";
// import HeroCauses from "./containers/DashboardPages/Hero/HeroCauses/HeroCauses";
// import HeroEnterprises from "./containers/DashboardPages/Hero/HeroEnterprises/HeroEnterprises";
import TribeWorld from "./containers/DashboardPages/TribeWorld/TribeWorld";
import CampaignDonation from "./containers/DashboardPages/CampaignDonation/CampaignDonation";

class App extends Component {
  state = {
    location: "",
  };

  setLocatoinAutocomplete = (location, latLng) => {
    this.setState({ location });

    if (latLng) {
      const { lat, lng } = latLng;
      this.setState({ lat, lng });
    }
  };

  render() {
    const { isAuthenticated, isVerifying, dashboardMode } = this.props;

    return (
      <Switch>
        {/* Start Of => Index Page Routes */}
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/news" component={News} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/faqs" component={Faqs} />
        <Route exact path="/legal" component={Legal} />
        <Route exact path="/login" component={LoginMobile} />
        <Route exact path="/join" component={SignupMobile} />
        <Route exact path="/updatepassword/:token" component={UpdatePassword} />
        <Route exact path="/findcause" component={FindCause} />
        <Route exact path="/howitworks" component={HowItWorks} />
        <Route exact path="/charity/details/:id" component={CauseDetails} />
        <Route
          exact
          path="/enterprise/details/:id"
          component={EnterpriseDetails}
        />
        <Route exact path="/charities" component={Charities} />
        <Route exact path="/hero-profile/:id" component={HeroProfile} />
        {/* End Of => Index Page Routes */}

        {/* Start Of => Dashboard Page Routes */}

        <Route exact path="/hero-createcause" component={CreateCause} />
        <Route
          exact
          path="/hero-createenterprise"
          component={CreateEnterprise}
        />
        <Route exact path="/campaign/donate/:id" component={CampaignDonation} />

        <ProtectedUserRoute
          exact
          path="/angel-dashboard"
          component={DashboardAngel}
          pageFor="angel"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedUserRoute
          exact
          path="/angel-donations"
          component={Donations}
          pageFor="angel"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedUserRoute
          exact
          path="/angel-donations?refunds=true"
          component={Donations}
          pageFor="angel"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedUserRoute
          exact
          path="/angel-rewards"
          component={RewardTraking}
          pageFor="angel"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedUserRoute
          exact
          path="/hero-dashboard"
          component={DashboardHero}
          pageFor="hero"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedUserRoute
          exact
          path="/hero-startfundraiser"
          component={StartFundraiser}
          pageFor="hero"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedUserRoute
          exact
          path="/hero-managecauses"
          component={ManageCauses}
          pageFor="hero"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedUserRoute
          exact
          path="/hero-manageenterprises"
          component={ManageEnterprises}
          pageFor="hero"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedUserRoute
          exact
          path="/settings"
          component={Settings}
          pageFor="both"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedUserRoute
          exact
          path="/inbox"
          component={Inbox}
          pageFor="both"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedUserRoute
          exact
          path="/inbox/:id"
          component={Inbox}
          pageFor="both"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />

        <ProtectedUserRoute
          exact
          path="/tribe-world"
          component={TribeWorld}
          pageFor="both"
          dashboardMode={dashboardMode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        {/* End Of => Dashboard Page Routes */}

        <Route component={NotFound} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    user: state.auth.user,
    language: state.language.language,
    dashboardMode: state.dashboard.mode,
  };
}

export default connect(mapStateToProps)(App);
