import React from "react";
import "./FAQSForm.css";
import PropTypes from "prop-types";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import LabelledInput from "../../Inputs/LabelledInput/LabelledInput";
// import IconBtn from "../../Buttons/IconBtn/IconBtn";
import PrimaryBtn from "../../Buttons/PrimaryBtn/PrimaryBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import infoCircle from "../../../../assets/images/info-circle.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const FAQSForm = (props) => {
  const questionToolTip = (props) => <Tooltip {...props}>Question</Tooltip>;
  const answerToolTip = (props) => <Tooltip {...props}>Answer</Tooltip>;
  return (
    <div className="FAQSForm">
      <p className="about-faqs">
        FAQs are Frequently asked questions,It allows you to answer the
        questions that are most commonly asked surrounding your product or
        service.
      </p>
      {/* faq list */}
      <div className="faqs-list">
        <div className="wrap-list">
          {props.inputs.faqs.map((faq) => (
            <div className="faq-item" key={faq.id}>
              <div className="wrap-card">
                <OverlayTrigger placement="top-start" overlay={questionToolTip}>
                  <img src={infoCircle} className="infocircle-icon" />
                </OverlayTrigger>

                <LabelledInput
                  size="fullWidth"
                  dashboardMode={props.dashboardMode}
                  inputType="input"
                  label="Question"
                  input={{
                    type: "text",
                    name: "question",
                    value: `${faq.question}`,
                    onChange: props.inputChangeHandler,
                  }}
                />
                <OverlayTrigger placement="top-start" overlay={answerToolTip}>
                  <img src={infoCircle} className="infocircle-icon" />
                </OverlayTrigger>
                <LabelledInput
                  size="fullWidth"
                  dashboardMode={props.dashboardMode}
                  inputType="input"
                  label="Answer"
                  input={{
                    type: "text",
                    placeholder: "Write an answer ",
                    name: "answer",
                    value: `${faq.answer}`,
                    onChange: props.inputChangeHandler,
                  }}
                />
              </div>
              <div className="delete-button">
                <button onClick={() => props.deleteFaqHandler(faq.id)}>
                  Delete question
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* faqs input */}
      <div className="input-wrapper">
        <OverlayTrigger placement="top-start" overlay={questionToolTip}>
          <img src={infoCircle} className="infocircle-icon" />
        </OverlayTrigger>
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="input"
          label="Question"
          input={{
            type: "text",
            placeholder: "Write a question ",
            name: "question",
            value: props.inputs ? props.inputs.question : "",
            onChange: props.inputChangeHandler,
          }}
        />
      </div>
      <div className="input-wrapper">
        <OverlayTrigger placement="top-start" overlay={answerToolTip}>
          <img src={infoCircle} className="infocircle-icon" />
        </OverlayTrigger>
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="input"
          label="Answer"
          input={{
            type: "text",
            placeholder: "Write an answer ",
            name: "answer",
            value: props.inputs ? props.inputs.answer : "",
            onChange: props.inputChangeHandler,
          }}
        />
      </div>
      <div className="create-btn-wrapper">
        <button className="create-faqbtn" onClick={props.createFaqHandler}>
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>{" "}
          Add question
        </button>
      </div>

      {props.for === "creation" ? (
        <div className="btn-wrapper">
          <span>
            <PrimaryBtn
              type="button"
              size="md"
              color="tranparentLinearBlue"
              onClick={() => props.changeStep("prev")}
            >
              Previous
            </PrimaryBtn>
          </span>
          <span>
            <PrimaryBtn
              type="button"
              size="md"
              color="LinearBlue"
              onClick={() => props.changeStep("next")}
            >
              Next Step
            </PrimaryBtn>
          </span>
        </div>
      ) : props.for === "edit" ? (
        <div className="btn-wrapper">
          <PrimaryBtn
            type="button"
            size="md"
            color="yellow"
            onClick={props.onEditHandler}
          >
            Edit
          </PrimaryBtn>
        </div>
      ) : null}

      <div className="faqs-note">
        Note: You can skip adding the FAQs by clicking the next button
      </div>
    </div>
  );
};

FAQSForm.propTypes = {
  for: PropTypes.oneOf(["creation", "edit"]).isRequired,
  dashboardMode: PropTypes.string.isRequired,
  inputs: PropTypes.object.isRequired,
  inputChangeHandler: PropTypes.func.isRequired,
  createFaqHandler: PropTypes.func.isRequired,
  editFaqHandler: PropTypes.func.isRequired,
  deleteFaqHandler: PropTypes.func.isRequired,
};

export default FAQSForm;
