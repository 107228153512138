import React, { useEffect, useState } from "react";
import "./HeroResponsible.css";
import HeroDetails from "./HeroDetails/HeroDetails";
import HeroCampaigns from "./HeroCampaigns/HeroCampaigns";
import User from "../../../../Models/User";

function HeroResponsible(props) {
  const [heroCard, setheroCard] = useState({});

  //get hero details
  function getHeroDetails(creatorId) {
    User.GetUserCard(creatorId).then(
      (result) => setheroCard(result),
      (err) => alert(err)
    );
  }

  useEffect(() => {
    getHeroDetails(props.creatorId);
  }, []);

  return (
    <div className="heroResponsible-section">
      <div className="hero-section-title">Hero Responsible</div>
      <HeroDetails heroCard={heroCard} />
      <HeroCampaigns
        firstName={heroCard.firstName}
        creatorId={props.creatorId}
        categories={props.categories}
        campaignType={props.campaignType}
      />
    </div>
  );
}

export default HeroResponsible;
