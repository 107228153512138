import React, { Component } from "react";
import "./Legal.css";
import NavbarWithBackdrop from "../../../components/Sections/NavbarWithBackdrop/NavbarWithBackdrop";
import Title from "../../../components/UI/Typography/Title/Title";
import CollapseCard from "../../../components/UI/Cards/CollapseCard/CollapseCard";
import Footer from "../../../components/Sections/Footer/Footer";

// Images
import BackdropImg from "../../../assets/images/backdropTest.png";

class Legal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			options: [
				"overview",
				"definitions",
				"services",
				"obligations",
				"contributions",
				"propertyMatters",
				"miscellaneous",
			],
			activeOption: "overview",
		};

		this.optionsEl = React.createRef();
		this.overviewRef = React.createRef();
		this.definitionsRef = React.createRef();
		this.servicesRef = React.createRef();
		this.obligationsRef = React.createRef();
		this.contributionsRef = React.createRef();
		this.propertyMattersRef = React.createRef();
		this.miscellaneousRef = React.createRef();

		window.onscroll = this.onScrollHandler;
	}

	onScrollHandler = () => {
		if (this.optionsEl.current) {
			if (window.scrollY >= 425) {
				this.optionsEl.current.style.position = "sticky";
				this.optionsEl.current.style.top = "1rem";
			} else {
				this.optionsEl.current.style.position = "static";
			}
		}
	};

	selectOptionHandler = (selectedOpt) => {
		const { options } = this.state;
		const doesExist = options.find((opt) => opt === selectedOpt);

		if (doesExist) {
			this.setState({ activeOption: selectedOpt });

			switch (selectedOpt) {
				case "overview":
					this.overviewRef.current.scrollIntoView();
					break;
				case "definitions":
					this.definitionsRef.current.scrollIntoView();
					break;
				case "services":
					this.servicesRef.current.scrollIntoView();
					break;
				case "obligations":
					this.obligationsRef.current.scrollIntoView();
					break;
				case "contributions":
					this.contributionsRef.current.scrollIntoView();
					break;
				case "propertyMatters":
					this.propertyMattersRef.current.scrollIntoView();
					break;
				case "miscellaneous":
					this.miscellaneousRef.current.scrollIntoView();
					break;
				default:
					return null;
			}
		} else {
			return;
		}
	};

	render() {
		const { activeOption } = this.state;

		return (
			<main className="Legal">
				<NavbarWithBackdrop
					navbarMode="white"
					backdropImg={BackdropImg}
					text="Legal"
					textColor="white"
				/>

				<section className="Legal-Body">
					<div className="container">
						<div className="row">
							<div className="col-4 d-none d-lg-block">
								<div
									className="Legal-options"
									ref={this.optionsEl}
								>
									<div
										className={
											activeOption === "overview"
												? "Option-Box active"
												: "Option-Box"
										}
										onClick={() =>
											this.selectOptionHandler("overview")
										}
									>
										<Title
											size="md"
											color={
												activeOption === "overview"
													? "white"
													: "dark"
											}
											isResponsive={false}
										>
											Overview
										</Title>
									</div>
									<div
										className={
											activeOption === "definitions"
												? "Option-Box active"
												: "Option-Box"
										}
										onClick={() =>
											this.selectOptionHandler(
												"definitions"
											)
										}
									>
										<Title
											size="md"
											color={
												activeOption === "definitions"
													? "white"
													: "dark"
											}
											isResponsive={false}
										>
											Definitions
										</Title>
									</div>
									<div
										className={
											activeOption === "services"
												? "Option-Box active"
												: "Option-Box"
										}
										onClick={() =>
											this.selectOptionHandler("services")
										}
									>
										<Title
											size="md"
											color={
												activeOption === "services"
													? "white"
													: "dark"
											}
											isResponsive={false}
										>
											Our Services
										</Title>
									</div>
									<div
										className={
											activeOption === "obligations"
												? "Option-Box active"
												: "Option-Box"
										}
										onClick={() =>
											this.selectOptionHandler(
												"obligations"
											)
										}
									>
										<Title
											size="md"
											color={
												activeOption === "obligations"
													? "white"
													: "dark"
											}
											isResponsive={false}
										>
											Obligations
										</Title>
									</div>
									<div
										className={
											activeOption === "contributions"
												? "Option-Box active"
												: "Option-Box"
										}
										onClick={() =>
											this.selectOptionHandler(
												"contributions"
											)
										}
									>
										<Title
											size="md"
											color={
												activeOption === "contributions"
													? "white"
													: "dark"
											}
											isResponsive={false}
										>
											Contributions
										</Title>
									</div>
									<div
										className={
											activeOption === "propertyMatters"
												? "Option-Box active"
												: "Option-Box"
										}
										onClick={() =>
											this.selectOptionHandler(
												"propertyMatters"
											)
										}
									>
										<Title
											size="md"
											color={
												activeOption ===
												"propertyMatters"
													? "white"
													: "dark"
											}
											isResponsive={false}
										>
											Property Matters
										</Title>
									</div>
									<div
										className={
											activeOption === "miscellaneous"
												? "Option-Box active"
												: "Option-Box"
										}
										onClick={() =>
											this.selectOptionHandler(
												"miscellaneous"
											)
										}
									>
										<Title
											size="md"
											color={
												activeOption === "miscellaneous"
													? "white"
													: "dark"
											}
											isResponsive={false}
										>
											Miscellaneous
										</Title>
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-8">
								<div
									ref={this.overviewRef}
									className="Legal-Section General-Faq"
								>
									<div className="section-title">
										<Title
											size="md"
											color="dark"
											isResponsive={true}
										>
											Overview and Introduction
										</Title>
									</div>
									<CollapseCard title="The complete overview and introduction about Tribe">
										<ol>
											<li>
												<p>
													We are TribeWorld
													(Hereinafter referred to as
													“TribeWorld” “we” “our
													platform” and “us”), a
													company registered in the
													United Kingdom but operating
													services globally. We are an
													online crowdfunding platform
													for Users to seek to raise
													funds for their own Cause,
													Enterprise, Charity and
													Industrial Projects and to
													contribute to the Campaigns
													of others. Heroes of
													Enterprise or Industrial
													Campaigns can offer rewards
													to contributors in thanks
													for the contributors’
													contributions of funds.
												</p>
											</li>
											<li>
												<p>
													We are based in London,
													United Kingdom. Regarding
													any issue related and
													connected to this Terms of
													Use, we can be contacted at
													legal@tribeworld.org. We
													have a dedicated team who
													would endeavour to respond
													to your email timeously.
													Please be informed that we
													can only give clarifications
													and explanations when
													contacted. We are not able
													to provide legal, financial
													or tax advice. Tribeworld is
													not an accounting firm,
													neither are we a financial
													advisor, so please do not
													rely on any information on
													the website to determine any
													consequence of contributing
													to a Campaign.{" "}
												</p>
											</li>
											<li>
												<p>
													By using our website,
													services and platform, Users
													agree to be bound by thus
													Terms of Use, our Privacy
													Policy, Our Refund Policy,
													Our Fees Policy and our
													Cookies Policy. In addition
													to these, Users agree to be
													bound to additional policies
													applicable to any of our
													services, its functionality
													and contents. These terms
													are incorporated by
													reference.{" "}
												</p>
											</li>
											<li>
												<p>
													These Terms of Use governs
													and regulates your use of
													our platform and services,
													by using our platform, you
													consent to these terms and
													are bound by it. Please
													kindly read these terms
													carefully. These Terms
													override any previous
													communication or agreement
													with Tribeworld in relation
													to any of our services and
													platform.{" "}
												</p>
											</li>
										</ol>
									</CollapseCard>
								</div>

								<div
									ref={this.definitionsRef}
									className="Legal-Section General-Faq"
								>
									<div className="section-title">
										<Title
											size="md"
											color="dark"
											isResponsive={true}
										>
											Definitions and Interpretations
										</Title>
										<CollapseCard title="Angels">
											<p>
												Are Users who donates (either
												money, time or expertise) to a
												Charity or Cause Campaigns on
												our platform.
											</p>
										</CollapseCard>
										<CollapseCard title="Campaign">
											<p>
												Includes either a Cause
												Campaign, a Charity Campaign, a
												Industrial Campaign, a
												Enterprise Campaign set up on
												our Platform.
											</p>
										</CollapseCard>
										<CollapseCard title="Campaigns">
											<p>
												Collectively refers to a Cause
												Campaign, a Charity Campaign, a
												Industrial Campaign, a
												Enterprise Campaign set up on
												our Platform.
											</p>
										</CollapseCard>
										<CollapseCard title="Cause">
											<p>
												A non-profit project that is not
												a registered charity.
											</p>
										</CollapseCard>
										<CollapseCard title="Charity">
											<p>
												A non-profit project operated by
												a registered charity.
											</p>
										</CollapseCard>
										<CollapseCard title="Cause Campaign">
											<p>
												A network and marketing effort
												for a non-profit project from a
												User that is not a registered
												charity.
											</p>
										</CollapseCard>
										<CollapseCard title="Charity Campaign">
											<p>
												A network and marketing effort
												for a non-profit project from a
												User that is a registered
												charity.
											</p>
										</CollapseCard>
										<CollapseCard title="Contributions">
											<p>
												Money given to an Enterprise or
												Industrial Campaign.
											</p>
										</CollapseCard>
										<CollapseCard title="Contributors">
											<p>
												Are Users who contribute to an
												Enterprise or Industrial
												Campaign on our platform.
											</p>
										</CollapseCard>
										<CollapseCard title="Dashboard">
											<p>
												A page where registered Users
												can view details of campaigns
												they have donated/contributed to
												or Campaigns they created.
											</p>
										</CollapseCard>
										<CollapseCard title="Donation">
											<p>
												Money given to a cause or
												charities without any economic
												or service reward.
											</p>
										</CollapseCard>
										<CollapseCard title="Elder">
											<p>
												A Tribe administrator,
												responsible for campaign support
												and customer service.
											</p>
										</CollapseCard>
										<CollapseCard title="Enterprise">
											<p>
												A profit project, for example a
												startup or a Research and
												Development (R&D) fund.
											</p>
										</CollapseCard>
										<CollapseCard title="Enterprise Campaign">
											<p>
												A network and marketing effort
												for a profit project, for
												example a startup or a Research
												and Development (R&D) fund.
											</p>
										</CollapseCard>
										<CollapseCard title="Heroes">
											<p>
												Are Users who raise funds
												through our platform for charity
											</p>
										</CollapseCard>
										<CollapseCard title="Industrial project">
											<p>
												A larger scale community
												initiative aimed to support a
												larger scale population. Town,
												City, Region, in the field of
												energy, water, environmental
												improvement, forest.
											</p>
										</CollapseCard>
										<CollapseCard title="Industrial Project Campaign">
											<p>
												A network and marketing effort
												for a larger scale community
												initiative aimed to support a
												larger scale population. Town,
												City, Region, in the field of
												energy, water, environmental
												improvement, forest.
											</p>
										</CollapseCard>
										<CollapseCard title="Live Campaign">
											<p>
												An active campaign that has been
												approved by an Elder that can
												receive donations and/or
												contributions.
											</p>
										</CollapseCard>
										<CollapseCard title="Our Content">
											<p>
												Includes those contents provided
												to Users by Us, which is related
												or connected to our services or
												activities on our platform.
											</p>
										</CollapseCard>
										<CollapseCard title="Party">
											<p>
												Means either User as defined in
												User definition or Tribeword as
												defined in Tribeworld
												definition.
											</p>
										</CollapseCard>
										<CollapseCard title="Parties">
											<p>
												Means both Users as defined in
												User definition and Tribeword as
												defined in Tribeworld
												definition.
											</p>
										</CollapseCard>
										<CollapseCard title="Platform">
											<p>
												Includes all avenues in which
												our services can be accessed and
												used. This includes but is not
												limited to{" "}
												<a href="http://tribeworld.org">
													http://tribeworld.org
												</a>
												, mobile applications, any of
												our services, etc.
											</p>
										</CollapseCard>
										<CollapseCard title="Rewards">
											<p>
												These are gifts given to Heroes
												to Angels in return for their
												contributions. These include but
												are not limited gifts of
												tangible or intangible form.
											</p>
										</CollapseCard>
										<CollapseCard title="Referral">
											<p>
												Link shared by existing User to
												an unregistered User directing
												them to register as a User on
												Tribe.
											</p>
										</CollapseCard>
										<CollapseCard title="Terms of Use">
											<p>
												Means this “Terms of Use”
												together with the terms
												incorporated by reference, all
												as may be amended, varied or
												supplemented from time to time
												by Tribeworld in accordance with
												clause 10(g).
											</p>
										</CollapseCard>
										<CollapseCard title="Tribeworld">
											<p>
												Means TribeWorld Limited, a
												company registered and located
												in the United Kingdom which can
												be contacted on our Contact
												Page.{" "}
											</p>
										</CollapseCard>
										<CollapseCard title="User">
											<p>
												Any User of our service
												including but not limited to
												Heroes, Contributors and Angels,
												or any person using our services
												or platform.{" "}
											</p>
										</CollapseCard>
										<CollapseCard title="User Content">
											<p>
												Means any content provided by
												the persons who fall under the
												category in 2(m) above.
											</p>
										</CollapseCard>
										<CollapseCard title="User settings">
											<p>
												A page where Users can add or
												edit their personal information,
												change password and payment
												information.
											</p>
										</CollapseCard>
										<CollapseCard title="Website and Platform ">
											<p>
												<a href="http://tribeworld.org">
													http://tribeworld.org
												</a>{" "}
												and all of our services.
											</p>
										</CollapseCard>
										<CollapseCard title="You">
											<p>
												means the same thing as “User”
												as described in User definition.
											</p>
										</CollapseCard>
										<CollapseCard title="References">
											<p>
												Any reference to a statutory
												provision shall be construed as
												a reference to:
												<ol>
													<li>
														Any statutory
														modification or
														re-enactment thereof
														(whether before or after
														the date hereof) for the
														time being in force;
													</li>
													<li>
														All statutory
														instruments or orders
														made pursuant thereto;
														and
													</li>
													<li>
														Any statutory provisions
														of which that statutory
														provision is a
														re-enactment or
														modification.
													</li>
													<li>
														Any reference to a
														document or this Terms
														of Use shall include a
														reference to any
														amendment, replacement,
														notation or supplement
														to that document or this
														Terms of Use but
														excluding any amendment
														replacement, notation or
														supplement made in
														breach of this Terms of
														Use.
													</li>
													<li>
														Any reference to a
														party, (who can either
														be Users or Tribeworld
														collectively and
														individually) to this
														Terms of Use includes a
														reference to that
														party’s successors and
														permitted assigns.
													</li>
													<li>
														Donations and
														Contributions are used
														interchangeably and they
														individually and
														collectively mean monies
														given to any Campaign on
														our Platform.
													</li>
													<li>
														Paragraph headings are
														inserted for ease of
														reference and
														convenience and shall
														not be construed as
														forming part of this
														Terms of Use or used in
														the interpretation of
														any Article hereof.
													</li>
													<li>
														Words denoting the
														singular shall include
														the plural and vice
														versa.
													</li>
													<li>
														Words denoting persons
														shall include
														corporations, firms and
														organisations and vice
														versa.
													</li>
													<li>
														Words denoting any
														gender shall include all
														genders.
													</li>
												</ol>
											</p>
										</CollapseCard>
									</div>

									<div
										ref={this.servicesRef}
										className="Legal-Section General-Faq"
									>
										<div className="section-title">
											<Title
												size="md"
												color="dark"
												isResponsive={true}
											>
												Our Services
											</Title>
											<CollapseCard title="All the services that Tribe provides you with">
												<ol>
													<li>
														<p>
															We are a
															crowdfunding
															platform that allows
															Users (usually
															called Heroes) set
															up Campaigns aimed
															at raising funds for
															two purposes, either
															for a cause, a
															charity or
															Enterprise or
															Industrial purposes.
															When Campaigns are
															set up as an
															industrial or
															enterprise Campaign,
															Heroes can offer
															rewards to
															Contributors who
															contribute to their
															Campaign. However,
															when contributions
															are made to a Cause
															or Charity Campaign,
															rewards are not
															offered to Angels by
															Heroes.
														</p>
													</li>
													<li>
														<p>
															Every Hero who sets
															up a Campaign on our
															platform has an
															agreement with us
															authorising us to
															collect
															Contributions or/and
															Donations on its
															behalf.
														</p>
													</li>
													<li>
														<p>
															Putting up Campaigns
															is at the discretion
															of Tribeworld. The
															decision to list any
															Campaign is made by
															a member of our
															administrative team
															called “an Elder.”
															Tribeworld is not
															under any obligation
															to explain or
															justify the decision
															to list, not-list,
															remove or re-list
															any Campaign on our
															platform.
														</p>
													</li>
													<li>
														<p>
															Tribeworld expressly
															states that we are
															not responsible for
															the activities of
															any Campaign. We are
															only a platform
															bringing Users
															together. Every Hero
															setting up a
															Campaign is under
															the obligation to
															ensure that it
															secures the needed
															authorisation,
															permit or licence to
															start such Campaign,
															within the confines
															of the local laws of
															the jurisdiction at
															which it operates.
														</p>
													</li>
													<li>
														<p>
															Tribeworld Campaign
															pages are not geared
															or set up to raise
															money for any Hero’s
															use. We are only a
															platform bringing
															Contributors, Heroes
															and Angels together.
															It is the
															responsibility of
															Contributors and
															Angels to read the
															information on each
															Campaign page to
															make an informed
															personal decision on
															whether to
															contribute to a
															Campaign or not. As
															a Contributor or an
															Angel, you are
															responsible for
															making a decision
															regarding the
															authenticity and
															suitability of the
															Campaign you
															contribute to.
															Please be informed
															that it is also your
															duty to ensure that
															the Campaign you
															contribute to is
															independent and
															represents your
															interest.
														</p>
													</li>
													<li>
														<p>
															We reserve the
															discretion and right
															to terminate,
															monitor, suspend,
															delete or ban the
															account of any User
															on our platform. We
															have no obligation
															to give any reason
															or explanation for
															such action. We take
															these actions for
															the benefit of our
															platform.
														</p>
													</li>
												</ol>
											</CollapseCard>
										</div>
									</div>

									<div
										ref={this.obligationsRef}
										className="Legal-Section General-Faq"
									>
										<div className="section-title">
											<Title
												size="md"
												color="dark"
												isResponsive={true}
											>
												Obligations
											</Title>
											<CollapseCard title="Obligations of Users">
												<p>
													We give to all our Users a
													limited, non-exclusive
													non-transferable, and
													revocable license to use our
													platform and services.
													However, this right of use
													is subject to this Terms of
													Use and the following
													restrictions:
												</p>
												<ol>
													<li>
														<p>
															Users in the
															European Economic
															Area (EEA) must be
															18 years old to use
															our platform and
															services. For minors
															outside the EEA who
															are between the age
															of 13 and 18, the
															use of our platform
															or any of our
															services must be
															through the use of
															an account owned and
															operated by a parent
															or legal guardian.
															The parent must also
															provide affirmative
															consent and
															supervise the use of
															their account. Users
															are solely
															responsible for any
															use of their account
															by a minor.
														</p>
													</li>
													<li>
														<p>
															By using our
															platform, you hereby
															confirm that you are
															of the required Age
															as stated in 4(a). A
															breach of this term
															constitutes a
															fundamental breach
															of this Terms of
															Use.
														</p>
													</li>
													<li>
														<p>
															Users are obligated
															to provide accurate
															and complete
															information when
															registering an
															account on our
															platform. They must
															also ensure that
															their account is
															updated and accurate
															at all times. Each
															User is responsible
															for his/her account.
															The confidentiality
															of your account and
															its activities are
															your sole
															responsibility. Any
															suspicion of any
															unauthorised use of
															your account should
															be reported to us
															immediately by
															sending an email to
															legal@tribeworld.org.
															Tribeworld is not
															liable for any loss
															or damages from any
															unauthorised use of
															a User’s account by
															another person.
														</p>
													</li>
													<li>
														<p>
															Each User hereby
															represents that they
															are not
															impersonating
															another person,
															being fraudulent,
															being offensive or
															violating another
															User’s right. Each
															User also obligates
															that the Username
															selected is not
															offensive and
															targeted at
															demeaning another
															User.
														</p>
													</li>
													<li>
														<p>
															Users can delete
															their account or the
															use of our platform
															or any of our
															services by sending
															us an email at
															delete@tribeworld.org.
															Please note that
															deleting your
															account does not
															delete your content.
															We may need to keep
															certain Users’
															contents for
															business reasons and
															legal compliance
															purposes. Please
															read our privacy
															policy for more
															information.
														</p>
													</li>
													<li>
														<p>
															Users must refrain
															from carrying out
															activities that
															infringe on the
															right of another
															User or person,
															carry out criminal
															activities, violate
															any law, breach a
															contract or a legal
															duty, offer illegal
															rewards, violate our
															Terms of Use and
															other related
															policies.
														</p>
													</li>
													<li>
														<p>
															Users must respect
															the rights of
															others, must not
															post wrong
															information, avoid
															unsolicited
															communications,
															engage in actions
															that are libellous,
															tortious, profane,
															obscene or invade
															another person’s
															privacy. Users must
															not defame, harass,
															abuse, threaten,
															spam or distribute
															viruses or any
															software/program
															that invades the
															privacy of any User,
															affects the proper
															functioning of any
															equipment or the use
															of our platform by
															any User. Users must
															not use the Website
															to send junk email
															or “spam” to people
															who do not wish to
															receive email from
															you.
														</p>
													</li>
													<li>
														<p>
															Users must not
															engage in activities
															that are detrimental
															to our platform or
															its functionality.
															Users shall not take
															actions to bypass
															our security
															measures or gain
															unauthorised access
															to our content or
															any other User’s
															content, reverse
															engineer or take
															apart any of our
															services or
															platform.
														</p>
													</li>
													<li>
														<p>
															Tribeworld is not
															responsible for any
															loss or damages
															arising from the
															failure to comply
															with clause 4(a) -
															4(g) above.
															Tribeworld may
															terminate, suspend
															or take any action
															against your account
															for violation of any
															Terms of Use.
														</p>
													</li>
												</ol>
											</CollapseCard>
											<CollapseCard title="HERO’S OBLIGATIONS">
												<p>
													As a Hero, you are a
													Campaign owner. When you set
													up a Campaign on our
													profile, you agree that you
													are entering into a separate
													legal agreement with
													Tribeworld and contributors
													or Angels, as applicable. In
													addition to all other
													Tribeworld policy and this
													Terms of Use, the following
													applies to all Heroes on our
													platform:
												</p>
												<ol>
													<li>
														<p>
															All Heroes must be
															truthful and
															transparent about
															the information
															provided on all
															Campaigns. They must
															also be available to
															answer all questions
															and queries as asked
															by the Contributors
															or Angels. Heroes
															must be willing,
															ready and available
															to back up the
															claims made in their
															Campaign.
														</p>
													</li>
													<li>
														<p>
															Promptly notify
															Contributors on
															obstacles and
															delays.
														</p>
													</li>
													<li>
														<p>
															Keep private all
															personal information
															received from
															Contributors or
															Angels either
															related to the
															Campaign or not.
														</p>
													</li>
													<li>
														<p>
															Provide regular
															updates to
															Contributors or
															Angels. Heroes
															should be ready to
															provide information
															which includes but
															are not limited to
															product features and
															capabilities, the
															stage of product
															development, and
															timelines for
															delivery, when
															applicable.
														</p>
													</li>
													<li>
														<p>
															Tribeworld may
															request for
															information about
															your Campaign, This
															information
															includes, but is not
															limited to,
															information to
															support Campaign
															statements, evidence
															that the Hero is
															taking actions to
															deliver promised
															rewards, productions
															and shipping
															timelines and
															information, source
															documentation to
															substantiate product
															claims and Campaign
															promises, product
															prototypes, and
															personal information
															to confirm the
															identity of a Hero,
															vendors, and
															suppliers. You agree
															that Tribeworld may
															employ third parties
															to assess the
															documents and your
															Campaign to
															determine the
															veracity of your
															campaign and other
															determinations
															needed. You agree to
															provide this
															information to
															Tribeworld upon
															request. Failure to
															provide any of the
															requested
															information within
															14 days, or a longer
															time provided by
															Tribeworld in the
															request made in
															writing, shall be
															deemed a material
															breach of this Terms
															of Use. Please note
															that this right to
															request for
															information does not
															confer on Tribeworld
															any obligation to
															investigate any
															statement made by a
															User or any other
															person. Requests for
															information can be
															for any purpose as
															determined by
															Tribeworld.
														</p>
													</li>
													<li>
														<p>
															At Tribeworld, we
															endeavour to make
															Withdrawals
															available to Heroes
															timeously as
															circumstances
															permits. Heroes
															hereby accept and
															agree that
															Withdrawals may not
															be available to them
															for use immediately
															after the end of a
															Campaign. Subject to
															5(g), Withdrawal is
															only possible after
															14 days after the
															end of the Campaign.
															Tribeworld does not
															guarantee that
															Withdrawals will be
															available to you
															within any specific
															time frame.
															Withholding of
															contributions might
															be for various
															reasons including
															but not limited to
															refunds, chargeback
															disputes, fraud,
															breach of this Terms
															of use or any of our
															other policies, or
															any other situation.
														</p>
													</li>
													<li>
														<p>
															Withdrawal will only
															be allowed if the
															Hero passes our
															security and
															identity checks. If
															these checks are not
															passed within 21
															days from the
															receipt of the end
															of a Campaign, all
															contributions will
															be refunded and the
															User’s account will
															be suspended.
														</p>
													</li>
													<li>
														<p>
															Users agree that
															Tribeworld holds no
															responsibility or
															liability for any
															delay or inability
															to access and use
															Withdrawals at any
															specified time, and
															any consequences
															arising from such
															delay or inability.
															Heroes are
															responsible for
															ensuring that the
															information provided
															for processing
															withdrawal is
															accurate and
															complete.
															Withdrawals are paid
															after the relevant
															deductions as
															contained in our
															Fees Policy has been
															made.
														</p>
													</li>
													<li>
														<p>
															Comply with all
															legal requirements
															relevant to the use
															of the
															contributions,
															operation with
															Contributors or
															Angels and also the
															delivery of rewards
															to Contributors.
														</p>
													</li>
													<li>
														<p>
															Deliver rewards
															adequately and
															timeously as
															promised.
														</p>
													</li>
													<li>
														<p>
															Must not use the
															Platform to conduct,
															display or forward
															surveys, pyramid
															schemes or chain
															letters.
														</p>
													</li>
													<li>
														<p>
															Must not use the
															platform to conduct,
															display or forward
															raffles, lotteries
															or contests.
														</p>
													</li>
													<li>
														<p>
															Must be willing to
															issue refunds to
															contributors if you
															cannot deliver the
															reward promised.
														</p>
													</li>
													<li>
														<p>
															If the Campaign set
															up is a cause or
															charity campaign,
															the Hero setting up
															the Campaign is
															responsible for
															getting the
															appropriate
															authorisation,
															permit or licence to
															raise funds for the
															said Campaign.
														</p>
													</li>
												</ol>
											</CollapseCard>
											<CollapseCard title="CONTRIBUTORS AND ANGEL’S OBLIGATIONS">
												<p>
													As a Contributor or an
													Angel, your contribution is
													voluntary and at your own
													discretion. You are to
													consider all the information
													presented to you and stated
													on the Campaign to make an
													informed decision on whether
													you want to donate or not.
													In addition to all other
													Tribeworld policies and this
													Terms of Use, the following
													applies to all Contributors
													and Angels, as applicable,
													on our platform:
												</p>
												<ol>
													<li>
														<p>
															As a Contributor or
															an Angel, you are
															supporting a cause,
															charity, an idea, a
															project or an event
															you care about. You
															accept the risk that
															the Campaign may
															experience changes,
															delays, and
															unforeseen
															challenges, or that
															a Campaign, and its
															Rewards, might not
															come to fruition.
														</p>
													</li>
													<li>
														<p>
															Please be aware that
															we do not guarantee
															that the Campaign
															you contribute to
															will succeed or the
															reward promised to
															you will be
															delivered, satisfy
															its goals or deemed
															satisfactory by you.
															Tribeworld
															undertakes no duty
															to investigate the
															veracity of any
															information made by
															Heroes. We also do
															not endorse,
															guarantee, make
															representations, or
															provide warranties
															regarding the
															quality, safety,
															morality or legality
															of any Campaign,
															Reward or
															Contribution, or the
															truth or accuracy of
															any User Content
															posted on the
															Services. The date
															to deliver a Reward
															is an estimate by a
															Hero and not
															Tribeworld. There is
															no guarantee that
															the Hero will fulfil
															and deliver the
															Reward by that date.
															No transfer of title
															to tangible personal
															property occurs
															through Tribeworld.
														</p>
													</li>
													<li>
														<p>
															In order to
															contribute to any
															Campaign, Angels and
															Contributors will be
															required to provide
															information
															regarding their
															credit card or other
															payment methods. All
															Angels and
															Contributors
															represent and
															guarantee Tribeworld
															that such
															information is true
															and that they are
															authorised to use
															the said Payment
															Method. They agree
															Donations are final
															and will not be
															refunded unless in
															the manner as
															contained in our
															refund policy.
														</p>
													</li>
													<li>
														<p>
															One you contribute
															to a Campaign, the
															Hero may need to
															contact you to thank
															you or ask for
															information related
															to the reward or
															feedback for the
															reward. However, if
															you do not want to
															be contacted, please
															contact us here A
															Hero should not make
															a request for
															information that
															exceeds those needed
															to fulfil the reward
															as promised, if a
															Hero requests for
															information that
															exceeds the needed
															information, please
															report to us by
															sending an email to
															legal@tribeworld.org
															.
														</p>
													</li>
													<li>
														<p>
															Any financial and
															tax liability that
															comes from
															contributions or
															rewards is solely
															the responsibility
															of the Contributor
															or Angel making the
															contribution.
														</p>
													</li>
													<li>
														<p>
															If you become aware
															of fraudulent use of
															your payment card to
															contribute to any
															Campaign, or if it
															is lost or stolen,
															you must notify your
															card provider in
															accordance with its
															reporting rules.
														</p>
													</li>
												</ol>
											</CollapseCard>
											<CollapseCard title="TRIBEWORLD’S OBLIGATIONS">
												<ol>
													<li>
														<p>
															We are only a
															platform that brings
															Users together,
															facilitates the
															creation of
															Campaigns and
															operation of owners.
															We do not endorse
															any User Content,
															guarantee the
															success of any
															Campaign,
															investigate or
															guarantee the
															information provided
															by any User. It is
															the sole
															responsibility of
															Contributors and
															Angels to make
															informed decisions
															on the Campaigns
															they donate to. We
															do not provide
															financial or legal
															advice. Our help
															with design or
															drafting Campaign
															content does not
															confer any liability
															on it, they are
															activities of the
															Hero.
														</p>
													</li>
													<li>
														<p>
															Tribeworld does not
															bear any liability
															and has no
															obligation to
															investigate,
															participate, or
															become involved in
															any dispute between
															Users, including
															between Heroes,
															Contributors, and
															Angels.
														</p>
													</li>
													<li>
														<p>
															Tribeworld may
															request information
															from Users regarding
															Campaigns and
															contributions,
															however, this is not
															an obligation,
															rather it is our
															discretion to decide
															whether to request
															for information or
															not. When we request
															for this information
															from Heroes, If the
															information is not
															provided within the
															requested time
															Tribeworld reserves
															the right to
															terminate any
															Campaign and account
															and pursue any
															remedies available
															to it under this
															Terms of Use or any
															applicable law.
														</p>
													</li>
													<li>
														<p>
															Tribeworld may also
															withhold
															Contributions raised
															from any Campaign
															for any other breach
															of this Terms of
															Use. We may also
															seek reimbursement
															from a User by any
															other lawful means,
															including by using
															third-party
															collection services.
														</p>
													</li>
													<li>
														<p>
															Tribeworld may
															remove User Content
															that violates our
															Terms of Use at its
															sole discretion.
															Removing User
															Content, terminating
															an account, or
															cancelling Campaigns
															and refunding
															Contributions are
															not actions. It is
															at Tribeworld’s sole
															discretion whether
															to give a reason for
															the action taken or
															not.
														</p>
													</li>
													<li>
														<p>
															Tribeworld may offer
															or issue refunds
															with or without
															Heroes’ consent or
															consultation. We
															offer a limited
															refund system, under
															this system, refund
															will only be given
															as stated in our
															Refund Policy.
															Please note that all
															the contents of this
															clause are subject
															to our Refund
															Policy.
														</p>
													</li>
												</ol>
											</CollapseCard>
										</div>

										<div
											ref={this.contributionsRef}
											className="Legal-Section General-Faq"
										>
											<div className="section-title">
												<Title
													size="md"
													color="dark"
													isResponsive={true}
												>
													Use of Contributions
												</Title>
												<CollapseCard title="Expand For Details">
													<ol>
														<li>
															<p>
																If you are
																contributing to
																a Charity or
																Cause,
																Tribeworld does
																not represent or
																warrant that
																your
																contributions
																will be used for
																any particular
																purpose and
																shall not be
																responsible for
																any
																dissatisfaction
																you may have
																regarding a
																Campaign’s use
																of your
																contribution or
																for any misuse
																or non-use of
																such
																contribution.
																All post
																contribution
																dealings are
																solely between
																you and Hero in
																charge of the
																Campaign to
																which you
																contributed to.
																Tribeworld shall
																not be
																responsible for
																ensuring that
																your
																contribution to
																a Cause or
																Charity Campaign
																is limited to a
																particular
																purpose. If you
																want a donation
																to be used for a
																specific
																purpose, contact
																the Hero and
																make your
																donations
																directly to
																them.
															</p>
														</li>
														<li>
															<p>
																Tribeworld will
																follow our
																internal
																verification
																process to
																verify the
																identity of an
																Hero and
																undertake any
																other necessary
																checks before
																any funds raised
																are transferred
																to the Hero that
																started the
																Campaign.
															</p>
														</li>
														<li>
															<p>
																Tribeworld
																accepts no
																liability to
																Contributors or
																Angels
																whatsoever for
																any use or
																misuse of
																contributions
																made to any
																Campaign. A Hero
																may state that
																page owners will
																use
																contributions
																only for
																specific
																purposes, but we
																cannot guarantee
																that they will
																use them for
																such purposes.
																If you have any
																doubts as to how
																the
																contributions
																will be spent,
																you should
																contact the Hero
																directly to seek
																reassurance. It
																is your sole
																responsibility,
																as a User of our
																platform, to
																ensure that the
																Campaign stated
																to be the Hero’s
																Campaign page is
																one that you
																wish to support.
															</p>
														</li>
														<li>
															<p>
																Heroes setting
																up either an
																Enterprise or
																Industrial
																Campaign will be
																charged platform
																fees which will
																be a percentage
																of the
																contributions
																raised by their
																Campaign (the
																"Platform
																Fees"). This
																platform fee
																will be in
																addition to
																Transaction Fees
																and
																Miscellaneous
																Fees as
																contained in Our
																Fees Policy. Our
																Fees are
																effective on the
																date that the
																Platform Fees
																are posted and
																may be updated
																from time to
																time. This
																clause is
																subject to our
																fees guidelines
																that can be
																accessed here
															</p>
														</li>
														<li>
															<p>
																Heroes setting
																up either an
																Enterprise or
																Industrial
																Campaign agrees
																that Tribeworld
																will retain ___%
																of the total
																contribution
																raised.
																“Retention Sum”.
																This retention
																sum will be paid
																to the Hero
																within 14 days
																of delivering
																Rewards to all
																the Angels who
																contributed to
																the Campaign and
																requested a
																Reward for the
																contribution.
															</p>
														</li>
														<li>
															<p>
																When donations
																are made to a
																Cause or
																Charity, Angels
																are allowed the
																option to donate
																to Tribeworld.
																These donations
																are independent
																of the
																Transaction Fees
																and
																Miscellaneous
																Fees that will
																be charged on
																the
																contribution.
																Please see our
																Fees Policy.
															</p>
														</li>
													</ol>
												</CollapseCard>
											</div>

											<div
												ref={this.propertyMattersRef}
												className="Legal-Section General-Faq"
											>
												<div className="section-title">
													<Title
														size="md"
														color="dark"
														isResponsive={true}
													>
														Intellectual Property
														Matters
													</Title>
													<CollapseCard title="Expand For Details">
														<ol>
															<li>
																<p>
																	Tribeworld’s
																	Intellectual
																	Property –
																	Our content,
																	service,
																	trademark
																	and
																	everything
																	on our
																	platform are
																	protected
																	through
																	various
																	intellectual
																	property
																	laws
																	including
																	but not
																	limited to
																	copyright,
																	patent,
																	trademark
																	and all
																	other
																	available
																	protections
																	under the
																	Laws of
																	England and
																	Wales.{" "}
																</p>
															</li>
															<li>
																<p>
																	By using our
																	platform,
																	you covenant
																	that you
																	will respect
																	our
																	intellectual
																	property and
																	not adapt it
																	or create
																	derivative
																	works off
																	our
																	contents. We
																	only grant
																	you a
																	limited,
																	temporary,
																	non-exclusive,
																	non-transferable
																	right to use
																	and access
																	our content
																	and our
																	Users’
																	content. You
																	cannot and
																	should not
																	reproduce,
																	redistribute,
																	transmit,
																	assign,
																	sell,
																	broadcast,
																	rent, share,
																	lend,
																	modify,
																	adapt or
																	copy any of
																	the content
																	on our
																	platform
																	unless you
																	get written
																	permission
																	from us.
																</p>
															</li>
															<li>
																<p>
																	We have the
																	right and
																	discretion
																	to limit or
																	revoke this
																	limited
																	license to
																	use.
																</p>
															</li>
															<li>
																<p>
																	Users’
																	Intellectual
																	Property -
																	Users grant
																	a
																	non-exclusive,
																	global, no
																	borders,
																	perpetual,
																	irrevocable,
																	royalty-free,
																	sublicensable,
																	and
																	transferable
																	right to use
																	of contents
																	to
																	Tribeworld
																	who can
																	exercise,
																	commercialise,
																	and exploit
																	the
																	copyright,
																	publicity,
																	trademark,
																	and database
																	rights with
																	respect to
																	Users’
																	Content.
																</p>
															</li>
															<li>
																<p>
																	Tribeworld
																	is hereby
																	granted the
																	right to
																	edit, amend,
																	make
																	changes,
																	translate,
																	format or
																	even delete
																	any Users’
																	content at
																	it deems
																	necessary
																	and
																	appropriate.
																</p>
															</li>
															<li>
																<p>
																	Users’
																	covenants,
																	represents
																	and warrants
																	that they
																	have the
																	right and
																	licence to
																	confer the
																	rights in
																	9(d) and
																	9(e) on
																	Tribeworld.
																	Users also
																	warrant that
																	they have
																	not
																	infringed on
																	any third
																	party’s
																	(intellectual,
																	privacy,
																	human,
																	proprietary,
																	confidentiality
																	or any other
																	rights)
																	rights.
																</p>
															</li>
															<li>
																<p>
																	Users
																	further
																	warrant that
																	Tribeworld
																	is not
																	required to
																	obtain any
																	license,
																	consent,
																	make any
																	payment or
																	obtain any
																	permission
																	from any
																	third party
																	to use any
																	User’s
																	content on
																	our
																	platform.
																	User agrees
																	that
																	Tribeworld
																	does not
																	have any
																	liability
																	from the use
																	of any User
																	content.
																</p>
															</li>
															<li>
																<p>
																	If there is
																	any
																	suspicion of
																	infringement
																	of your
																	intellectual
																	property
																	right by
																	another
																	User, please
																	do not
																	hesitate to
																	contact us
																	immediately
																	as this is
																	very
																	important to
																	us. You can
																	contact us
																	at
																	legal@tribeworld.org.
																</p>
															</li>
															<li>
																<p>
																	Please note
																	that we
																	reserve the
																	right and
																	sole
																	discretion
																	on whether
																	to delete,
																	disable the
																	content
																	infringing
																	the
																	intellectual
																	property as
																	alleged. We
																	can also
																	delete the
																	Users’
																	account and
																	ban them
																	from using
																	our service
																	or platform.
																	However,
																	this is also
																	at our
																	discretion.
																</p>
															</li>
														</ol>
													</CollapseCard>
												</div>

												<div
													ref={this.miscellaneousRef}
													className="Legal-Section General-Faq"
												>
													<div className="section-title">
														<Title
															size="md"
															color="dark"
															isResponsive={true}
														>
															Miscellaneous
															Provisions
														</Title>
														<CollapseCard title="Expand for details">
															<ol>
																<li>
																	<p>
																		<b>
																			Unsolicited
																			Idea
																		</b>
																		- Users
																		agree
																		that all
																		unsolicited
																		comments
																		and
																		advice
																		are
																		non-confidential
																		and non
																		-proprietary.
																		An
																		irrevocable,
																		worldwide,
																		transferable
																		and
																		royalty-free
																		license
																		to use,
																		exploit,
																		distribute
																		and
																		display
																		the
																		unsolicited
																		advice
																		or
																		communication
																		is
																		hereby
																		granted
																		to
																		Tribeworld.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Release
																			from
																			Liability
																			for
																			Site
																			Outages
																		</b>
																		- Users
																		agree
																		that
																		Tribeworld
																		does not
																		bear any
																		liability
																		from any
																		loss of
																		data,
																		business
																		opportunity
																		or
																		content
																		due to
																		the
																		scheduled
																		maintenance,
																		unplanned
																		outages
																		or
																		malfunctioning
																		of our
																		platform.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Anti-Corruption
																			and
																			Anti
																			Money-Laundering
																		</b>
																		- Users
																		agree
																		that in
																		using
																		our
																		platform,
																		they
																		will not
																		undertake
																		any
																		conduct
																		that
																		constitutes
																		an
																		offence
																		under
																		applicable
																		anti-corruption
																		and
																		anti-money
																		laundering
																		laws. A
																		breach
																		of this
																		clause
																		shall be
																		deemed a
																		material
																		breach
																		of this
																		Terms of
																		Use.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Severability
																		</b>
																		- In the
																		event
																		that any
																		provision
																		of this
																		Terms of
																		Use is
																		held to
																		be
																		invalid
																		or
																		unenforceable
																		in whole
																		or in
																		part,
																		all
																		other
																		provisions
																		will
																		continue
																		to be
																		valid
																		with the
																		invalid
																		and
																		unenforceable
																		parts
																		severed
																		from the
																		remainder
																		of this
																		Terms of
																		Use.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Third
																			Parties
																		</b>
																		- This
																		Terms of
																		Use does
																		not
																		confer
																		any
																		rights
																		on any
																		person
																		or party
																		other
																		than the
																		Users
																		and
																		Tribeworld.
																		The
																		rights
																		of the
																		parties
																		are not
																		subject
																		to the
																		consent
																		of any
																		other
																		person.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Headings
																		</b>
																		- The
																		paragraph
																		titles
																		in this
																		Terms of
																		Use are
																		for
																		convenience
																		only and
																		shall
																		not
																		define
																		or limit
																		any of
																		the
																		provisions
																		hereof.
																		These
																		headings
																		are used
																		for
																		nomenclature
																		purposes
																		alone
																		and do
																		not
																		denote
																		an
																		interpretation
																		tool for
																		any term
																		in this
																		Terms of
																		Use.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Amendment
																		</b>
																		- We may
																		update
																		this
																		Terms of
																		Use from
																		time to
																		time by
																		publishing
																		a new
																		version
																		on our
																		website.
																		The
																		right to
																		amend is
																		at our
																		sole
																		discretion.
																		You
																		should
																		check
																		this
																		page
																		occasionally
																		to
																		ensure
																		you
																		understand
																		any
																		changes
																		to this
																		Terms of
																		Use. We
																		may
																		notify
																		you of
																		changes
																		to this
																		policy
																		by email
																		or
																		through
																		email or
																		any
																		other
																		choice
																		of
																		communications
																		as set
																		by you.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Anti-
																			Slavery
																		</b>
																		- Users
																		covenants
																		that
																		they in
																		using
																		our
																		platform,
																		they
																		will not
																		undertake
																		any
																		conduct
																		that
																		constitutes
																		an
																		offence
																		under
																		applicable
																		anti-slavery
																		laws. A
																		breach
																		of this
																		clause
																		shall be
																		deemed a
																		material
																		breach
																		of this
																		Terms of
																		Use.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Notices
																		</b>
																		- Unless
																		otherwise
																		stated
																		in this
																		Terms of
																		Use or
																		any
																		other
																		relevant
																		part of
																		our
																		platform,
																		all
																		notices
																		and
																		other
																		communications
																		shall be
																		in
																		writing
																		and
																		delivered
																		by
																		electronic
																		mail. If
																		to
																		Tribeworld,
																		the
																		email
																		address
																		is
																		legal@tribeworld.org
																		unless
																		another
																		email
																		address
																		is
																		provided
																		on the
																		relevant
																		page on
																		our
																		platform
																		or this
																		Terms of
																		Use. If
																		to any
																		User,
																		the
																		email
																		address
																		will be
																		the
																		email
																		address
																		provided
																		when
																		registering
																		for an
																		account
																		or
																		making
																		contributions.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Waiver
																		</b>{" "}
																		- No
																		single
																		or
																		partial
																		exercise
																		of a
																		right or
																		remedy
																		provided
																		by this
																		Terms of
																		Use or
																		by law
																		prevents
																		the
																		further
																		exercise
																		of the
																		right or
																		remedy
																		or the
																		exercise
																		of
																		another
																		right or
																		remedy.
																		A waiver
																		of a
																		breach
																		of this
																		Terms of
																		Use does
																		not
																		constitute
																		a waiver
																		of a
																		subsequent
																		or prior
																		breach
																		of this
																		Terms of
																		Use.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Relationship
																			Of
																			The
																			Parties
																		</b>
																		- This
																		Terms of
																		Use is
																		intended
																		and
																		shall be
																		construed
																		as
																		creating
																		an
																		Agreement
																		for the
																		purposes
																		specified
																		in this
																		Terms of
																		Use.
																		Nothing
																		in this
																		Terms of
																		Use or
																		otherwise
																		is
																		intended
																		or shall
																		be
																		construed
																		as
																		creating
																		a
																		partnership
																		other
																		than as
																		specifically
																		set out
																		in this
																		Terms of
																		Use or
																		any
																		legal
																		entity
																		between
																		the
																		Users
																		and
																		Tribeworld
																		or any
																		on-going
																		or
																		continuing
																		relationship
																		or
																		commitment
																		between
																		the
																		Users of
																		Tribeworld,
																		other
																		than as
																		specifically
																		set out
																		in this
																		Terms of
																		Use.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Indemnity
																		</b>
																		- Users
																		agree to
																		indemnify
																		and hold
																		harmless
																		Tribeworld,
																		its
																		successors
																		and
																		assigns,
																		from and
																		against
																		all
																		losses,
																		costs
																		and
																		other
																		damage
																		caused
																		by the
																		User,
																		its
																		Campaign
																		or any
																		activity
																		that is
																		related
																		or
																		connected
																		to the
																		use of
																		our
																		platform.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Governing
																			Law
																		</b>{" "}
																		- This
																		Terms of
																		Use, the
																		jurisdiction
																		clause
																		contained
																		in it
																		and any
																		non-contractual
																		obligations
																		arising
																		out of
																		or in
																		connection
																		with it
																		or its
																		subject
																		matter
																		or
																		formation
																		are
																		governed
																		by,
																		construed
																		and take
																		effect
																		in
																		accordance
																		with the
																		law of
																		England
																		and
																		Wales.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Dispute
																			Resolution
																		</b>
																		-
																		Notwithstanding
																		the
																		provisions
																		of (o)
																		and (p),
																		for any
																		and all
																		grievances,
																		disputes,
																		claims,
																		or
																		controversies
																		you may
																		have
																		against
																		Tribeworld
																		(“Disputes”),
																		before
																		pursuing
																		any
																		dispute
																		resolution
																		avenue,
																		you must
																		first
																		give us
																		an
																		opportunity
																		to
																		resolve
																		the
																		Dispute
																		informally
																		by
																		sending
																		an email
																		to
																		legal@tribworld.org
																		with the
																		subject
																		“Pre-Action
																		Dispute
																		Notice”.
																		The
																		Email
																		should
																		contain
																		your
																		name,
																		your
																		email
																		address
																		as
																		registered
																		on your
																		account
																		(if you
																		have no
																		account,
																		please
																		state
																		that), a
																		detailed
																		description
																		of your
																		grievance
																		and
																		claims
																		and, a
																		description
																		of the
																		specific
																		relief
																		you seek
																		and how
																		we can
																		resolve
																		this
																		grievance.
																		If we do
																		not
																		resolve
																		the
																		Dispute
																		within
																		sixty
																		(60)
																		days
																		after
																		receiving
																		your
																		Pre-Action
																		Dispute
																		Notice,
																		then you
																		may
																		pursue
																		resolution
																		of the
																		Dispute
																		in
																		accordance
																		to (o)
																		and (p).
																	</p>
																</li>
																<li>
																	<p>
																		Subject
																		to (n)
																		and (p),
																		the
																		parties
																		agree to
																		submit
																		all
																		unresolved
																		disputes
																		between
																		them to
																		arbitration
																		administered
																		by the
																		International
																		Centre
																		for
																		Dispute
																		Resolution
																		(“ICDR”)
																		and
																		governed
																		by the
																		ICDR
																		Arbitration
																		Rules
																		(“ICDR
																		Rules”).
																		In any
																		arbitration
																		proceeding,
																		one (1)
																		arbitrator
																		will be
																		appointed
																		under
																		the ICDR
																		Rules,
																		and the
																		place of
																		arbitration
																		will be
																		London,
																		England
																		unless
																		the
																		parties
																		mutually
																		agree to
																		another
																		location
																		before
																		the
																		appointment
																		of the
																		arbitrator.
																	</p>
																</li>
																<li>
																	<p>
																		Notwithstanding
																		the
																		provisions
																		of (o),
																		either
																		party
																		may seek
																		injunctive
																		relief
																		for
																		infringement
																		of
																		intellectual
																		property
																		rights
																		or other
																		proprietary
																		rights
																		in
																		court.
																		Such
																		relief
																		will be
																		brought
																		before
																		the
																		courts
																		of
																		England
																		and
																		Wales,
																		who have
																		exclusive
																		jurisdiction
																		to
																		settle
																		any
																		dispute
																		or
																		claim.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Refunds
																		</b>
																		:
																		Tribeworld
																		takes a
																		restricted
																		approach
																		to
																		refund.
																		Refunds
																		on the
																		platform
																		are
																		dependent
																		on
																		whether
																		the
																		Campaign
																		contributed
																		and/or
																		Donated
																		to.
																		Refunds
																		on our
																		platform
																		are
																		subject
																		to our
																		refund
																		policy
																		that can
																		be
																		accessed
																		here.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Class
																			Action
																			Waiver
																		</b>{" "}
																		- Users
																		and
																		Tribeworld
																		agree
																		that any
																		proceedings
																		to
																		resolve
																		or
																		litigate
																		any
																		dispute
																		will be
																		conducted
																		solely
																		on an
																		individual
																		basis.
																		Neither
																		the
																		Users
																		nor
																		Tribeworld
																		will
																		seek to
																		have any
																		dispute
																		heard as
																		a class
																		action,
																		a
																		representative
																		action,
																		a
																		collective
																		action,
																		or in
																		any
																		proceeding
																		in which
																		the User
																		or
																		Tribeworld
																		acts or
																		proposes
																		to act
																		in a
																		representative
																		capacity.
																		The User
																		and
																		Tribeworld
																		further
																		agree
																		that no
																		proceeding
																		will be
																		joined,
																		consolidated,
																		or
																		combined
																		with
																		another
																		proceeding
																		without
																		the
																		prior
																		written
																		consent
																		of the
																		User,
																		Tribeworld,
																		and all
																		parties
																		to any
																		such
																		proceeding.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Warranty
																		</b>
																		-
																		Tribeworld
																		has no
																		fiduciary
																		duty to
																		any
																		User.
																		Our
																		Platform,
																		services,
																		content,
																		User
																		Content,
																		and any
																		other
																		related
																		materials
																		are
																		provided
																		on an
																		"as is"
																		and "as
																		available"
																		basis
																		without
																		any
																		warranties
																		of any
																		kind,
																		express
																		or
																		implied.
																		This no
																		warranty
																		includes
																		but is
																		not
																		limited
																		to
																		implied
																		warranties
																		of
																		merchantability,
																		quality
																		or
																		fitness
																		for a
																		particular
																		purpose,
																		non-infringement
																		or
																		course
																		of
																		performance.
																		This
																		no-warranty
																		clause
																		is
																		exercisable
																		to the
																		fullest
																		extent
																		permissible
																		by the
																		law. The
																		use of
																		our
																		platform
																		and
																		services,
																		including
																		without
																		limitation
																		any
																		services
																		provided
																		on any
																		third-party
																		platform,
																		are
																		entirely
																		at your
																		own
																		risk.
																	</p>
																</li>
																<li>
																	<p>
																		<b>
																			Confidentiality
																		</b>
																		- Except
																		to the
																		extent
																		required
																		by law,
																		any
																		legal or
																		regulatory
																		authority
																		of
																		competent
																		jurisdiction
																		or, with
																		the
																		prior
																		written
																		consent
																		of the
																		other
																		Party.
																		No party
																		shall
																		use or
																		disclose
																		to any
																		third
																		party
																		such
																		information
																		belonging
																		to the
																		other
																		party.
																		This
																		paragraph
																		shall
																		survive
																		the
																		termination
																		of this
																		Terms of
																		Use. For
																		the
																		avoidance
																		of
																		doubt,
																		Personal
																		Information
																		shall be
																		treated
																		in
																		accordance
																		with
																		Applicable
																		Laws and
																		shall
																		not be
																		considered
																		“confidential
																		information”
																		belonging
																		to a
																		party.
																	</p>
																</li>
																<li>
																	<p>
																		In the
																		event of
																		a User’s
																		failure
																		to
																		comply
																		with the
																		terms
																		contained
																		in this
																		Terms of
																		Use, our
																		Privacy
																		Policy
																		or
																		Cookies
																		Policy,
																		we
																		reserve
																		the
																		right
																		and sole
																		discretion
																		to
																		immediately
																		and
																		without
																		notice
																		suspend,
																		delete
																		or
																		permanently
																		ban the
																		Users’
																		access
																		to all
																		or part
																		of our
																		platform
																		or
																		services.
																	</p>
																</li>
																<li>
																	<p>
																		The
																		terms in
																		this
																		Terms of
																		Use
																		survive
																		the
																		usage of
																		the
																		platform.
																		Even
																		after
																		termination
																		or
																		deletion
																		of your
																		account
																		on our
																		platform
																		or the
																		discontinuing
																		of your
																		use of
																		our
																		services
																		for
																		whatsoever
																		reason,
																		either
																		by your
																		choice
																		or our
																		choice,
																		the
																		terms in
																		this
																		Terms of
																		Use
																		continue
																		to apply
																		post use
																		and
																		termination.
																	</p>
																</li>
															</ol>
														</CollapseCard>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<Footer />
			</main>
		);
	}
}

// const template = (
// 	<div ref={this.definitionsRef} className="Legal-Section General-Faq">
// 		<div className="section-title">
// 			<Title size="md" color="dark" isResponsive={true}>
// 				Overview and Introduction
// 			</Title>
// 			<CollapseCard title=""></CollapseCard>
// 		</div>
// 	</div>
// );

export default Legal;
