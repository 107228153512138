import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/Layouts/DashboardLayout/DashboardLayout";
import { connect } from "react-redux";
import { requestHandler } from "../../../utils/RequestHandler";
import sweetalert from "sweetalert";
import Navbar from "../../../components/Sections/Navbar/Navbar";
import "./CauseDetails.css";

import PageLoader from "../../../components/PageLoader/PageLoader";
import CauseDetailsLayout from "../../../components/Layouts/CauseDetailsLayout/CauseDetailsLayout";

function CauseDetails(props) {
  const [causeDetails, setcauseDetails] = useState([]);
  let causeId = props.match.params.id;

  const getCauseDetails = (id) => {
    return requestHandler("getCharity", { id })
      .then((res) => {
        if (res.status === 200) {
          setcauseDetails(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Server side error occured.");
        sweetalert(
          "Error",
          "Error occured while fetching Cause Details.",
          "error"
        );
      });
  };

  useEffect(() => {
    getCauseDetails(causeId);
  }, [causeId]);

  return (
    <>
      {causeDetails.length !== 0 ? (
        props.isAuthenticated ? (
          <DashboardLayout>
            <CauseDetailsLayout selectedCause={causeDetails["0"]} />;
          </DashboardLayout>
        ) : (
          <div className="notlogged-detailsPage">
            <div className="navbar-wrap">
              <Navbar mode="dark" />
            </div>
            <CauseDetailsLayout selectedCause={causeDetails["0"]} />;
          </div>
        )
      ) : (
        <PageLoader />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
}

export default connect(mapStateToProps)(CauseDetails);
