import React, { useState } from "react";
import LabelledInput from "../../../Inputs/LabelledInput/LabelledInput";
import PrimaryBtn from "../../../Buttons/PrimaryBtn/PrimaryBtn";
import "./DonationStepOne.css";
import CheckBox from "../../../Inputs/CheckBox/CheckBox";

function DonationStepOne(props) {
  const [text, setText] = useState();
  const validateInputs = () => {
    if (props.state.donationAmount) {
      setText("");
      props.changeStep("next");
    } else {
      setText("*Invalid Donation Amount");
    }
  };
  return (
    <div className="DonationStep">
      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="input"
          label="Please set Donation Amount"
          inputIcon="inputIcon"
          input={{
            type: "number",
            placeholder: "Amount",
            name: "donationAmount",
            onChange: props.inputChangeHandler,
            value: props.state.donationAmount,
          }}
        ></LabelledInput>
      </div>

      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="select"
          label="Donate to Platform"
          input={{
            type: "number",
            placeholder: "Donate to Platform",
            name: "donationForPlatform",
            onChange: props.inputChangeHandler,
            value: props.state.donationForPlatform,
          }}
        >
          <>
            {props.percentForPlatform
              ? props.percentForPlatform.map((percent, i) => (
                  <option
                    key={i}
                    value={percent}
                    selected={
                      percent === props.state.donationForPlatform ? true : false
                    }
                  >
                    {percent}
                  </option>
                ))
              : ""}
          </>
        </LabelledInput>
      </div>

      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="input"
          label="Message"
          input={{
            type: "text",
            placeholder: "Donate Message",
            name: "message",
            onChange: props.inputChangeHandler,
            value: props.state.message,
          }}
        />
      </div>
      <div className="input-wrapper">
        <CheckBox
          size="sm"
          label="I would like to donate anonymously"
          color="dark"
          input={{
            defaultChecked: props.state.donateAnonymously,
            onChange: props.setAnonymous,
            value: props.state.donateAnonymously,
            // checked: props.state.donateAnonymously ? true : false,
          }}
        />
      </div>

      <p className="Error-message">{text}</p>
      <div className="btn-wrapper">
        <PrimaryBtn
          type="button"
          size="md"
          color="LinearBlue"
          onClick={validateInputs}
        >
          Next Step
        </PrimaryBtn>
      </div>
    </div>
  );
}

export default DonationStepOne;
