import React, { useState, useEffect } from "react";
import UserAvatar from "../../../../../../components/UserAvatar";
import PropTypes from "prop-types";
import { createDateAndTimeFromISO } from "../../../../../../utils/helpers";
import LabelledInput from "../../../../../UI/Inputs/LabelledInput/LabelledInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { requestHandler } from "../../../../../../utils/RequestHandler";
import User from "../../../../../../Models/User";

function SingleComment(props) {
  const [showReply, setshowReply] = useState(false);
  const [message, setmessage] = useState();
  const [userCard, setUserCard] = useState();

  useEffect(() => {
    if (props.user) {
      User.GetUserCard(props.user._id).then(
        (result) => setUserCard(result),
        (err) => alert(err)
      );
    }
  }, [props.user]);

  let style =
    props.reply === "true" ? { paddingBottom: "2em" } : { paddingBottom: "0" };

  const inputChangeHandler = (event) => {
    setmessage(event.target.value);
  };

  const updateComment = () => {
    requestHandler(
      "updateComment",
      {
        replies: [
          {
            name: props.user.firstName + " " + props.user.lastName,
            message,
            createdAt: new Date().toISOString(),
          },
        ],
      },
      props.replyTo
    ).then((result) => {
      if (result.status === 200) {
        props.getComments(props.campaignId);
        setshowReply(!showReply);
      }
    });
  };
  return (
    <div className="single-commentTab">
      <div className="comment-user">
        <div className="commentUser-avatar">
          {userCard ? <UserAvatar avatar={userCard.avatar} /> : <UserAvatar />}
        </div>
        <p className="commentUser-name">{props.name}</p>
      </div>
      <div className="comment-content" style={{ style }}>
        <p>{props.commentText}</p>
      </div>
      <div className="comment-info">
        <p className="comment-date">
          {createDateAndTimeFromISO(props.time, true, true)}
        </p>
      </div>
      <div
        className={
          props.reply === "true" && props.isAuthenticated
            ? "reply-btn"
            : " d-none"
        }
      >
        <button onClick={() => setshowReply(!showReply)}>
          {showReply ? "Cancel" : "Reply"}
        </button>
      </div>
      {showReply ? (
        <div className="comment_ReplyBox">
          <LabelledInput
            size="autoWidth"
            inputType="input"
            label=""
            input={{
              type: "text",
              placeholder: "Reply",
              name: "message",
              onChange: inputChangeHandler,
            }}
          ></LabelledInput>

          <button onClick={updateComment}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      ) : null}
    </div>
  );
}

SingleComment.propTypes = {
  commentText: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  name: PropTypes.string,
  time: PropTypes.string,
};

export default SingleComment;
