import React from "react";
import "./PageLoader.css";
import { ClipLoader } from "react-spinners";

const PageLoader = () => {
	return (
		<div className="PageLoader">
			<ClipLoader color={"#3d79f2"} loading={true} size={100} />
		</div>
	);
};

export default PageLoader;
