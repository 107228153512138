import React, { Component } from "react";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout/DashboardLayout";
import { Link } from "react-router-dom";

class StartFundraiser extends Component {
	state = {};

	render() {
		return (
			<DashboardLayout>
				<main className="StartFundraiser">
					<Link to="/hero-createcause" className="d-block">
						Create Cause
					</Link>
					<Link to="/hero-createenterprise" className="d-block">
						Create Enterprise
					</Link>
				</main>
			</DashboardLayout>
		);
	}
}

export default StartFundraiser;
