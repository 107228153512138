import React from "react";
import "./PreviewGoal.css";

import ModalEditBtn from "../../../UI/Buttons/ModalEditBtn/ModalEditBtn";

function PreviewGoal(props) {
  return (
    <div className="cause-goal ">
      <div className="goal-details">
        <div className="goal-icon">
          <img src={props.icon} alt="goal-icon" />
        </div>
        <div className="goal">
          <div className="goal-amount">
            <p className="goal-heading">{props.name}</p>
            <p className="goal-limit">{props.amount}</p>
          </div>
          <div>
            <ModalEditBtn
              onClick={() => {
                props.for === "goal"
                  ? props.setshowGoalUpdateModal(true)
                  : props.setshowDurationUpdateModal(true);
              }}
              isAuthenticated={props.isAuthenticated}
              userId={props.user._id}
              causeUserId={props.creatorId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewGoal;
