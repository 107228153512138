import React, { useEffect, useState } from "react";
import { requestHandler } from "../../../utils/RequestHandler";
import sweetalert from "sweetalert";
import ProjectCard from "../../../components/UI/Cards/ProjectCard/ProjectCard";
import Title from "../../../components/UI/Typography/Title/Title";
import Navbar from "../../../components/Sections/Navbar/Navbar";
import "./Charities.css";

function Charities() {
  const [charities, setCharities] = useState([]);
  useEffect(() => {
    getCharities();
  }, []);

  console.log(charities);
  const getCharities = () => {
    return requestHandler("getCharityCard")
      .then((res) => {
        if (res.status === 200) {
          setCharities(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Server side error occured.");
        sweetalert("Error", "Error occured while fetching charities.", "error");
      });
  };

  return (
    <div className="charities">
      <div className="navbar-wrap">
        <Navbar mode="dark" />
      </div>
      <div className="fluid-container CharitiesList">
        <div className="row">
          <div className="col title-wrapper mx-auto">
            <Title size="lg" color="yellow" isResponsive={true}>
              Charities
            </Title>
          </div>
        </div>
        <div className="row">
          {charities
            ? charities.map((charity) => (
                <div className="col card-wrapper" key={charity._id}>
                  <ProjectCard for="hero" charity={charity} campaign="cause" />
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default Charities;
