import React, { Component, Fragment } from "react";
import "./Home.css";
import { connect } from "react-redux";
import sweetalert from "sweetalert";
import UserModel from "../../../Models/User";

// Images
// import bgDummy1 from "../../../assets/images/bgDummy1.png";
// import bgDummy2 from "../../../assets/images/bgDummy2.png";
// import HeaderImg from "../../../assets/images/headerImg.png";
import HomePage1Img from "../../../assets/images/HomePage1.jpg";
import WhyTribeMattersImg from "../../../assets/images/WhyTribeMatters.jpg";
import AreYouReadyToJoinImg from "../../../assets/images/AreYouReadyToJoin.jpg";

// Components
import Header from "../../../components/Sections/Header/Header";
import AboutAngels from "../../../components/Sections/Home/AboutAngels/AboutAngels";
import AboutHeroes from "../../../components/Sections/Home/AboutHeroes/AboutHeroes";
import TrendingProjects from "../../../components/Sections/Home/TrendingProjects/TrendingProjects";
import UserCardSlider from "../../../components/Sections/Home/UserCardSlider/UserCardSlider";
import BgShape from "../../../components/Layouts/BgShape/BgShape";
import FluidCard from "../../../components/Layouts/FluidCard/FluidCard";
import Footer from "../../../components/Sections/Footer/Footer";

class Home extends Component {
  state = {
    topHeroes: [],
  };

  componentDidMount() {
    this.getTopHeroes();
  }

  getTopHeroes = () => {
    UserModel.GetAllUserCard()
      .then((apiRes) => {
        if (apiRes.status === 200) {
          this.setState({ topHeroes: apiRes.data });
        }
      })
      .catch((err) => {
        sweetalert("Error", "Error occured while fetching heroes.", "error");
      });
  };

  render() {
    return (
      <main className="Home">
        <Fragment>
          <Header img={HomePage1Img} />
          <AboutHeroes />
          <AboutAngels />
          <TrendingProjects showBgShape={true} />
          <div className="First-FluidCard">
            <FluidCard
              img={WhyTribeMattersImg}
              headers={["why tribe", "matters more", "then ever"]}
              btnText={"tell me more"}
            />
          </div>
          <div className="First-UserCardSlider">
            <UserCardSlider
              title="Top Heroes"
              text="Meet some of Tribe’s top heroes and find out how they’re changing the world."
              btnText="View all"
              data={this.state.topHeroes}
            />
            <BgShape side="left" fromTop={0} />
          </div>
          {/* <div className="Second-UserCardSlider">
            <UserCardSlider
              title="Our Volunteers"
              text="Meet some of the people who make Tribe happen, and help us all to change the world."
              btnText="View all"
              data={this.state.topHeroes}
            />
          </div> */}
          <div className="Second-FluidCard">
            <FluidCard
              img={AreYouReadyToJoinImg}
              headers={["Are you ready to", "join the tribe?"]}
              btnText={"tell me more"}
            />
          </div>
          <Footer />
        </Fragment>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(Home);
