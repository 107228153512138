import React, { useState } from "react";
import "./Header.css";
import PropTypes from "prop-types";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import Navbar from "../Navbar/Navbar";
import Title from "../../UI/Typography/Title/Title";
import PrimaryBtn from "../../UI/Buttons/PrimaryBtn/PrimaryBtn";

const Header = (props) => {
  // bootstrap video modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <section className="Header">
      <div className="wrap-header-bg">
        <img src={props.img} alt="header-bg-img" />
      </div>
      <div className="overlay"></div>
      <Navbar mode="white" />
      <div className="container content-container">
        <div className="row">
          <div className="col">
            <Title size="lg" color="white" isResponsive={true}>
              Everyday Heroes
            </Title>
            <Title size="lg" color="white" isResponsive={true}>
              crowdfunding a
            </Title>
            <Title size="lg" color="white" isResponsive={true}>
              better world
            </Title>
            <p className="text">Are you ready to join the tribe?</p>
            <div className="btn-wrapper">
              <PrimaryBtn
                type="button"
                size="md"
                color="transparent"
                icon={faArrowRight}
                effect="hvr-icon-forward"
                onClick={handleShow}
              >
                Play Video
              </PrimaryBtn>
            </div>
          </div>
        </div>
      </div>
      {/* Video Modal */}
      <div className="Header-VideoModal">
        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Body className="modal-body">
            <iframe
              className="video-modal-frame"
              src="https://www.youtube.com/embed/Q5NuA91RhAQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Modal.Body>
        </Modal>
      </div>
    </section>
  );
};

Header.propTypes = {
  img: PropTypes.any.isRequired,
};

export default Header;
