import React, { useState } from "react";
import "./DonationForm.css";
import StepsBar from "../../StepsBar/StepsBar";
import DonationStepOne from "./DonationStepOne/DonationStepOne";
import DonationStepTwo from "./DonationStepTwo/DonationStepTwo";

const DonationForm = (props) => {
  const { donateToCampaign, inputChangeHandler, state, setAnonymous } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const labels = ["DONATE DETAILS", "PAYMENT"];

  function changeStep(changeType) {
    if (changeType === "next") {
      setCurrentStep(currentStep + 1);
    } else if (changeType === "prev") {
      setCurrentStep(currentStep - 1);
    }
  }

  return (
    <div className="DonationForm">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10 col-xl-10 mx-auto">
            <div className="wrapper">
              <div className="wrap-bar">
                <p className="donation-title ">Donate Now</p>
                <StepsBar labels={labels} currentStep={currentStep} />
              </div>
              <div className="wrap-form">
                {currentStep === 1 ? (
                  <DonationStepOne
                    changeStep={changeStep}
                    inputChangeHandler={(e) => inputChangeHandler("state", e)}
                    state={state}
                    setAnonymous={setAnonymous}
                    percentForPlatform={props.percentForPlatform}
                  />
                ) : currentStep === 2 ? (
                  <DonationStepTwo
                    changeStep={changeStep}
                    donateToCampaign={donateToCampaign}
                    inputChangeHandler={(e) => inputChangeHandler("state", e)}
                    state={state}
                    isAuthenticated={props.isAuthenticated}
                    validateInputs={props.validateInputs}
                    emailGoodToGo={props.emailGoodToGo}
                    checkingEmailExistenceInSystem={
                      props.checkingEmailExistenceInSystem
                    }
                    responseMessage={props.responseMessage}
                    setRegisterMe={props.setRegisterMe}
                    handleStripeToken={props.handleStripeToken}
                    signInUser={props.signInUser}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationForm;
