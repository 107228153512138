import React from "react";
import "./SectionHeader.css";
import PropTypes from "prop-types";
import Title from "../../UI/Typography/Title/Title";
import PrimaryBtn from "../../UI/Buttons/PrimaryBtn/PrimaryBtn";

const SectionHeader = (props) => {
  return (
    <div className="SectionHeader">
      <div className="container">
        <div className="row">
          <div className="col-10 col-md-8 col-xl-7 text-center mx-auto">
            <Title size="md" color="dark" isResponsive={true}>
              {props.title}
            </Title>
            <p>{props.text}</p>
            {props.btnText ? (
              <PrimaryBtn
                type="button"
                size="sm"
                color="transparentYellow"
                onClick={props.onClick}
              >
                {props.btnText}
              </PrimaryBtn>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
};

export default SectionHeader;
