import React, { useState } from "react";
import UserAvatar from "../../UserAvatar";
import "./UserProfileCard.css";
import PageLoader from "../../PageLoader/PageLoader";
import PrimaryBtn from "../../../components/UI/Buttons/PrimaryBtn/PrimaryBtn";
import { createDateAndTimeFromISO } from "../../../utils/helpers";
import sweeetalert from "sweetalert";
import Conversation from "../../../Models/Conversation";
import { Redirect } from "react-router-dom";

function UserProfileCard({ userCard, isAuthenticated }) {
  const [redirect, setredirect] = useState(null);

  const startConversation = async () => {
    if (!isAuthenticated) {
      return sweeetalert(
        "You need to logged In",
        "Please login to perform this activity!",
        "info"
      );
    }
    try {
      let conversation = await Conversation.FetchSingleConversation(
        userCard._id
      );
      setredirect("/inbox/" + conversation._id);
    } catch (e) {
      alert(
        "Cannot start the conversation at this moment. Please try again later"
      );
    }
  };
  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      {userCard ? (
        <div className="profile_card">
          <div className="card_top">
            <div className="user-avatar">
              <UserAvatar avatar={userCard.avatar} />
            </div>
            <p className="user_name">
              {userCard.firstName + " " + userCard.lastName}
            </p>
          </div>
          <div className="card_main">
            <p className="joined-date">
              Joined at:{" "}
              <span>{createDateAndTimeFromISO(userCard.createdAt, true)}</span>
            </p>
            <p className="location">
              Location:{" "}
              <span>
                {userCard.address ? userCard.address.location : "City,Country"}
              </span>
            </p>
          </div>
          <div className="user-buttons">
            <PrimaryBtn
              size="md"
              type="button"
              color="LinearBlue"
              onClick={startConversation}
            >
              Contact User
            </PrimaryBtn>
            <PrimaryBtn
              size="md"
              type="link"
              color="tranparentLinearBlue"
              to="#"
            >
              Report User
            </PrimaryBtn>
          </div>
        </div>
      ) : (
        <PageLoader />
      )}
    </>
  );
}

export default UserProfileCard;
