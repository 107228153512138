import React from "react";
import "./NotFound.css";
import BackgroundImg from "../../assets/images/notfound-bg.jpeg";
import TribePrimaryBtn from "../../components/UI/Buttons/TribePrimaryBtn/TribePrimaryBtn";

const NotFound = () => {
  return (
    <div id="notfound">
      <div className="bg-img">
        <img src={BackgroundImg} alt="bgImg" />
      </div>
      <div className="content">
        <h1>404</h1>
        <h2>Not to worry</h2>
        <h3 className="first-child">
          Even mighty heroes and wise angels get lost sometimes.
        </h3>
        <h3>Come back to the fire - the Elders are making soup.</h3>
        <div className="wrap-button">
          <TribePrimaryBtn
            type="dark"
            onClick={() => window.location.replace("/")}
          >
            Home Page
          </TribePrimaryBtn>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
