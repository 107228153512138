import PropTypes from "prop-types";
import React from "react";
import "./ChatMessage.css";

const ChatMessage = (props) => {
  const calculateTime = (date) => {
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const [hours, mins] = date.toString().split(" ")[4].split(":");
    let day = date.getDate();

    let monthIndex = date.getMonth();
    let monthName = monthNames[monthIndex];

    let year = date.getFullYear();

    return `${hours}:${mins} ${day}-${monthName}-${year}`;
  };

  return (
    <div className={`ChatMessage ${props.toSide} ${props.dashboardMode}`}>
      <div className="message">{props.message.content}</div>
      <div className="time">
        {calculateTime(new Date(props.message.createdAt))}
      </div>
    </div>
  );
};

ChatMessage.propTypes = {
  toSide: PropTypes.oneOf(["left", "right"]).isRequired,
  dashboardMode: PropTypes.string.isRequired,
};

export default ChatMessage;
