import React, { Fragment } from "react";
import "./LocationAutoComplete.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import LabelledInput from "../LabelledInput/LabelledInput";
import { GOOGLE_MAP_API } from "../../../../utils/constants";

class LocationAutoComplete extends React.Component {
  handleSelect = (location) => {
    geocodeByAddress(location)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => this.props.setLocatoinAutocomplete(location, latLng))
      .catch((error) => console.error("Error", error));
  };

  render() {
    const { dashboardMode } = this.props;

    return (
      <PlacesAutocomplete
        value={this.props.location}
        onChange={this.props.setLocatoinAutocomplete}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="LocationAutoComplete">
            {/* <div className="form-group">
							<input
								type="text"
								{...getInputProps({
									placeholder: "Location",
									className:
										"form-control location-search-input",
								})}
							/>
						</div> */}
            <div className="input-wrapper">
              <LabelledInput
                size="fullWidth"
                dashboardMode={dashboardMode}
                inputType="input"
                label="Location"
                input={{
                  type: "text",
                  placeholder: "Address",
                  ...getInputProps({
                    placeholder: "Address",
                    className: "form-control location-search-input",
                  }),
                }}
              />
            </div>
            <ul className="LocationAutoComplete-List list-group autocomplete-dropdown-container">
              <Fragment>
                {loading && <Fragment>Loading...</Fragment>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? {
                        backgroundColor: "#fafafa",
                        cursor: "pointer",
                      }
                    : {
                        backgroundColor: "#ffffff",
                        cursor: "pointer",
                      };
                  return (
                    <Fragment>
                      <li
                        key={suggestion.index}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        className="LocationAutoComplete-ListItem list-group-item"
                      >
                        {suggestion.description}
                      </li>
                    </Fragment>
                  );
                })}
              </Fragment>
            </ul>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_API,
})(LocationAutoComplete);
