import React, { useEffect, useState } from "react";
import "./Topbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBars, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import UserAvatar from "../../UserAvatar";
import superhero from "../../../assets/images/superhero.png";
import angel from "../../../assets/images/angel.png";
import User from "../../../Models/User";

const Topbar = (props) => {
  const [title, settitle] = useState("");
  const [dropDown, setdropDown] = useState(false);
  const [heroCard, setheroCard] = useState({});

  const handleDropdown = () => {
    setdropDown(!dropDown);
  };

  const openDashboardSidebar = () => {
    const sliderNavbar = document.getElementById("dashboardSidebar");
    sliderNavbar.style.transform = "scaleX(1)";
  };

  const getUserCard = (creatorId) => {
    User.GetUserCard(creatorId).then(
      (result) => setheroCard(result),
      (err) => alert(err)
    );
  };

  let path = window.location.pathname;

  const PageTitle = () => {
    if (props.for === "heroProfile") {
      settitle("Hero's Profile");
    } else {
      if (path === "/hero-createcause") {
        settitle("Create a Cause");
      } else if (path === "/hero-createenterprise") {
        settitle("Create an Enterprise");
      } else if (path === "/hero-causes") {
        settitle("Manage Cause");
      } else if (path === "/hero-enterprises") {
        settitle("Manage Enterprise");
      } else if (path === "/hero-dashboard") {
        settitle("Dashboard");
      } else if (path === "/tribe-world") {
        settitle("Tribe World");
      }
    }
  };

  useEffect(() => {
    PageTitle();
    getUserCard(props.user._id);
  }, []);

  return (
    <div className={`Topbar ${props.dashboardMode}`}>
      {/* hero causes and enterprises back button for closing modal */}
      {props.hero && props.hero !== null ? (
        <div className="back-button">
          <p
            onClick={() => {
              props.for === "heroCauses"
                ? props.setHeroCauses(false)
                : props.setHeroEnterprises(false);
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} /> Back
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="box box1">
        <button
          className="bars-btn d-block d-lg-none"
          onClick={openDashboardSidebar}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>

        <div className="mobile-title">
          <p>{title}</p>
        </div>

        <div className="d-none d-lg-block">
          {props.for === "heroProfile" ? (
            <p className="welcome-note d-none d-lg-inline-block">
              <span>Hero's Profile</span>
            </p>
          ) : // for hero campaigns page
          props.for === "heroCauses" ? (
            <p className="welcome-note d-none d-lg-inline-block ">
              <span>
                {props.hero ? `${props.hero.firstName}'s` : ""} Causes
              </span>
            </p>
          ) : props.for === "heroEnterprises" ? (
            <p className="welcome-note d-none d-lg-inline-block ">
              <span>
                {props.hero ? `${props.hero.firstName}'s` : ""} Enterprises
              </span>
            </p> // for hero campaigns page ends
          ) : path === "/hero-causes" ? (
            <p className="welcome-note d-none d-lg-inline-block">
              <span>Your Causes</span>
            </p>
          ) : path === "/hero-enterprises" ? (
            <p className="welcome-note d-none d-lg-inline-block">
              <span>Your Enterprises</span>
            </p>
          ) : path === "/tribe-world" ? (
            <p className="welcome-note d-none d-lg-inline-block">
              <span>Tribe World</span>
            </p>
          ) : (
            <p className="welcome-note d-none d-lg-inline-block">
              Welcome, <br />
              <span>{`${props.user.firstName} ${props.user.lastName}`}!</span>
            </p>
          )}
        </div>
      </div>
      <div className="box box2">
        {props.dashboardMode === "hero" ? (
          <div class="dashboard-mode">
            <img className="mode-image " src={superhero} alt="superhero mode" />
            <p className="switch-dashboard">
              Hero <span className="d-none d-lg-inline-block">Dashboard</span>
            </p>
            <BootstrapSwitchButton
              checked={props.switchState}
              onlabel="."
              onstyle=""
              offlabel="."
              offstyle=""
              style="Topbar-SwitchBtn"
              onChange={props.onSwitchHandler}
              width={30}
              height={10}
              size="xs"
            />
          </div>
        ) : (
          <div className="dashboard-mode">
            <img className="mode-image " src={angel} alt="superhero mode" />
            <p className="switch-dashboard">
              Angel <span className="d-none d-lg-inline-block">Dashboard</span>
            </p>
            <BootstrapSwitchButton
              checked={props.switchState}
              onlabel="."
              onstyle=""
              offlabel="."
              offstyle=""
              style="Topbar-SwitchBtn"
              onChange={props.onSwitchHandler}
              width={30}
              height={10}
              size="xs"
            />
          </div>
        )}
        {/* <SwitchButton checked={switchState} onChange={onSwitchHandler} /> */}

        <div className="topbar-right">
          <p className="notification-count">3</p>
          <div className="notifications">
            <FontAwesomeIcon className="bell-icon" icon={faBell} />
          </div>

          <div className="user-avatar">
            <span onClick={handleDropdown}>
              <UserAvatar avatar={heroCard.avatar} />
            </span>
            {dropDown && (
              <div className="user_Dropdown">
                <p className="logout_btn" onClick={props.logoutUser}>
                  Log Out
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
