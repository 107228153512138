import React, { useEffect, useState } from "react";
import SingleComment from "./SingleComment/SingleComment";
import { requestHandler } from "../../../../../utils/RequestHandler";
import { Editor } from "@tinymce/tinymce-react";
import { tinyCloudAPIKey } from "../../../../../utils/constants";
import PrimaryBtn from "../../../../UI/Buttons/PrimaryBtn/PrimaryBtn";
import ReactHtmlParser from "react-html-parser";
import sweetalert from "sweetalert";
import "./Comments.css";

function Comments({ campaignId, user, isAuthenticated }) {
  const [comments, setcomments] = useState([]);
  const [commentMessage, setcommentMessage] = useState();

  const updateChangeHandler = (content) => {
    setcommentMessage(content);
  };

  const getComments = (campaignId) => {
    return requestHandler("getCampaignComments", { campaignId })
      .then((res) => {
        setcomments(res.data);
      })
      .catch((err) => {
        console.log(err);
        sweetalert("Error", "Error Occured While fetching comments.", "error");
      });
  };

  const postComment = () => {
    const message = commentMessage;
    let data = {
      campaignId,
      message,
    };
    requestHandler("createCampaignComment", data).then((result) => {
      if (result.status === 200) {
        setcommentMessage("");
        getComments(campaignId);
      }
    });
    console.log(data);
  };

  useEffect(() => {
    getComments(campaignId);
  }, [campaignId]);

  return (
    <div className="comments-section">
      {isAuthenticated ? (
        <div className="comment-inputWrap">
          <div className="comment-editor">
            <Editor
              apiKey={tinyCloudAPIKey}
              initialValue=""
              init={{
                height: 150,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar: `undo redo | formatselect | bold italic |
                                          alignleft aligncenter alignright alignjustify |
                                          bullist numlist outdent indent | removeformat | help `,
              }}
              onEditorChange={updateChangeHandler}
            />
          </div>
          <div className="update-btnWrap">
            <PrimaryBtn
              color="LinearBlue"
              type="button"
              size="md"
              onClick={postComment}
            >
              Send
            </PrimaryBtn>
          </div>
        </div>
      ) : (
        <p className="not-authenticated">Log In to Comment</p>
      )}
      {comments.length !== 0 ? (
        comments.map((comment) => {
          return (
            <div className="comment" key={comment._id}>
              <SingleComment
                replyTo={comment._id}
                getComments={getComments}
                campaignId={campaignId}
                user={user}
                isAuthenticated={isAuthenticated}
                reply="true"
                commentText={ReactHtmlParser(comment.message)}
                name={comment.user.firstName + " " + comment.user.lastName}
                time={comment.createdAt}
              />
              {comment.replies.length
                ? comment.replies.map((reply) => {
                    return (
                      <div className="comment-reply" key={reply.createdAt}>
                        <SingleComment
                          reply="false"
                          commentText={reply.message}
                          name={reply.name}
                          time={reply.createdAt}
                        />
                      </div>
                    );
                  })
                : ""}
            </div>
          );
        })
      ) : (
        <p className="no-comments">Be the first to comment</p>
      )}
    </div>
  );
}

export default Comments;
