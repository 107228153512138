import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./HeroAngelModal.css";
import Navbar from "../../../Sections/Navbar/Navbar";
import PrimaryBtn from "../../Buttons/PrimaryBtn/PrimaryBtn";

function HeroAngelModal({ show, handleShow }) {
  const [fullscreen, setFullscreen] = useState(true);
  const [causeHover, setCauseHover] = useState(false);
  const [enterpriseHover, setEnterpriseHover] = useState(false);

  const handleMouseOver = (project) => {
    if (project === "cause") {
      setCauseHover(true);
    } else {
      setEnterpriseHover(true);
    }
  };

  const handleMouseOut = (projecet) => {
    if (projecet === "cause") {
      setCauseHover(false);
    } else {
      setEnterpriseHover(false);
    }
  };

  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={handleShow}>
        <Modal.Body>
          <main>
            <Navbar mode="dark" />
            <div
              className={
                causeHover
                  ? "HeroAngelModal causeHover"
                  : enterpriseHover
                  ? "HeroAngelModal enterpriseHover"
                  : "HeroAngelModal"
              }
            >
              <section className="option_wrapper">
                <p className="option_title">What are you raising money for? </p>
                <div className="option_btns">
                  <div className="create-cause">
                    <div
                      className="heroAngelModal__btns"
                      onMouseOver={() => handleMouseOver("cause")}
                      onMouseOut={() => handleMouseOut("cause")}
                    >
                      <PrimaryBtn
                        type="link"
                        to="/hero-createcause"
                        size="cover"
                        color="LinearBlue"
                      >
                        Create Cause
                      </PrimaryBtn>
                    </div>
                    <p className="mt-3">
                      When you create a project through Tribe - a Cause - you
                      become a Hero, and can begin seeking the support of
                      Angels.
                    </p>
                  </div>
                  <div
                    className={
                      enterpriseHover
                        ? "create-enterprise"
                        : "create-enterprise"
                    }
                  >
                    {" "}
                    <div
                      className="heroAngelModal__btns"
                      onMouseOver={() => handleMouseOver("enterprise")}
                      onMouseOut={() => handleMouseOut("enterprise")}
                    >
                      <PrimaryBtn
                        type="link"
                        to="/hero-createenterprise"
                        size="cover"
                        color="LinearBlue"
                      >
                        Create Enterprise
                      </PrimaryBtn>
                    </div>
                    <p className="mt-3">
                      When you create a project through Tribe - an Enterprise -
                      you become a Hero, and can begin seeking the support of
                      Angels.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HeroAngelModal;
