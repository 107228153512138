import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/Layouts/DashboardLayout/DashboardLayout";
import { connect } from "react-redux";
import { requestHandler } from "../../../utils/RequestHandler";
import sweetalert from "sweetalert";
import Navbar from "../../../components/Sections/Navbar/Navbar";
import "./EnterpriseDetails.css";

import PageLoader from "../../../components/PageLoader/PageLoader";
import EnterpriseDetailsLayout from "../../../components/Layouts/EnterpriseDetailsLayout/EnterpriseDetailsLayout";

function EnterpriseDetails(props) {
  const [enterpriseDetails, setenterpriseDetails] = useState([]);
  let enterpriseId = props.match.params.id;

  const getEnterpriseDetails = (id) => {
    return requestHandler("getEnterprise", { id })
      .then((res) => {
        if (res.status === 200) {
          setenterpriseDetails(res.data);
        }
      })
      .catch((err) => {
        sweetalert(
          "Error",
          "Error occured while fetching Enerpise Details.",
          "error"
        );
      });
  };

  useEffect(() => {
    getEnterpriseDetails(enterpriseId);
  }, [enterpriseId]);

  return (
    <>
      {enterpriseDetails.length !== 0 ? (
        props.isAuthenticated ? (
          <DashboardLayout>
            <EnterpriseDetailsLayout
              selectedEnterprise={enterpriseDetails["0"]}
            />
          </DashboardLayout>
        ) : (
          <div className="notlogged-detailsPage">
            <div className="navbar-wrap">
              <Navbar mode="dark" />
            </div>
            <EnterpriseDetailsLayout
              selectedEnterprise={enterpriseDetails["0"]}
            />
          </div>
        )
      ) : (
        <PageLoader />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
}

export default connect(mapStateToProps)(EnterpriseDetails);
