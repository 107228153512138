import React, { useState } from "react";
import "./DetailsSummary.css";
import CollapseCard from "../../../UI/Cards/CollapseCard/CollapseCard";
import Story from "./Story/Story";
import Faqs from "./Faqs/Faqs";
import Updates from "./Updates/Updates";
import Comments from "./Comments/Comments";
import Donations from "./Donations/Donations";

function DetailsSummary(props) {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  let DetailsDesktop = (
    <div className="detailsSummary-wrap">
      <div className="summary-tabs">
        <ul className="summarytabs-list">
          <li
            className={toggleState === 1 ? "active" : ""}
            onClick={() => toggleTab(1)}
          >
            <span className="tab-heading">STORY</span>
          </li>
          <li
            className={toggleState === 2 ? "active" : ""}
            onClick={() => toggleTab(2)}
          >
            <span className="tab-heading">FAQS</span>
          </li>
          <li
            className={toggleState === 3 ? "active" : ""}
            onClick={() => toggleTab(3)}
          >
            <span className="tab-heading">UPDATES</span>
          </li>
          <li
            className={toggleState === 4 ? "active" : ""}
            onClick={() => toggleTab(4)}
          >
            <span className="tab-heading">COMMENTS</span>
          </li>
          <li
            className={toggleState === 5 ? "active" : ""}
            onClick={() => toggleTab(5)}
          >
            DONATIONS
          </li>
        </ul>
      </div>

      <div className="summary-content">
        <div
          className="tabWrap"
          style={{ display: toggleState === 1 ? "block" : "none" }}
        >
          <div className="tabContent">
            <Story story={props.campaign.story} />
          </div>
        </div>
        <div
          className="tabWrap"
          style={{ display: toggleState === 2 ? "block" : "none" }}
        >
          <div className="tabContent">
            <Faqs faqs={props.campaign.faqs} />
          </div>
        </div>
        <div
          className="tabWrap"
          style={{ display: toggleState === 3 ? "block" : "none" }}
        >
          <div className="tabContent">
            <Updates
              campaignId={props.campaign._id}
              creatorId={props.campaign.creatorId}
              user={props.user}
            />
          </div>
        </div>
        <div
          className="tabWrap"
          style={{ display: toggleState === 4 ? "block" : "none" }}
        >
          <div className="tabContent">
            <Comments
              campaignId={props.campaign._id}
              user={props.user}
              isAuthenticated={props.isAuthenticated}
            />
          </div>
        </div>
        <div
          className="tabWrap"
          style={{ display: toggleState === 5 ? "block" : "none" }}
        >
          <div className="tabContent">
            <Donations campaignId={props.campaign._id} />
          </div>
        </div>
      </div>
    </div>
  );

  let DetailsMobile = (
    <div className="detailsSummary-wrap">
      <CollapseCard title="Story">
        <Story story={props.campaign.story} />
      </CollapseCard>
      <CollapseCard title="faqs">
        <Faqs faqs={props.campaign.faqs} />
      </CollapseCard>
      <CollapseCard title="updates">
        <Updates
          campaignId={props.campaign._id}
          creatorId={props.campaign.creatorId}
          user={props.user}
        />
      </CollapseCard>
      <CollapseCard title="Comments">
        <Comments
          campaignId={props.campaign._id}
          user={props.user}
          isAuthenticated={props.isAuthenticated}
        />
      </CollapseCard>
      <CollapseCard title="Donations">
        <Donations campaignId={props.campaign._id} />
      </CollapseCard>
    </div>
  );
  let windowWidth = window.innerWidth;
  if (windowWidth > 991) {
    return <div className="details-summary">{DetailsDesktop}</div>;
  } else {
    return <div className="details-summary">{DetailsMobile}</div>;
  }
}

export default DetailsSummary;
