import React, { useState, useEffect } from "react";
import "./ProjectCard.css";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import CardImg from "../../../../assets/images/cardImg.png";
import { requestHandler } from "../../../../utils/RequestHandler/index";
// import Hero from "../../../../Models/Hero";
import { createDateAndTimeFromISO } from "../../../../utils/helpers";
import UserAvatar from "../../../UserAvatar";

import {
  faLandmark,
  faBriefcaseMedical,
  faGraduationCap,
  faCalendarDay,
  faHandHoldingHeart,
  faAmbulance,
  faTree,
  faPalette,
  faLaptopCode,
  faFilm,
  faPizzaSlice,
  faTshirt,
  faGamepad,
  faMusic,
  faBook,
  faHeartbeat,
  faTractor,
  faCommentAlt,
  faBraille,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sweetalert from "sweetalert";

const ProjectCard = (props) => {
  const [comments, setComments] = useState([]);
  const [remainingDays, setremainingDays] = useState();
  const [categories, setCategories] = useState();
  const [donations, setdonations] = useState();

  const campaignId = props.charity._id;
  const categoryId = props.charity.categoryId;

  const cardImg = props.charity.content
    ? props.charity.content.length !== 0
      ? props.charity.content[0]
      : CardImg
    : CardImg;

  const getDaysPassed = () => {
    let createdDate = createDateAndTimeFromISO(props.charity.createdAt, true);
    let prevDate = new Date(createdDate),
      end = new Date(),
      diff = 0,
      days = 1000 * 3600 * 24;

    diff = end - prevDate;
    let daysPassed = Math.floor(diff / days);
    setremainingDays(props.charity.duration - daysPassed);
  };

  const progressBar = Math.floor(
    (props.charity.donationAmount / props.charity.goal) * 100
  );

  // const getDonations = (id) => {
  //   return requestHandler("getDonationsForCharity", { id })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setdonations(res.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       sweetalert("Error", "Error occured while fetching donations.", "error");
  //     });
  // };

  const getComments = () => {
    return requestHandler("getCampaignComments", { campaignId })
      .then((res) => {
        setComments(res.data);
      })
      .catch((err) => {
        console.log(err);
        sweetalert("Error", "Error Occured While fetching comments.", "error");
      });
  };

  const getCategory = () => {
    requestHandler("getCategories")
      .then((res) => {
        if (res.status === 200) {
          setCategories(res.data);
        }
      })
      .catch((err) => {
        sweetalert(
          "Error",
          "Error occured while fetching Category for project card.",
          "error"
        );
      });
  };

  let icon = faBraille;

  // let categoryName = null;

  // if (categories && props.charity.categoryId) {
  //   categoryName = categories.filter((category) => category._id === categoryId);
  // }

  // switch (categoryName) {
  //   case "memorial":
  //     icon = faLandmark;
  //     break;
  //   case "medical":
  //     icon = faBriefcaseMedical;
  //     break;
  //   case "education":
  //     icon = faGraduationCap;
  //     break;
  //   case "special event / commemoration":
  //     icon = faCalendarDay;
  //     break;
  //   case "nonprofit / charity":
  //     icon = faHandHoldingHeart;
  //     break;
  //   case "emergency":
  //     icon = faAmbulance;
  //     break;
  //   case "environmental":
  //     icon = faTree;
  //     break;
  //   case "art / design":
  //     icon = faPalette;
  //     break;
  //   case "technology":
  //     icon = faLaptopCode;
  //     break;
  //   case "film":
  //     icon = faFilm;
  //     break;
  //   case "food & beverages":
  //     icon = faPizzaSlice;
  //     break;
  //   case "fashion & wearables":
  //     icon = faTshirt;
  //     break;
  //   case "games":
  //     icon = faGamepad;
  //     break;
  //   case "music":
  //     icon = faMusic;
  //     break;
  //   case "publishing (books)":
  //     icon = faBook;
  //     break;
  //   case "wellness":
  //     icon = faHeartbeat;
  //     break;
  //   case "farming & agriculture":
  //     icon = faTractor;
  //     break;
  //   case "other (how would you categorise your project?)":
  //     icon = faGolfBall;
  //     break;
  //   default:
  //     icon = faGolfBall;
  //     break;
  // }
  switch (props.charity.categoryId) {
    case "5ef083558910271f55a44178": //memorial
      icon = faLandmark;
      break;
    case "5ef083558910271f55a44177": //medical
      icon = faBriefcaseMedical;
      break;
    case "5ef083558910271f55a4417b": //education
      icon = faGraduationCap;
      break;
    case "5ef083558910271f55a4417c": //special event / commemoration
      icon = faCalendarDay;
      break;
    case "5ef083558910271f55a4417a": // nonprofit / charity
      icon = faHandHoldingHeart;
      break;
    case "5ef083558910271f55a44179": //emergency
      icon = faAmbulance;
      break;
    case "5f453f943387c33f0ab79348" || "5f453fa72b62f0406986a780": //environmental
      icon = faTree;
      break;
    case "5f453fb62b62f0406986a781": //art / design"
      icon = faPalette;
      break;
    case "5f453fbe2b62f0406986a782": //technology
      icon = faLaptopCode;
      break;
    case "5f453fc52b62f0406986a783": //film
      icon = faFilm;
      break;
    case "5f453fcd2b62f0406986a784": //food & beverages
      icon = faPizzaSlice;
      break;
    case "5f453fda2b62f0406986a785": //fashion & wearables
      icon = faTshirt;
      break;
    case "5f453fe12b62f0406986a786": //games
      icon = faGamepad;
      break;
    case "5f453fe72b62f0406986a787": //music
      icon = faMusic;
      break;
    case "5f453fee2b62f0406986a788": //publishing (books)
      icon = faBook;
      break;
    case "5f453ff52b62f0406986a789": //wellness
      icon = faHeartbeat;
      break;
    case "5f453ffe2b62f0406986a78a": //farming & agriculture
      icon = faTractor;
      break;
    case "5f4540132b62f0406986a78b": //other (how would you categorise your project?)
      icon = faBraille;
      break;
    default:
      icon = faBraille;
      break;
  }

  useEffect(() => {
    getComments();
    getDaysPassed();
    getCategory();
    // getDonations();
  }, []);

  return (
    <div
      className="ProjectCard"
      onClick={() =>
        props.campaign === "cause"
          ? props.history.push(`/charity/details/${props.charity._id}`)
          : props.history.push(`/enterprise/details/${props.charity._id}`)
      }
    >
      <div className="top-img">
        <img src={cardImg} alt="CardImg" />
      </div>
      <div className="content-box">
        <div className="body">
          <div className="wrapper-title">
            <p className="category-icon">
              <FontAwesomeIcon icon={icon} />
            </p>
            <p className="card_title">{props.charity.title}</p>
          </div>
          <p className="text">
            {props.charity.shortDescription || "No description"}
          </p>
          <p className="location-details">
            Location:<span> {props.charity.locationName}</span>
          </p>

          <div className="goal_and_achieved">
            <p>${props.charity.donationAmount || 0}</p>
            <p>${props.charity.goal || 0}</p>
          </div>

          <div className="card-progressbar ">
            <ProgressBar
              className={`${progressBar >= 100 ? "complete" : ""}`}
              now={progressBar}
            />
          </div>

          <div className="charity_details">
            <p>Achieved</p>
            <p>
              {props.campaign === "cause"
                ? ""
                : ` ${remainingDays <= 0 ? 0 : remainingDays || 0} Days Left`}
            </p>
            <p>Goal</p>
          </div>
        </div>
        <div className="footer">
          <p className="charity-comments">
            <FontAwesomeIcon icon={faCommentAlt} />
            <span>{comments.length}</span>
          </p>
          <p className="charity-donations">
            {props.charity.anglesBacked || 0} donates{" "}
            <span className="donates">
              <div className="donates-avatars">
                <UserAvatar />
              </div>
              <div className="donates-avatars">
                <UserAvatar />
              </div>
              <div className="donates-avatars">
                <UserAvatar />
              </div>
            </span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  for: PropTypes.oneOf(["hero", "angel"]).isRequired,
  charity: PropTypes.object.isRequired,
  campaign: PropTypes.oneOf(["cause", "enterprise"]).isRequired,
};

export default withRouter(ProjectCard);
