import React, { useState, Fragment } from "react";
import "./Navbar.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import TribeLogo from "../../UI/TribeLogo/TribeLogo";
import PrimaryBtn from "../../UI/Buttons/PrimaryBtn/PrimaryBtn";
import SliderNavbar from "./SliderNavbar/SliderNavbar";
import LoginModal from "../../UI/Modals/LoginModal/LoginModal";
import SignupModal from "../../UI/Modals/SignupModal/SignupModal";
import SearchBar from "../../UI/Modals/SearchBar/SearchBar";

const Navbar = (props) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  const toggleSignupModal = () => {
    setShowSignupModal(!showSignupModal);
  };

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const openSliderNavbar = () => {
    const sliderNavbar = document.getElementById("SliderNavbar");
    sliderNavbar.style.transform = "scaleX(1)";
  };

  return (
    <section className="Navbar">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className={`Navbar-wrapper ${props.mode}`}>
              <div className="Wrap-logo">
                <TribeLogo type="black" />
              </div>
              <div className="Wrap-menubar">
                <div className="Menubar-mobile d-block d-lg-none">
                  <ul className="Menubar">
                    <li className="Menubar-item">
                      <div className="searchbar" onClick={toggleSearchBar}>
                        <FontAwesomeIcon icon={faSearch} />
                      </div>
                      <SearchBar
                        show={showSearchBar}
                        viewport="mobile"
                        toggleSearchBar={toggleSearchBar}
                      />
                    </li>
                    <li className="Menubar-item">
                      <div className="Menubar-link" onClick={openSliderNavbar}>
                        Menu
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="Menubar-desktop d-none d-lg-block">
                  <ul className="Menubar">
                    <li className="Menubar-item">
                      <Link to="/findcause" className="Menubar-link">
                        Find Causes
                      </Link>
                      <SearchBar
                        show={showSearchBar}
                        viewport="desktop"
                        toggleSearchBar={toggleSearchBar}
                      />
                    </li>
                    <li className="Menubar-item">
                      <Link to="/howitworks" className="Menubar-link">
                        How it works
                      </Link>
                    </li>
                    <li className="Menubar-item Dropdown-item">
                      <Link to="#" className="Menubar-link" disabled>
                        About
                        <span className="downIcon">
                          <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                      </Link>
                      <div className="dropdown-container">
                        <div>
                          <Link className="dropdown-link" to="/about">
                            About us
                          </Link>
                        </div>
                        <div>
                          <Link className="dropdown-link" to="/news">
                            News
                          </Link>
                        </div>
                        <div>
                          <Link className="dropdown-link" to="/contact">
                            Contact
                          </Link>
                        </div>
                        <div>
                          <Link className="dropdown-link" to="/legal">
                            Legal
                          </Link>
                        </div>
                        <div>
                          <Link className="dropdown-link" to="/FAQS">
                            FAQS
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li className="Menubar-item">
                      <div className="searchbar" onClick={toggleSearchBar}>
                        <FontAwesomeIcon icon={faSearch} />
                      </div>
                    </li>
                    {props.isAuthenticated ? (
                      <li className="Menubar-item">
                        <PrimaryBtn
                          type="link"
                          size="md"
                          color="yellow"
                          to={`/${props.dashboardMode}-dashboard`}
                          effect="hvr-grow"
                        >
                          Dashboard
                        </PrimaryBtn>
                      </li>
                    ) : (
                      <Fragment>
                        <li className="Menubar-item">
                          <PrimaryBtn
                            type="button"
                            size="sm"
                            color="yellow"
                            onClick={toggleSignupModal}
                            effect="hvr-grow"
                          >
                            Join us
                          </PrimaryBtn>
                        </li>
                        <li className="Menubar-item">
                          <PrimaryBtn
                            type="button"
                            size="sm"
                            color={
                              props.mode !== "dark"
                                ? "transparent"
                                : "transparentYellow"
                            }
                            onClick={toggleLoginModal}
                            effect="hvr-grow"
                          >
                            Login
                          </PrimaryBtn>
                        </li>
                      </Fragment>
                    )}
                  </ul>
                </div>
              </div>
              <SliderNavbar isAuthenticated={props.isAuthenticated} />
            </div>
          </div>
        </div>
      </div>
      <LoginModal showModal={showLoginModal} toggleModal={toggleLoginModal} />
      <SignupModal
        showModal={showSignupModal}
        toggleModal={toggleSignupModal}
      />
    </section>
  );
};

Navbar.propTypes = {
  mode: PropTypes.oneOf(["white", "dark"]),
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    dashboardMode: state.dashboard.mode,
  };
}

export default connect(mapStateToProps)(Navbar);
