import React from "react";
import "./WorldImpact.css";
import { Radar as Radarjs } from "react-chartjs-2";
import ImpactValue from "../ImpactValue/ImpactValue";
import {
  getDefaultImpactLabelsAndValues,
  getImpactLabel,
} from "../../../../utils/helpers";

function WorldImpact({ impact }) {
  let impactLables = null,
    impactValues = null;
  if (impact) {
    [impactLables, impactValues] = impact;
  } else {
    [impactLables, impactValues] = getDefaultImpactLabelsAndValues();
  }
  impactLables = impactLables.map((key) => getImpactLabel(key));

  let data = {
    labels: impactLables,
    datasets: [
      {
        label: "Impact",
        data: impactValues,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      },
    ],
  };
  return (
    <div className="world-impact">
      <p className="impact-heading-top">Impact the world</p>
      <div className="worldImpact-wrap">
        <div className="impact-left">
          <p className="impact-heading">Impact the world</p>
          <div className="row name-values">
            {impactLables.map((label, index) => (
              <ImpactValue label={label} value={impactValues[index]} />
            ))}
          </div>
        </div>
        <div className="impact-right">
          <Radarjs data={data}></Radarjs>
        </div>
      </div>
    </div>
  );
}

export default WorldImpact;
