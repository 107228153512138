import React from "react";
import PropTypes from "prop-types";
import "./PrimaryAuthInput.css";

const PrimaryAuthInput = (props) => (
	<input {...props} className={`PrimaryAuthInput ${props.borderStyle}`} />
);

PrimaryAuthInput.propTypes = {
	borderStyle: PropTypes.oneOf([
		"rounded",
		"topBordersRounded",
		"bottomBordersRounded",
	]),
};

export default PrimaryAuthInput;
