import React, { useEffect, useState } from "react";
import Hero from "../../../../../Models/Hero";
import HeroCauseItem from "./HeroCauseItem/HeroCauseItem";

function HeroCampaigns({ creatorId, firstName, categories, campaignType }) {
  const [herocampaigns, setheroCampaigns] = useState({});

  function getHeroCampaigns(creatorId) {
    if (campaignType === "cause") {
      Hero.fetchCauses(creatorId).then(
        (result) => {
          setheroCampaigns(result);
        },
        (err) => alert(err)
      );
    } else if (campaignType === "enterprise") {
      Hero.fetchEnterprises(creatorId).then(
        (result) => {
          setheroCampaigns(result);
        },
        (err) => alert(err)
      );
    }
  }

  useEffect(() => {
    getHeroCampaigns(creatorId);
  }, [creatorId]);

  return (
    <div className="hero-causes-section">
      <p className="hero-causes-heading">
        {firstName} {campaignType === "enterprise" ? "Enterprises" : "Causes"}
      </p>
      <div className="hero-causes-wrap">
        <div className="hero-causes-list">
          {herocampaigns.length ? (
            herocampaigns.map((campaign) => {
              return (
                <div key={campaign._id}>
                  <HeroCauseItem
                    campaign={campaign}
                    categories={categories}
                    campaignType={campaignType}
                  />
                </div>
              );
            })
          ) : (
            <h4>Loading Campaigns</h4>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeroCampaigns;
