import React from "react";
import "./LabelledInput.css";
import PropTypes from "prop-types";

const LabelledInput = (props) => {
  let InputHTML = null;

  switch (props.inputType) {
    case "input":
      InputHTML = (
        <input {...props.input} className={`InputField ${props.inputIcon} `} />
      );
      break;
    case "textarea":
      InputHTML = (
        <textarea {...props.input} className="InputField" rows="4"></textarea>
      );
      break;
    case "select":
      InputHTML = (
        <select {...props.input} className="InputField">
          {props.children}
        </select>
      );
      break;

    default:
      InputHTML = <input {...props.input} className="InputField" />;
  }

  return (
    <div
      className={`LabelledInput ${props.size} ${props.dashboardMode} ${props.image}`}
    >
      {props.label ? <label className="Label">{props.label}</label> : null}

      {InputHTML}
    </div>
  );
};

LabelledInput.propTypes = {
  inputType: PropTypes.oneOf(["input", "textarea", "select"]).isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  dashboardMode: PropTypes.oneOf(["hero", "angel"]),
  size: PropTypes.oneOf(["autoWidth", "fullWidth"]),
  image: PropTypes.oneOf(["storyImage"]),
  inputIcon: PropTypes.string,
};

export default LabelledInput;
