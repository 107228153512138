import { requestHandler } from "../utils/RequestHandler";

class Angel {
  static donateToCampaign = (
    charityId,
    donationAmount,
    donationForPlatform,
    totalPayment,
    donateAnonymously,
    message
  ) => {
    let data = {
      charityId: charityId,
      donationAmount: donationAmount,
      donationForPlatform: donationForPlatform,
      totalPayment: totalPayment,
      donateAnonymously: donateAnonymously,
      message: message,
    };
    return requestHandler("donateToCharity", data).then((result) => {
      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error("Request status is not 200");
      }
    });
  };

  static fetchDonations = () => {
    return requestHandler("getMyDonations", {}).then((result) => {
      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error("Request status is not 200.");
      }
    });
  };

  static fetchRewards = () => {
    return requestHandler("getMyRewards", {}).then((result) => {
      if (result.status === 200 || result.statusCode === 200) {
        return result.data;
      } else {
        throw new Error("Request status is not 200.");
      }
    });
  };

  static refundDonation = (donationId) => {
    return requestHandler("refundDonation", { donationId }).then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error("Request status is not 200.");
      }
    });
  };
}

export default Angel;
