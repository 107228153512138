import React, { useState, useEffect, useRef } from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TribeLogo from "../../UI/TribeLogo/TribeLogo";
import enterpriseLine from "../../../../src/assets/images/enterpriseLine.svg";
import causeLine from "../../../../src/assets/images/causeLine.svg";
import angelsAreWatching from "../../../../src/assets/images/angelsarewatching.png";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import superhero from "../../../assets/images/superhero.png";
import angel from "../../../assets/images/angel.png";

import {
  faColumns,
  faGlobeAfrica,
  faCommentAlt,
  faCopy,
  faTachometerAlt,
  faTimesCircle,
  faCog,
  faThLarge,
  faProjectDiagram,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = (props) => {
  const [isSlider, setIsSlider] = useState(false);
  const sidebarRef = useRef();

  useEffect(() => {
    if (window.screen.width < 991) {
      setIsSlider(true);
    }
  }, []);

  const closeSlider = () => {
    sidebarRef.current.style.transform = "scaleX(0)";
  };

  const HeroNavigation = () => (
    <div className="navigation">
      <div className="navigation-item">
        <Link
          to="/hero-dashboard"
          className={
            window.location.pathname === "/hero-dashboard"
              ? "navigation-link active"
              : "navigation-link"
          }
        >
          <div className="item-icon">
            <FontAwesomeIcon icon={faThLarge} />
          </div>
          <div className="item-text">Dashboard</div>
        </Link>
      </div>
      <div className="navigation-item">
        <Link
          to="/hero-createcause"
          className={
            window.location.pathname ===
            ("/hero-createcause" || "/hero-createenterprise")
              ? "navigation-link active"
              : "navigation-link"
          }
        >
          <div className="item-icon">
            <FontAwesomeIcon icon={faCopy} />
          </div>
          <div className="item-text">Start a Fundraiser</div>
        </Link>
        <div className="fundraiser-tabs">
          <Link
            to="/hero-createcause"
            className={
              window.location.pathname === "/hero-createcause"
                ? "navigation-link fundraiser-item active"
                : "navigation-link fundraiser-item"
            }
          >
            <img
              className="cause-line"
              src={causeLine}
              alt="enterprise connecting line"
            />
            <div className="item-text">
              <span>Cause</span>
              <span className="fundraiserCause-icon">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </div>
          </Link>
          <Link
            to="/hero-createenterprise"
            className={
              window.location.pathname === "/hero-createenterprise"
                ? "navigation-link fundraiser-item active"
                : "navigation-link fundraiser-item"
            }
          >
            <img
              className="enterprise-line"
              src={enterpriseLine}
              alt="enterprise connecting line"
            />
            <div className="item-text">
              <span>Enterprise</span>
              <span className="fundraiserEnterprise-icon">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </div>
          </Link>
        </div>
      </div>

      <div className="navigation-item">
        <Link
          to="/hero-managecauses"
          className={
            window.location.pathname === "/hero-managecauses"
              ? "navigation-link active"
              : "navigation-link"
          }
        >
          <div className="item-icon">
            <FontAwesomeIcon icon={faProjectDiagram} />
          </div>
          <div className="item-text">Manage Causes</div>
        </Link>
      </div>
      <div className="navigation-item">
        <Link
          to="/hero-manageenterprises"
          className={
            window.location.pathname === "/hero-manageenterprises"
              ? "navigation-link active"
              : "navigation-link"
          }
        >
          <div className="item-icon">
            <FontAwesomeIcon icon={faColumns} />
          </div>
          <div className="item-text">Manage Enterprises</div>
        </Link>
      </div>
      <div className="navigation-item">
        <Link
          to="/tribe-world"
          className={
            window.location.pathname === "/tribe-world"
              ? "navigation-link active"
              : "navigation-link"
          }
        >
          <div className="item-icon">
            <FontAwesomeIcon icon={faGlobeAfrica} />
          </div>
          <div className="item-text">Tribe World</div>
        </Link>
      </div>
      <hr />
      <div className="navigation-item">
        <Link
          to="/inbox"
          className={
            window.location.pathname === "/inbox"
              ? "navigation-link active"
              : "navigation-link"
          }
        >
          <div className="item-icon">
            <FontAwesomeIcon icon={faCommentAlt} />
          </div>
          <div className="item-text">Chat</div>
        </Link>
      </div>
      <div className="navigation-item">
        <Link
          to="/settings"
          className={
            window.location.pathname === "/setting"
              ? "navigation-link active"
              : "navigation-link"
          }
        >
          <div className="item-icon">
            <FontAwesomeIcon icon={faCog} />
          </div>
          <div className="item-text">Settings</div>
        </Link>
      </div>

      <div className="sidebar-image">
        <img src={angelsAreWatching} alt="Angels are watching" />
      </div>
    </div>
  );

  const AngelNavigation = () => (
    <div className="navigation">
      <div className="navigation-item">
        <Link to="/angel-dashboard" className="navigation-link">
          <div className="item-icon">
            <FontAwesomeIcon icon={faTachometerAlt} />
          </div>
          <div className="item-text">Dashboard</div>
        </Link>
      </div>
      <div className="navigation-item">
        <Link to="/angel-donations" className="navigation-link">
          <div className="item-icon">
            <FontAwesomeIcon icon={faTachometerAlt} />
          </div>
          <div className="item-text">Donations</div>
        </Link>
      </div>
      <div className="navigation-item">
        <Link to="/angel-rewards" className="navigation-link">
          <div className="item-icon">
            <FontAwesomeIcon icon={faTachometerAlt} />
          </div>
          <div className="item-text">Reward Tracking</div>
        </Link>
      </div>
      <div className="navigation-item">
        <Link to="/tribe-world" className="navigation-link">
          <div className="item-icon">
            <FontAwesomeIcon icon={faTachometerAlt} />
          </div>
          <div className="item-text">Tribe World</div>
        </Link>
      </div>
      <div className="navigation-item">
        <Link to="/inbox" className="navigation-link">
          <div className="item-icon">
            <FontAwesomeIcon icon={faTachometerAlt} />
          </div>
          <div className="item-text">Inbox</div>
        </Link>
      </div>
      <div className="sidebar-image">
        <img src={angelsAreWatching} alt="Angels are watching" />
      </div>
    </div>
  );

  return (
    <div
      id="dashboardSidebar"
      ref={sidebarRef}
      className={`Sidebar ${props.dashboardMode} ${isSlider ? "slider" : ""}`}
      style={{ width: isSlider ? "80%" : `${props.sidebarWidth}px` }}
    >
      {/* navigation cloe btn */}
      {/* <div className="Sidebar-Mobile d-xl-none d-lg-none">
        <button className="timeCircle-btn" onClick={closeSlider}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      </div> */}

      <div className="Sidebar-Head">
        <div className="tribe-logo">
          <Link to="/">
            <TribeLogo type="black" />
          </Link>
        </div>
        <button button className="timeCircle-btn" onClick={closeSlider}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
        {props.dashboardMode === "hero" ? (
          <div className="dashboard-mode">
            <img className="mode-image " src={superhero} alt="superhero mode" />
            <p className="switch-dashboard">Hero Dashboard</p>
            <BootstrapSwitchButton
              checked={props.switchState}
              onlabel="."
              onstyle=""
              offlabel="."
              offstyle=""
              style="Topbar-SwitchBtn"
              onChange={props.onSwitchHandler}
              width={30}
              height={10}
              size="xs"
            />
          </div>
        ) : (
          <div className="dashboard-mode">
            <img className="mode-image " src={angel} alt="superhero mode" />
            <p className="switch-dashboard">Angel Dashboard</p>
            <BootstrapSwitchButton
              checked={props.switchState}
              onlabel="."
              onstyle=""
              offlabel="."
              offstyle=""
              style="Topbar-SwitchBtn"
              onChange={props.onSwitchHandler}
              width={30}
              height={10}
              size="xs"
            />
          </div>
        )}
      </div>
      <div className="Sidebar-Body">
        {props.dashboardMode === "hero" ? HeroNavigation() : AngelNavigation()}
      </div>
    </div>
  );
};

export default Sidebar;
