import React from "react";
import { Link } from "react-router-dom";
import CardImg from "../../../../../../assets/images/cardImg.png";

function HeroCauseItem({ campaign, categories, campaignType }) {
  const cardImg = campaign.content
    ? campaign.content.length !== 0
      ? campaign.content[0]
      : CardImg
    : CardImg;

  let category = categories.filter(
    (category) => category._id === campaign.categoryId
  );
  return (
    <div className="hero-cause-item">
      <div className="cause-image">
        <img src={cardImg} alt="cause" />
      </div>
      <div className="cause-details">
        <p className="hero-cause-category">
          {category.length !== 0 ? category["0"].name : "Category"}
        </p>
        <p className="hero-cause-title">{campaign.title}</p>
        <p className="hero-cause-goal">
          Goal <span>${campaign.goal}</span>
        </p>
        <Link
          to={
            campaignType === "cause"
              ? `/charity/details/${campaign._id}`
              : `/enterprise/details/${campaign._id}`
          }
          className="view-more-btn"
        >
          View More
        </Link>
      </div>
    </div>
  );
}

export default HeroCauseItem;
