import React from "react";
import "./ImpactValue.css";

function ImpactValue({ label, value, color }) {
  return (
    <div className="impact-value col-12 col-lg-6">
      <div className="impact-name">
        <div
          className="impact-color"
          style={{
            backgroundColor: "red",
          }}
        ></div>
        <p style={{ marginBottom: "0px" }}>{label}</p>
      </div>
      <div className="impact-points">{value}</div>
    </div>
  );
}

export default ImpactValue;
