import React, { useState, useEffect } from "react";
import "./Updates.css";
import { Editor } from "@tinymce/tinymce-react";
import { tinyCloudAPIKey } from "../../../../../utils/constants";
import PrimaryBtn from "../../../../UI/Buttons/PrimaryBtn/PrimaryBtn";
import checkmark from "../../../../../assets/images/checkmark.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { requestHandler } from "../../../../../utils/RequestHandler";
import ReactHtmlParser from "react-html-parser";
import { createDateAndTimeFromISO } from "../../../../../utils/helpers";

function Updates({ campaignId, creatorId, user }) {
  const [updates, setupdates] = useState([]);
  const [updateMessage, setupdateMessage] = useState("Add Updates here");

  const updateChangeHandler = (content) => {
    setupdateMessage(content);
  };

  const getUpdates = (campaignId) => {
    if (!campaignId) return;
    requestHandler("getCampaignUpdates", { campaignId }).then((response) => {
      if (response.status === 200) {
        setupdates(response.data);
      }
    });
  };

  const postUpdate = () => {
    const message = updateMessage;
    let data = {
      campaignId,
      message,
    };
    requestHandler("createCampaignUpdate", data).then((result) => {
      if (result.status === 200) {
        getUpdates(campaignId);
      }
    });
  };

  useEffect(() => {
    getUpdates(campaignId);
  }, [campaignId]);

  return (
    <div className="previewUpdates">
      {creatorId === user._id ? (
        <div className="updates-input">
          <p>Create an Update</p>
          <div className="updates-editor">
            <Editor
              apiKey={tinyCloudAPIKey}
              initialValue="Add an Update...."
              init={{
                height: 150,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar: `undo redo | formatselect | bold italic | 
                                        alignleft aligncenter alignright alignjustify | 
                                        bullist numlist outdent indent | removeformat | help `,
              }}
              onEditorChange={updateChangeHandler}
            />
          </div>
          <div className="update-btnWrap">
            <PrimaryBtn
              color="LinearBlue"
              type="button"
              size="md"
              onClick={postUpdate}
            >
              Submit
            </PrimaryBtn>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* update list */}
      <div className="updates-list">
        {updates.length !== 0
          ? updates.map((update) => {
              return (
                <div className="update-item" key={update._id}>
                  <div className="item-content">
                    <img
                      className="checkmark-icon"
                      src={checkmark}
                      alt="checkmark-icon"
                    />

                    <div className="update-text">
                      <p>{ReactHtmlParser(update.message)}</p>
                    </div>
                  </div>
                  <div className="item-info">
                    <div className="item-user">
                      <span>
                        <FontAwesomeIcon icon={faUserAlt} />
                      </span>
                      <span className="update_user">
                        {update.user.firstName + " " + update.user.lastName}
                      </span>
                    </div>
                    <div className="item-date">
                      <span>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                      <span className="update_time">
                        {createDateAndTimeFromISO(update.createdAt, true)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          : "No updates"}
      </div>
    </div>
  );
}

export default Updates;
