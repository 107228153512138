import React, { Component } from "react";
import "./Settings.css";
import { connect } from "react-redux";
import DashboardLayout from "../../../components/Layouts/DashboardLayout/DashboardLayout";
import SettingCard from "../../../components/UI/Cards/SettingCard/SettingCard";
import tribelogo from "../../../assets/images/tribelogo.png";

class Settings extends Component {
	state = {};

	render() {
		return (
			<DashboardLayout>
				<main className="Settings">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12 col-xl-6 mx-auto wrap-column">
								<div className="wrap-card">
									<SettingCard
										img={tribelogo}
										title="Personal Information"
										text="Sed faucibus turpis in eu mi bibendum neque egestas congue
                                        quisque egestas diam in arcu"
										linkText="Change Settings"
										to="/settings-personal"
									/>
								</div>
							</div>
							<div className="col-12 col-xl-6 mx-auto wrap-column">
								<div className="wrap-card">
									<SettingCard
										img={tribelogo}
										title="Change Password"
										text="Sed faucibus turpis in eu mi bibendum neque egestas congue
                                        quisque egestas diam in arcu"
										linkText="Change Password"
										to="/settings-passwordchange"
									/>
								</div>
							</div>
							<div className="col-12 col-xl-6 mx-auto wrap-column">
								<div className="wrap-card">
									<SettingCard
										img={tribelogo}
										title="Change Avatar"
										text="Sed faucibus turpis in eu mi bibendum neque egestas congue
                                        quisque egestas diam in arcu"
										linkText="Change Avatar"
										to="#"
									/>
								</div>
							</div>
							<div className="col-12 col-xl-6 mx-auto wrap-column">
								<div className="wrap-card">
									<SettingCard
										img={tribelogo}
										title="Payment Method"
										text="Sed faucibus turpis in eu mi bibendum neque egestas congue
                                        quisque egestas diam in arcu"
										linkText="Change Payment Method"
										to="#"
									/>
								</div>
							</div>
							<div className="col-12 col-xl-6 mx-auto wrap-column">
								<div className="wrap-card">
									<SettingCard
										img={tribelogo}
										title="KYC Support"
										text="Sed faucibus turpis in eu mi bibendum neque egestas congue
                                        quisque egestas diam in arcu"
										linkText="KYC Support"
										to="#"
									/>
								</div>
							</div>
							{this.props.dashboardMode === "angel" ? (
								<div className="col-12 col-xl-6 mx-auto wrap-column">
									<div className="wrap-card">
										<SettingCard
											img={tribelogo}
											title="Make Refund Request"
											text="Sed faucibus turpis in eu mi bibendum neque egestas congue
                                        quisque egestas diam in arcu"
											linkText="Make Refund Request"
											to="/angel-donations?refunds=true"
										/>
									</div>
								</div>
							) : (
								<div className="col-12 col-xl-6 mx-auto wrap-column"></div>
							)}
						</div>
					</div>
				</main>
			</DashboardLayout>
		);
	}
}

function mapStateToProps(state) {
	return {
		dashboardMode: state.dashboard.mode,
	};
}

export default connect(mapStateToProps)(Settings);
