import React, { useState, useRef, useEffect } from "react";
import "./DashboardLayout.css";
import { connect } from "react-redux";
import { setDashboardMode, receiveLogout } from "../../../store/actions";
import Sidebar from "../../Dashboard/Sidebar/Sidebar";
import Topbar from "../../Dashboard/Topbar/Topbar";

const DashboardLayout = (props) => {
  const [leftClassName, setLeftClassName] = useState("");
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const leftSideRef = useRef(null);
  const [switchState, setSwitchState] = useState(
    props.dashboardMode === "hero" ? true : false
  );

  const onSwitchHandler = (checked) => {
    props.setDashboardMode(checked ? "hero" : "angel");
    setSwitchState(checked);
  };

  useEffect(() => {
    if (window.screen.width < 991) {
      setLeftClassName("col-xl-2");
    } else if (window.screen.width < 1250) {
      setLeftClassName("col-xl-2 col-lg-2");
    } else {
      setLeftClassName("col-xl-2");
    }
  }, []);

  useEffect(() => {
    if (leftSideRef.current) {
      setSidebarWidth(leftSideRef.current.offsetWidth);
    }
  }, [leftSideRef, leftClassName]);

  const logoutUser = () => {
    localStorage.removeItem("auth");
    props.logoutUser();
  };

  let path = window.location.pathname;

  return (
    <div className="DashboardLayout">
      <div className="container-fluid">
        <div className="row">
          <div ref={leftSideRef} className={`LeftSide ${leftClassName}`}>
            <Sidebar
              sidebarWidth={sidebarWidth}
              dashboardMode={props.dashboardMode}
              logoutUser={logoutUser}
              switchState={switchState}
              setSwitchState={setSwitchState}
              onSwitchHandler={onSwitchHandler}
            />
          </div>
          <div
            className="RightSide col"
            style={
              path === "/tribe-world"
                ? { paddingLeft: "0px", paddingRight: "0px" }
                : {}
            }
          >
            <div className="wrap-topbar">
              <Topbar
                setHeroCauses={props.setHeroCauses} //for hero profile &  causes/enterprises
                setHeroEnterprises={props.setHeroEnterprises} //for hero profile &  causes/enterprises
                for={props.for} //for hero profile & causes/enterprises
                hero={props.hero} //for hero profile &  causes/enterprises
                user={props.user}
                dashboardMode={props.dashboardMode}
                setDashboardMode={props.setDashboardMode}
                switchState={switchState}
                setSwitchState={setSwitchState}
                onSwitchHandler={onSwitchHandler}
                logoutUser={logoutUser}
              />
            </div>
            <div className="wrap-body">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  )
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    dashboardMode: state.dashboard.mode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDashboardMode: (dashboardMode) => {
      localStorage.setItem("dashboardMode", JSON.stringify({ dashboardMode }));
      dispatch(setDashboardMode(dashboardMode));
    },
    // setDashboardMode: (mode) => dispatch(setDashboardMode(mode)),
    logoutUser: () => dispatch(receiveLogout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
