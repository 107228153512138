import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Title from "../../../../components/UI/Typography/Title/Title";
import PrimaryAuthInput from "../../../../components/UI/Inputs/PrimaryAuthInput/PrimaryAuthInput";
import TribePrimaryBtn from "../../../../components/UI/Buttons/TribePrimaryBtn/TribePrimaryBtn";
import sweetalert from "sweetalert";
import User from "../../../../Models/User";
import "./UpdatePassword.css";

function UpdatePassword(props) {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [redirect, setRedirect] = useState();
  let token = props.match.params.token;

  const updatePassword = () => {
    if (password !== confirmPassword) {
      sweetalert("Password", "Passwords do not match", "warning");
    } else if (password.length < 6) {
      return alert("Password should be at least 6 characters.");
    } else {
      User.updatePasswordWithToken(token, password).then(
        (res) => {
          sweetalert(
            "Password Updated",
            "Password Reset Successful, Log In now",
            "success"
          );
          setRedirect("/");
        },
        (err) => {
          sweetalert("Error", err.message, "error");
        }
      );
    }
  };

  useEffect(() => {
    if (props.isAuthenticated) {
      setRedirect("/");
    }
  }, [props.isAuthenticated]);

  return (
    <>
      {redirect ? (
        <Redirect to={redirect} />
      ) : (
        <main className="UpdatePassword">
          <section className="UpdatePassword-Section">
            <div className="container">
              <div className="row">
                <div className="col-10 col-sm-8 col-md-7 col-lg-6 col-xl-5 mx-auto">
                  <div className="UpdatePassword-Body">
                    <div className="header">
                      <div className="header-title">
                        <Title size="md" color="white">
                          Update Password
                        </Title>
                      </div>
                      <Link to="/">
                        <div className="header-times">&times;</div>
                      </Link>
                    </div>
                    <div className="form">
                      <div className="wrap-input">
                        <label htmlFor="password">Password</label>
                        <PrimaryAuthInput
                          id="password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          borderStyle="rounded"
                          type="password"
                          placeholder="Password"
                        />
                      </div>
                      <div className="wrap-input">
                        <label htmlFor="password">Confirm Password</label>
                        <PrimaryAuthInput
                          id="confirmPassword"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          borderStyle="rounded"
                          type="password"
                          placeholder="confirm Password"
                        />
                      </div>
                      <div className="mt-5">
                        <TribePrimaryBtn type="hero" onClick={updatePassword}>
                          Update Password
                        </TribePrimaryBtn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    user: state.auth.user,
    language: state.language.language,
    dashboardMode: state.dashboard.mode,
  };
}

export default connect(mapStateToProps)(UpdatePassword);
