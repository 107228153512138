import React from "react";
import { Route, Redirect } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader";

const ProtectedUserRoute = ({
	component: Component,
	pageFor,
	dashboardMode,
	isAuthenticated,
	isVerifying,
	...rest
}) => {
	const renderFunction = (props) => {
		if (isVerifying) {
			return <PageLoader />;
		} else {
			if (isAuthenticated) {
				if (dashboardMode === pageFor || pageFor === "both") {
					return <Component {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: `/${dashboardMode}-dashboard`,
								state: { from: props.location },
							}}
						/>
					);
				}
			} else {
				return (
					<Redirect
						to={{
							pathname: "/",
							state: { from: props.location },
						}}
					/>
				);
			}
		}
	};

	return <Route {...rest} render={renderFunction} />;
};

export default ProtectedUserRoute;
