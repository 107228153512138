import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { requestHandler } from "../../../../utils/RequestHandler";
import React, { useEffect, useState } from "react";
import "./Rewards.css";
import sweetalert from "sweetalert";
import RewardModals from "./RewardModals/RewardModals";
import CollapseCard from "../../../UI/Cards/CollapseCard/CollapseCard";
import RewardCard from "./RewardCard/RewardCard";
function Rewards(props) {
  const { isAuthenticated, enterpriseId, productStage } = props;
  const [rewards, setrewards] = useState([]);
  const [showRewardModal, setshowRewardModal] = useState(false);
  const [showRewardEditModal, setshowRewardEditModal] = useState(false);
  const [rewardId, setRewardId] = useState();

  const [rewardInputs, setrewardInputs] = useState({
    title: "",
    price: "",
    retailPrice: "",
    description: "",
    quantity: "",
    // perkItem: "",
    enterpriseId: enterpriseId,
    deliveryDays: "",
  });

  const getRewards = () => {
    requestHandler("getRewards", { enterpriseId }).then((res) => {
      setrewards(res.data);
    });
  };
  const addReward = () => {
    if (
      rewardInputs.title !== "" &&
      rewardInputs.price !== "" &&
      // rewardInputs.perkItem !== "" &&
      rewardInputs.retailPrice !== "" &&
      rewardInputs.description !== "" &&
      rewardInputs.quantity !== "" &&
      rewardInputs.deliveryDays !== ""
    ) {
      console.log(rewardInputs);
      requestHandler("createReward", rewardInputs).then((res) => {
        if (res.status === 200) {
          sweetalert("Success", "Reward Added", "success");
          setshowRewardModal(false);
          getRewards();
        }
      });
    } else {
      sweetalert("Error", "Kindly Fill the required Fields.", "error");
    }
  };

  const editRewardHandle = (id) => {
    setshowRewardEditModal(true);
    setRewardId(id);
  };

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setrewardInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    getRewards();
  }, [enterpriseId]);

  return (
    <>
      {/*  Reward Modal */}
      <RewardModals
        inputChangeHandler={inputChangeHandler}
        setrewardInputs={setrewardInputs}
        setshowRewardModal={setshowRewardModal}
        showRewardModal={showRewardModal}
        setshowRewardEditModal={setshowRewardEditModal}
        showRewardEditModal={showRewardEditModal}
        addReward={addReward}
        enterpriseId={enterpriseId}
        id={rewardId}
        rewardInputs={rewardInputs}
        getRewards={getRewards}
        productStage={productStage}
      />
      {/*  Reward Modal */}

      <div className="rewards rewards-desktop">
        <div className="rewards-top">
          <p className="rewards-heading">Rewards</p>
          {isAuthenticated ? (
            <button
              className="add-reward"
              onClick={() => setshowRewardModal(true)}
            >
              <span>
                <FontAwesomeIcon icon={faPlus} />
              </span>
              Add Reward
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="rewards-wrap">
          <table className="rewards-table">
            <tr className="table-headings">
              <th>
                Title
                <span className="caret-icon">
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </th>
              <th>Description</th>
              <th>
                Price
                <span className="caret-icon">
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </th>
              <th style={{ fontSize: "14px" }}>
                Retail Price
                <span className="caret-icon">
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </th>
              <th>Quantity</th>
              <th>Delivery (days)</th>
              <th></th>
            </tr>
            {rewards.map((reward) => {
              return (
                <tr className="table-data">
                  <td className="title-column">{reward.title}</td>
                  <td className="regular-columns">{reward.description}</td>
                  <td className="regular-columns">{reward.price}</td>
                  <td className="regular-columns">{reward.retailPrice}</td>
                  <td className="regular-columns">{reward.quantity}</td>
                  <td className="regular-columns">{reward.deliveryDays}</td>
                  <td>
                    {isAuthenticated ? (
                      <button
                        className="reward-edit-btn"
                        onClick={() => {
                          editRewardHandle(reward._id);
                        }}
                      >
                        Edit
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>

      <div className="rewards rewards-mobile">
        <div className="rewards-top">
          <p className="rewards-heading">Rewards</p>
          {isAuthenticated ? (
            <button
              className="add-reward"
              onClick={() => setshowRewardModal(true)}
            >
              <span>
                <FontAwesomeIcon icon={faPlus} />
              </span>
              Add Reward
            </button>
          ) : (
            ""
          )}
        </div>
        {/* rewards list */}

        {rewards && rewards.length !== 0
          ? rewards.map((reward) => {
              return (
                <div className="mobile-single-reward">
                  <CollapseCard for="rewards" title={reward.title}>
                    <RewardCard
                      key={reward._id}
                      reward={reward}
                      onClick={() => editRewardHandle(reward._id)}
                      isAuthenticated={isAuthenticated}
                    />
                  </CollapseCard>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
}

export default Rewards;
