import React, { Component } from "react";
import "./About.css";
import BgShape from "../../../components/Layouts/BgShape/BgShape";
import Navbar from "../../../components/Sections/Navbar/Navbar";
import Footer from "../../../components/Sections/Footer/Footer";
import SectionCard from "../../../components/UI/Cards/SectionCard/SectionCard";
import TrendingProjects from "../../../components/Sections/Home/TrendingProjects/TrendingProjects";
import Title from "../../../components/UI/Typography/Title/Title";
import { Fade } from "react-reveal";

// Images
import Hero1Img from "../../../assets/images/hero2.png";
import Hero2Img from "../../../assets/images/hero3.png";
import WhatIsTribeImg from "../../../assets/images/WhatIsTribe.jpg";
import TheStorySoFarImg from "../../../assets/images/TheStorySoFar.jpg";

class About extends Component {
  state = {};

  render() {
    return (
      <main className="About">
        <BgShape side="right" fromTop={100} />

        <Navbar mode="dark" />

        <section className="about-tribe">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-5 mx-auto">
                <div className="wrap-content">
                  <Fade top>
                    <Title size="lg" color="dark" isResponsive={true}>
                      About Tribe
                    </Title>

                    <div className="text-content">
                      <p>A light in the darkness.</p>
                      <p>A sword for slaying monsters.</p>
                      <p>A warm hearth and a wise word...</p>
                      <p>This is Tribe.</p>
                      <p>A tribe with no official language</p>
                      <p>A tribe with no ruler or lord</p>
                      <p>No lands, no limits</p>
                      <p>A tribe where everyone has a chance</p>
                      <p>Where heroes still walk among us</p>
                      <p>And strive to build a better world.</p>
                      <p>Where angels hear ideas</p>
                      <p> And help to make them a reality.</p>
                      <p>A tribe unlike any other.</p>
                      <div className="titles">
                        <Title size="sm" color="dark" isResponsive={true}>
                          Are you ready to join this tribe? Your Tribe?
                        </Title>
                        <Title size="sm" color="dark" isResponsive={true}>
                          Are you ready to change the world?
                        </Title>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="col-lg-6 d-none d-lg-block">
                <div className="illustraions-box">
                  <Fade right>
                    <div className="wrap-img heroImg1">
                      <img src={Hero1Img} alt="Img" />
                    </div>
                  </Fade>

                  <div className="wrap-img heroImg2">
                    <img src={Hero2Img} alt="Img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <SectionCard
          isReversed={true}
          img={WhatIsTribeImg}
          imgSize="md"
          title={"What is tribe?"}
          btnText="Become a Hero"
        >
          <p>
            Tribe is a place where you - yes, you - can make a difference. Where
            the heroes of today build the world of tomorrow. Where angels
            support the change they want to see, lending wisdom, wealth and
            encouragement.
          </p>
          <p>
            Tribe is a place where things are clear and honest - where the money
            you donate makes a real, visible difference.
          </p>
        </SectionCard>

        <SectionCard
          isReversed={false}
          img={TheStorySoFarImg}
          imgSize="md"
          title={"The story so far..."}
          btnText="Become a Hero"
        >
          <p>
            Tribe began the way all great journeys do - with words shared around
            a campfire, with a dream of a better tomorrow, and with the decision
            of a determined few to make a positive change in the world.
          </p>
          <p>
            In 2019, our founders - the first Heroes of Tribe, you might say -
            were blown together by the winds of fate. From their meeting, an
            idea was born - a place we now know as Tribe - and they spent the
            next few years striving to make their dream a reality.
          </p>
          <p>
            But the story of Tribe is only just beginning, and the next chapter
            - the most important one of all - is in your hands.
          </p>
          <p>So, tell us - what happens next?</p>
        </SectionCard>

        <div className="wrapper-projects">
          <TrendingProjects />
        </div>

        <div className="wrapper-footer">
          <Footer />
        </div>
      </main>
    );
  }
}

export default About;
