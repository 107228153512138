import React, { useEffect, useState, useRef } from "react";
import LabelledInput from "../../../Inputs/LabelledInput/LabelledInput";
import PrimaryBtn from "../../../Buttons/PrimaryBtn/PrimaryBtn";
import "./DonationStepTwo.css";
import paypal from "../../../../../assets/images/paypal.png";
import banks from "../../../../../assets/images/banks.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CheckBox from "../../../Inputs/CheckBox/CheckBox";
import { STRIPE_KEY } from "../../../../../utils/constants";
import StripeCheckout from "react-stripe-checkout";

function DonationStepTwo(props) {
  const [totalPayment, setTotalPayment] = useState();
  useEffect(() => {
    let donationPercentage = props.state.donationForPlatform.substring(
      0,
      props.state.donationForPlatform.length - 1
    );

    let donationToPlatformAmount = (
      props.state.donationAmount *
      (donationPercentage / 100)
    ).toFixed(2);

    setTotalPayment(
      (
        parseFloat(props.state.donationAmount) +
        parseFloat(donationToPlatformAmount)
      ).toString()
    );
  }, [props.state.donationAmount, props.state.donationForPlatform]);

  const login = () => {
    props.signInUser(props.state.email, props.state.password);
  };

  let stripeButton = (
    <StripeCheckout
      stripeKey={STRIPE_KEY}
      token={props.handleStripeToken}
      allowRememberMe={false}
      amount={totalPayment * 100}
      email={props.state.email}
      name="Credit or Debit card"
      label="Donate"
      ComponentClass="stripeCheckout"
    />
  );
  return (
    <div className="DonationStep">
      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="input"
          label="First Name"
          input={{
            disabled: props.isAuthenticated ? true : false,
            type: "text",
            placeholder: "name",
            name: "firstName",
            onChange: props.inputChangeHandler,
            value: props.state.firstName,
          }}
        ></LabelledInput>
      </div>

      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="input"
          label="Last Name"
          input={{
            disabled: props.isAuthenticated ? true : false,
            type: "text",
            placeholder: "Last Name",
            name: "lastName",
            onChange: props.inputChangeHandler,
            value: props.state.lastName,
          }}
        />
      </div>

      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="input"
          label="E-mail"
          input={{
            disabled: props.isAuthenticated ? true : false,
            type: "email",
            placeholder: "E-mail",
            name: "email",
            onChange: props.inputChangeHandler,
            value: props.state.email,
          }}
        />
      </div>
      <div className="email-check-wrap">
        {props.isAuthenticated ? (
          ""
        ) : props.checkingEmailExistenceInSystem ? (
          "Checking..."
        ) : props.emailGoodToGo ? (
          <>
            <p className="email-exist-message">
              <FontAwesomeIcon icon={faCheckCircle} className="login-icon" />{" "}
              Email exists. You can <span>login now</span> or after making the
              donation{" "}
            </p>

            <LabelledInput
              size="fullWidth"
              dashboardMode={props.dashboardMode}
              inputType="input"
              label="Password"
              input={{
                disabled: props.isAuthenticated ? true : false,
                type: "password",
                placeholder: "password",
                name: "password",

                onChange: props.inputChangeHandler,
                value: props.state.password,
              }}
            />
            <PrimaryBtn
              type="button"
              size="md"
              color="LinearBlue"
              onClick={login}
            >
              Log In
            </PrimaryBtn>
          </>
        ) : !props.responseMessage ? (
          <div className="input-wrapper mt-3">
            <CheckBox
              size="sm"
              label="Register Me"
              color="dark"
              input={{
                defaultChecked: props.state.registerMe,
                onChange: props.setRegisterMe,
                value: props.state.registerMe,
              }}
            />

            {/* <div className="input-wrapper">
              <LabelledInput
                size="fullWidth"
                dashboardMode={props.dashboardMode}
                inputType="input"
                label="Password"
                input={{
                  disabled: props.isAuthenticated ? true : false,
                  type: "password",
                  placeholder: "password",
                  name: "password",
                  onChange: props.inputChangeHandler,
                  value: props.state.password,
                }}
              />
            </div> */}
          </div>
        ) : (
          props.responseMessage
        )}
      </div>
      {/* payment methods */}
      <div className="input-wrapper">
        <div className="payment_methods">
          <p className="payment-label">Select Payment Method</p>
          <div className="payment_btns">
            <div className="radiobtn-wrapper">
              <input
                className="payment-type"
                type="radio"
                name="paymentMethod"
                id="credit_debitCard"
                value="credit_debitCard"
                onChange={props.inputChangeHandler}
                checked={
                  props.state.paymentMethod === "credit_debitCard"
                    ? true
                    : false
                }
              />
              <label htmlFor="credit_debitCard" className="credit-card-label">
                <p>Credit or Debit Card</p>
                <p>
                  <img src={banks} alt="banks" />
                </p>
              </label>
            </div>

            <div className="radiobtn-wrapper">
              <input
                className="payment-type"
                type="radio"
                name="paymentMethod"
                id="paypal"
                value="paypal"
                onChange={props.inputChangeHandler}
                checked={props.state.paymentMethod === "paypal" ? true : false}
              />
              <label htmlFor="paypal">
                <img src={paypal} alt="paypal" />
              </label>
            </div>

            <div className="radiobtn-wrapper">
              <input
                className="payment-type"
                type="radio"
                name="paymentMethod"
                id="bankTransfer"
                value="bankTransfer"
                onChange={props.inputChangeHandler}
                checked={
                  props.state.paymentMethod === "bankTransfer" ? true : false
                }
              />
              <label htmlFor="bankTransfer"> Bank Transfer</label>
            </div>
          </div>
        </div>
      </div>

      <div className="btn-wrapper">
        <span>
          <PrimaryBtn
            type="button"
            size="md"
            color="tranparentLinearBlue"
            onClick={() => props.changeStep("prev")}
          >
            Previous
          </PrimaryBtn>
        </span>
        <span className="donate_btn">
          {!props.isAuthenticated ? (
            props.state.email.length !== 0 ? (
              props.emailGoodToGo ? (
                props.state.email.length !== 0 &&
                props.state.firstName.length !== 0 &&
                props.state.lastName.length !== 0 &&
                props.state.password.length !== 0 ? (
                  props.state.paymentMethod === "credit_debitCard" ? (
                    stripeButton
                  ) : (
                    <PrimaryBtn
                      type="button"
                      size="md"
                      color="LinearBlue"
                      onClick={props.validateInputs}
                    >
                      Donate
                    </PrimaryBtn>
                  )
                ) : (
                  <PrimaryBtn
                    type="button"
                    size="md"
                    color="LinearBlue"
                    onClick={props.validateInputs}
                  >
                    Donate
                  </PrimaryBtn>
                )
              ) : (
                <PrimaryBtn
                  type="button"
                  size="md"
                  color="LinearBlue"
                  onClick={props.validateInputs}
                >
                  Donate
                </PrimaryBtn>
              )
            ) : (
              <PrimaryBtn
                type="button"
                size="md"
                color="LinearBlue"
                onClick={props.validateInputs}
              >
                Donate
              </PrimaryBtn>
            )
          ) : props.state.paymentMethod === "credit_debitCard" ? (
            stripeButton
          ) : (
            <PrimaryBtn
              type="button"
              size="md"
              color="LinearBlue"
              onClick={props.validateInputs}
            >
              Donate
            </PrimaryBtn>
          )}
        </span>
      </div>
    </div>
  );
}

export default DonationStepTwo;
