import { requestHandler } from "../utils/RequestHandler";

export default {
	createContact: async (data) => {
		return await requestHandler("postContact", data);
	},
	getRequests: async () => {
		return requestHandler("getContactUs").then((res) => {
			console.log({ res });
			if (res.status === 200) {
				return res.data;
			} else return alert("Something went wrong");
		});
	},
};
