import React, { useState, useEffect } from "react";
import "./SelectedChatUser.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import User from "../../../../Models/User";
import UserAvatar from "../../../UserAvatar";

const SelectedChatUser = (props) => {
  const [opponent, setOpponent] = useState(null);

  useEffect(async () => {
    const { userA, userB } = props.conversation;
    const toGetUser = userA === props.user._id ? userB : userA;
    const user = await User.GetUserCard(toGetUser);
    setOpponent(user);
  }, [props.conversation]);

  console.log(opponent);
  return (
    <div className="SelectedChatUser">
      <div className="wrap-user">
        <div className="wrap-img">
          <UserAvatar avatar={opponent ? opponent.avatar : ""} />
        </div>
        <div className="name">{opponent ? opponent.firstName : ""}</div>
        <div className="last-message">20 minutes ago</div>
      </div>
      <div className="wrap-button">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            <FontAwesomeIcon icon={faEllipsisV} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <Link to={opponent ? `/hero-profile/${opponent._id} ` : ""}>
                View Profile
              </Link>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-2">Block User</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(SelectedChatUser);
