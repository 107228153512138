import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import Hero from "../../../../../Models/Hero";
import { requestHandler } from "../../../../../utils/RequestHandler";
import "./UpdateEnterprise.css";
import LabelledInput from "../../../../UI/Inputs/LabelledInput/LabelledInput";
import PropTypes from "prop-types";
import PrimaryBtn from "../../../../UI/Buttons/PrimaryBtn/PrimaryBtn";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LocationAutoComplete from "../../../../UI/Inputs/LocationAutoComplete/LocationAutoComplete";
import IconRadioBtn from "../../../../UI/Buttons/IconRadioBtn/IconRadioBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import placeholder from "../../../../../assets/images/placeholder-image.png";
import { useDropzone } from "react-dropzone";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import {
  faPlus,
  faTrash,
  faLightbulb,
  faBoxOpen,
  faCogs,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";

function UpdateEnterprise(props) {
  const [copied, setCopied] = useState(false);
  const [categories, setcategories] = useState([]);
  const [stage, setStage] = useState();
  const [show, setShow] = useState(false);
  let inputFileref = useRef(); // for enterprise image

  // drop zone for product stage
  const [file, setFile] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      );
    },
  });

  const images = file.map((image) => (
    <div key={image.name}>
      <div className="preview-image">
        <img src={image.preview} alt={image.name} />
      </div>
    </div>
  ));

  // drop zone end

  const [inputs, setInputs] = useState({
    id: "",
    title: "",
    goal: "",
    description: "",
    location: "",
    lat: "",
    lng: "",
    category: "",
    faqs: [],
    customUrl: "",
    story: "",
    duration: "",
    productStage: "",
    file: "",
    content: [],
    impact: {},
  });

  const [content, setcontent] = useState([]);

  const onFileInputChange = (event) => {
    let file = event.target.files["0"];
    setcontent([file]);
    setInputs((prevState) => ({
      ...prevState,
      file: null,
    }));
  };

  function addImage() {
    inputFileref.current.click();
  }

  function deleteImage() {
    setcontent([]);
  }

  useEffect(
    () =>
      Hero.fetchFundraiserCategories().then((categories) =>
        setcategories(categories)
      ),
    []
  );

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setInputs({
      id: props.selectedEnterprise._id,
      title: props.selectedEnterprise.title,
      goal: props.selectedEnterprise.goal,
      description: props.selectedEnterprise.shortDescription,
      location: props.selectedEnterprise.locationName,
      lat: props.selectedEnterprise.location.coordinates["0"],
      lng: props.selectedEnterprise.location.coordinates["1"],
      category: props.selectedEnterprise.categoryId,
      faqs: props.selectedEnterprise.faqs,
      customUrl: props.selectedEnterprise.customUrl,
      story: props.selectedEnterprise.story,
      duration: props.selectedEnterprise.duration,
      productStage: props.selectedEnterprise.productStage,
      impact: props.selectedEnterprise.impact,
      file: props.selectedEnterprise.content["0"],
    });

    setcontent(props.selectedEnterprise.content);
  }, [window.location.href]);

  const locationInputChangeHander = (location, latLng) => {
    inputChangeHandler({
      target: {
        name: "location",
        value: location,
      },
    });
    if (latLng) {
      const { lat, lng } = latLng;
      inputChangeHandler({
        target: {
          name: "lat",
          value: lat,
        },
      });
      inputChangeHandler({
        target: {
          name: "lng",
          value: lng,
        },
      });
    }
  };

  if (!inputs.category && categories.length) {
    inputChangeHandler({
      target: { value: categories[0]._id, name: "category" },
    });
  }

  const updateEnterprise = () => {
    const {
      id,
      title,
      goal,
      description,
      location,
      lat,
      lng,
      category,
      faqs,
      customUrl,
      story,
      duration,
      productStage,
      impact,
      file,
    } = inputs;

    let data = {
      title,
      goal,
      shortDescription: description,
      locationName: location,
      location: [lat, lng],
      customUrl,
      categoryId: category,
      faqs,
      story,
      duration,
      productStage,
      impact,
      content,
    };

    requestHandler("editEnterprise", data, inputs.id).then((result) => {
      if (result.status === 200) {
        window.location.reload(false);
      }
    });
  };

  console.log(content);

  // link to be shared
  let shareUrl = window.location.href;
  let ProductStageModal = (
    <div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div className="productStageModal">
            <div className="modal-top">
              <p className="modal-title">Product Stage</p>
              <button
                onClick={() => {
                  setShow(false);
                }}
                className="close-btn"
              >
                X
              </button>
            </div>
            <div className="modal-main-body">
              <div className="input-wrapper">
                <LabelledInput
                  size="fullWidth"
                  inputType="input"
                  readonly
                  input={{
                    type: "text",
                    placeholder: "Product Stage",
                    name: "label",
                    value: stage,
                  }}
                />
              </div>
              <div className="input-wrapper">
                <LabelledInput
                  size="fullWidth"
                  inputType="textarea"
                  label="Description"
                  input={{
                    type: "text",
                    placeholder: "Description",
                    name: "description",
                    onChange: props.productInputChangeHandler,
                  }}
                />
              </div>
              {/* Image upload */}
              <div className="story-cover">
                <p className="story-titles">Add Files</p>

                <div {...getRootProps()} class="enterprise-image-upload-wrap">
                  <input {...getInputProps()} />
                  {images && images.length !== 0 ? (
                    images
                  ) : (
                    <div>
                      <div className="image-placeholder">
                        <img src={placeholder} alt="placeholder" />
                      </div>
                      <div className="image-text">
                        <p className="drag-text">
                          Drop your files here, or browse
                        </p>
                        <p className="image-type">
                          Support: JPG, PNG, ZIP, PDF, DOC, TXT{" "}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <p className="fileSize-note">
                <span>Note :</span> File Size shouldn't be greater than 100mb
              </p>
              {/* Image upload */}
            </div>
            <div className="save-changes-btn">
              <PrimaryBtn
                type="button"
                size="md"
                color="LinearBlue"
                onClick={() => setShow(false)}
              >
                Done
              </PrimaryBtn>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );

  return (
    <>
      {/* enterprise update modal */}
      <Modal
        size="lg"
        show={props.showcauseUpdateModal}
        onHide={() => props.setshowCauseUpdateModal(false)}
        aria-labelledby="updateCauseModal"
      >
        <Modal.Body>
          <div className="updateCauseModal">
            <div className="modal-top">
              <p className="modal-title">Edit Enterpise</p>
              <button
                onClick={() => {
                  props.setshowCauseUpdateModal(false);
                }}
                className="close-btn"
              >
                X
              </button>
            </div>

            <div className="row modal-main-body">
              <div className="col-12 col-lg-5 updateCause-left">
                <p className="labelledInput">Photo</p>
                <div className="modal-image">
                  {inputs.file ? (
                    <img src={inputs.file} alt="campaignImg" />
                  ) : content.length !== 0 ? (
                    "Image Added"
                  ) : (
                    "No Images"
                  )}
                </div>
                <div className="add-delete-image">
                  <div className="image-input-wrapper">
                    <LabelledInput
                      className="input-image"
                      size="fullWidth"
                      dashboardMode={props.dashboardMode}
                      inputType="input"
                      label=""
                      input={{
                        type: "file",
                        name: "file",
                        accept: "image/*",
                        multiple: true,
                        image: "storyImage",
                        onChange: onFileInputChange,
                        ref: inputFileref,
                      }}
                    />
                  </div>
                  <p className="add-image" onClick={addImage}>
                    <FontAwesomeIcon icon={faPlus} /> Add a new Photo
                  </p>
                  <p className="delete-image" onClick={deleteImage}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-7 updateCause-right">
                <div className="update-input-wrapper">
                  <LocationAutoComplete
                    setLocatoinAutocomplete={locationInputChangeHander}
                    location={inputs.location || ""}
                  />
                </div>
                <div className="update-input-wrapper">
                  <LabelledInput
                    size="fullWidth"
                    dashboardMode={props.dashboardMode}
                    inputType="input"
                    label="Title"
                    input={{
                      type: "text",
                      placeholder: "Title",
                      name: "title",
                      value: inputs.title,
                      onChange: inputChangeHandler,
                    }}
                  />
                </div>
                {/* Production stage */}
                <div className="input-wrapper product-stage ">
                  {ProductStageModal}
                  <p style={{ marginBottom: "0px" }}>
                    Select the Stage of your company/product is in
                  </p>
                  <div className="radiobtns-inputs row">
                    <div className="productStage-radio-input col-6  col-lg-3 ">
                      <IconRadioBtn
                        name="label"
                        id="concept"
                        label="Concept"
                        icon={faLightbulb}
                        value="Concept"
                        onClick={() => {
                          setShow(true);
                          setStage("Concept");
                        }}
                      />
                    </div>
                    <div className="productStage-radio-input col-6  col-lg-3 ">
                      <IconRadioBtn
                        input={{
                          onChange: props.productInputChangeHandler,
                        }}
                        name="label"
                        id="prototype"
                        label="Prototype"
                        icon={faCogs}
                        value="Prototype"
                        onClick={() => {
                          setShow(true);
                          setStage("Prototype");
                        }}
                      />
                    </div>
                    <div className="productStage-radio-input col-6  col-lg-3 ">
                      <IconRadioBtn
                        name="label"
                        id="production"
                        label="Production"
                        icon={faBoxOpen}
                        value="Production"
                        onClick={() => {
                          setShow(true);
                          setStage("Production");
                        }}
                      />
                    </div>
                    <div className="productStage-radio-input col-6  col-lg-3 ">
                      <IconRadioBtn
                        name="label"
                        id="shipping"
                        label="Shipping"
                        icon={faTruck}
                        value="Shipping"
                        onClick={() => {
                          setShow(true);
                          setStage("Shipping");
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="input-wrapper product-stage ">
                  <p style={{ marginBottom: "0px" }}>
                    Select the Stage of your company/product is in
                  </p>
                  <div className="radiobtns-inputs row">
                    <div className="productStage-radio-input col-6  col-lg-3 ">
                      <IconRadioBtn
                        input={{
                          onChange: inputChangeHandler,
                        }}
                        name="productStage"
                        id="concept"
                        label="Concept"
                        icon={faLightbulb}
                        value="Concept"
                      />
                    </div>
                    <div className="productStage-radio-input col-6  col-lg-3 ">
                      <IconRadioBtn
                        input={{
                          onChange: inputChangeHandler,
                        }}
                        name="productStage"
                        id="prototype"
                        label="Prototype"
                        icon={faCogs}
                        value="Prototype"
                      />
                    </div>
                    <div className="productStage-radio-input col-6  col-lg-3 ">
                      <IconRadioBtn
                        input={{
                          onChange: inputChangeHandler,
                        }}
                        name="productStage"
                        id="production"
                        label="Production"
                        icon={faBoxOpen}
                        value="Production"
                      />
                    </div>
                    <div className="productStage-radio-input col-6  col-lg-3 ">
                      <IconRadioBtn
                        input={{
                          onChange: inputChangeHandler,
                        }}
                        name="productStage"
                        id="shipping"
                        label="Shipping"
                        icon={faTruck}
                        value="Shipping"
                      />
                    </div>
                  </div>
                </div> */}

                {/* Production Stage */}

                {/* file upload */}
                {/* <div className="story-cover">
                  <p className="story-titles">Add Files</p>
                  <div
                    onClick={fileBrowse}
                    class="enterprise-image-upload-wrap"
                  >
                    <div className="image-input-field">
                      <LabelledInput
                        className="input-image"
                        size="fullWidth"
                        dashboardMode={props.dashboardMode}
                        inputType="input"
                        label=""
                        input={{
                          type: "file",
                          name: "file",
                          multiple: true,
                          image: "storyImage",
                          onChange: props.onFileInputChange,
                          ref: imageInputRef,
                        }}
                      />
                    </div>
                    <div className="image-placeholder">
                      <img src={placeholder} alt="image-placeholder" />
                    </div>
                    <div className="image-text">
                      <p className="drag-text">
                        Drop your files here, or browse
                      </p>
                      <p className="image-type">
                        Support: JPG, PNG, ZIP, PDF, DOC, TXT{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <p className="fileSize-note">
                  <span>Note :</span> File Size shouldn't be greater than 100mb
                </p> */}
                {/* file upload end */}

                <div className="update-input-wrapper">
                  <LabelledInput
                    size="fullWidth"
                    dashboardMode={props.dashboardMode}
                    inputType="textarea"
                    label="Short Description"
                    input={{
                      type: "text",
                      placeholder: "Description",
                      name: "description",
                      value: inputs.description,
                      onChange: inputChangeHandler,
                      maxLength: "160",
                    }}
                  />
                </div>
                <div className="update-input-wrapper">
                  <LabelledInput
                    size="fullWidth"
                    dashboardMode={props.dashboardMode}
                    inputType="select"
                    label="Enterprise Category"
                    input={{
                      placeholder: "Select a category",
                      name: "category",
                      value: inputs.category,
                      onChange: inputChangeHandler,
                    }}
                  >
                    {categories.map((cat) => (
                      <option value={cat._id} key={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                  </LabelledInput>
                </div>
              </div>
            </div>
            <div className="save-changes-btn">
              <PrimaryBtn
                type="button"
                size="md"
                color="LinearBlue"
                onClick={updateEnterprise}
                // onClick={() => console.log(content)}
              >
                Save Changes
              </PrimaryBtn>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Enterprise update modal end */}

      {/* Goal update modal */}
      <Modal
        size="sm"
        show={props.showGoalUpdateModal}
        onHide={() => props.setshowGoalUpdateModal(false)}
        aria-labelledby="updateCauseModal"
      >
        <Modal.Body>
          <div className="updateCauseModal">
            <div className="modal-top">
              <p className="modal-title">Edit Goal</p>
              <button
                onClick={() => props.setshowGoalUpdateModal(false)}
                className="close-btn"
              >
                X
              </button>
            </div>

            <div
              className="row modal-main-body"
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              <div className="update-input-wrapper">
                <LabelledInput
                  size="fullWidth"
                  dashboardMode={props.dashboardMode}
                  inputType="input"
                  label=""
                  inputIcon="inputIcon"
                  input={{
                    type: "number",
                    placeholder: "1000",
                    name: "goal",
                    value: inputs.goal,
                    onChange: inputChangeHandler,
                  }}
                ></LabelledInput>
              </div>
            </div>
            <div style={{ marginBottom: "1rem" }} className="save-changes-btn">
              <PrimaryBtn
                type="button"
                size="md"
                color="LinearBlue"
                onClick={updateEnterprise}
              >
                Save Changes
              </PrimaryBtn>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Goal Update Modal end */}

      {/* Duration update modal */}
      <Modal
        size="sm"
        show={props.showDurationUpdateModal}
        onHide={() => props.setshowDurationUpdateModal(false)}
        aria-labelledby="updateCauseModal"
      >
        <Modal.Body>
          <div className="updateCauseModal">
            <div className="modal-top">
              <p className="modal-title">Edit Duration</p>
              <button
                onClick={() => props.setshowDurationUpdateModal(false)}
                className="close-btn"
              >
                X
              </button>
            </div>

            <div
              className="row modal-main-body"
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              <div className="update-input-wrapper">
                <LabelledInput
                  size="fullWidth"
                  dashboardMode={props.dashboardMode}
                  inputType="select"
                  label=""
                  input={{
                    type: "number",
                    placeholder: "In Days",
                    name: "duration",
                    value: inputs.duration,
                    onChange: inputChangeHandler,
                  }}
                >
                  <option value="30">30</option>
                  <option value="60">60</option>
                  <option value="90">90</option>
                </LabelledInput>
              </div>
            </div>
            <div style={{ marginBottom: "1rem" }} className="save-changes-btn">
              <PrimaryBtn
                type="button"
                size="md"
                color="LinearBlue"
                onClick={updateEnterprise}
              >
                Save Changes
              </PrimaryBtn>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Goal Update Modal end */}

      {/* Share  modal */}
      <Modal
        size="md"
        show={props.showShareModal}
        onHide={() => props.setshowShareModal(false)}
        aria-labelledby="updateCauseModal"
      >
        <Modal.Body>
          <div className="updateCauseModal">
            <div className="modal-top">
              <p className="modal-title">
                Share this with your Social <br /> Community
              </p>
              <button
                onClick={() => props.setshowShareModal(false)}
                className="close-btn"
              >
                X
              </button>
            </div>

            <div
              className="row modal-main-body"
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              <div className="social-share-buttons">
                <div className="share-btn-wrapper">
                  <FacebookShareButton url={shareUrl}>
                    <FacebookIcon round={true} size={40} />
                  </FacebookShareButton>
                </div>
                <div className="share-btn-wrapper">
                  <TwitterShareButton url={shareUrl}>
                    <TwitterIcon round={true} size={40} />
                  </TwitterShareButton>
                </div>
                <div className="share-btn-wrapper">
                  <WhatsappShareButton url={shareUrl}>
                    <WhatsappIcon round={true} size={40} />
                  </WhatsappShareButton>
                </div>
                <div className="share-btn-wrapper">
                  <LinkedinShareButton url={shareUrl}>
                    <LinkedinIcon round={true} size={40} />
                  </LinkedinShareButton>
                </div>
                <div className="share-btn-wrapper">
                  <EmailShareButton url={shareUrl}>
                    <EmailIcon round={true} size={40} />
                  </EmailShareButton>
                </div>
              </div>

              <div className="link-copy-wrapper">
                <p className="link-copy-text">or copy link</p>
                <div className="link-box">
                  <p className="link-to-copy">{shareUrl}</p>
                  <CopyToClipboard
                    text={shareUrl}
                    onCopy={() => setCopied(true)}
                  >
                    <p className="copy-btn">Copy</p>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Share Modal end */}
    </>
  );
}
UpdateEnterprise.propTypes = {
  showcauseUpdateModal: PropTypes.bool,
  setshowCauseUpdateModal: PropTypes.func,
  showGoalUpdateModal: PropTypes.bool,
  setshowGoalUpdateModal: PropTypes.func,
  showShareModal: PropTypes.bool,
  setshowShareModal: PropTypes.func,
  showDurationUpdateModal: PropTypes.bool,
  setshowDurationUpdateModal: PropTypes.func,
  selectedEnterprise: PropTypes.object,
};

export default UpdateEnterprise;
