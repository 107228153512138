import React from "react";
import Avatar from "avataaars";
import User from "../../Models/User";

const UserAvatar = ({ style, avatar, defaultAvatar, loading }) => {
	const user = User.GetLocalUser();
	let _style =
		style && style.width && style.height
			? style
			: { width: "100%", height: "100%" };
	let _avatar = user ? user.avatar : null;
	
	if (!_avatar || defaultAvatar) {
		_avatar = {
			avatarStyle: "Circle",
			topType: "LongHairMiaWallace",
			accessoriesType: "Prescription02",
			hairColor: "BrownDark",
			facialHairType: "Blank",
			clotheType: "Hoodie",
			clotheColor: "PastelBlue",
			eyeType: "Happy",
			eyebrowType: "Default",
			mouthType: "Smile",
			skinColor: "Light",
		};
	}

	if (avatar || loading) {
		_avatar = avatar;
	}
	
	return loading ? null : <Avatar {..._avatar} style={_style} />;
};

export default UserAvatar;
