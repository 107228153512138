import React, { Component } from "react";
import { connect } from "react-redux";
import DashboardLayout from "../../../components/Layouts/DashboardLayout/DashboardLayout";
import ChatContainer from "../../../components/Sections/ChatContainer/ChatContainer";

class Inbox extends Component {
  state = {};

  render() {
    return (
      <DashboardLayout>
        <main className="Inbox">
          <ChatContainer dashboardMode={this.props.dashboardMode} />
        </main>
      </DashboardLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    dashboardMode: state.dashboard.mode,
  };
}

export default connect(mapStateToProps)(Inbox);
