import React from "react";
import "./SettingCard.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const SettingCard = (props) => {
	return (
		<div className={`SettingCard ${props.dashboardMode}`}>
			<div className="wrap-img">
				<img src={props.img} alt="box-img" />
			</div>
			<div className="content">
				<h4 className="title">{props.title}</h4>
				<p className="text">{props.text}</p>
				<div className="wrap-link">
					<Link to={props.to ? props.to : "#"} className="link">
						{props.linkText}
					</Link>
				</div>
			</div>
		</div>
	);
};

SettingCard.propTypes = {
	img: PropTypes.any.isRequired,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	linkText: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
	return {
		dashboardMode: state.dashboard.mode,
	};
}

export default connect(mapStateToProps)(SettingCard);
