import React, { useState } from "react";
import LabelledInput from "../../Inputs/LabelledInput/LabelledInput";
import PrimaryBtn from "../../Buttons/PrimaryBtn/PrimaryBtn";
import "./HeroFlowStep.css";
import PropTypes from "prop-types";

function HeroFlowStep(props) {
  const [logInScreen, setLogInScreeen] = useState(false);
  return (
    <div className="heroFlowStep">
      {logInScreen ? (
        <div>
          <div className="input-wrapper">
            <LabelledInput
              size="fullWidth"
              inputType="email"
              label="Email"
              input={{
                placeholder: "Email",
                name: "email",
                onChange: props.inputChangeHandler,
              }}
            />
          </div>
          <div className="input-wrapper">
            <LabelledInput
              size="fullWidth"
              inputType="password"
              label="Password"
              input={{
                type: "password",
                placeholder: "Password",
                name: "password",
                onChange: props.inputChangeHandler,
              }}
            />
          </div>
          {props.for === "creation" ? (
            <div className="btn-wrapper">
              <span>
                <PrimaryBtn
                  type="button"
                  size="md"
                  color="tranparentLinearBlue"
                  onClick={() => props.changeStep("prev")}
                >
                  Previous
                </PrimaryBtn>
              </span>
              <span>
                <PrimaryBtn
                  type="button"
                  size="md"
                  color="LinearBlue"
                  onClick={
                    props.isAuthenticated
                      ? props.onSubmitHandler
                      : props.createWithSignIn
                  }
                >
                  Create
                </PrimaryBtn>
              </span>
            </div>
          ) : props.for === "edit" ? (
            <div className="btn-wrapper">
              <PrimaryBtn
                type="button"
                size="md"
                color="yellow"
                onClick={props.onEditHandler}
              >
                Edit
              </PrimaryBtn>
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <div className="input-wrapper">
            <LabelledInput
              size="fullWidth"
              inputType="text"
              label="First Name"
              input={{
                placeholder: "First Name",
                name: "firstName",
                onChange: props.inputChangeHandler,
              }}
            />
          </div>
          <div className="input-wrapper">
            <LabelledInput
              size="fullWidth"
              inputType="email"
              label="Last Name"
              input={{
                placeholder: "Last Name",
                name: "lastName",
                onChange: props.inputChangeHandler,
              }}
            />
          </div>
          <div className="input-wrapper">
            <LabelledInput
              size="fullWidth"
              inputType="email"
              label="Email"
              input={{
                placeholder: "Email",
                name: "email",
                onChange: props.inputChangeHandler,
              }}
            />
          </div>
          {props.for === "creation" ? (
            <div className="btn-wrapper">
              <span>
                <PrimaryBtn
                  type="button"
                  size="md"
                  color="tranparentLinearBlue"
                  onClick={() => props.changeStep("prev")}
                >
                  Previous
                </PrimaryBtn>
              </span>
              <span>
                <PrimaryBtn
                  type="button"
                  size="md"
                  color="LinearBlue"
                  onClick={
                    props.isAuthenticated
                      ? props.onSubmitHandler
                      : props.createWithRegistration
                  }
                >
                  Create
                </PrimaryBtn>
              </span>
            </div>
          ) : props.for === "edit" ? (
            <div className="btn-wrapper">
              <PrimaryBtn
                type="button"
                size="md"
                color="yellow"
                onClick={props.onEditHandler}
              >
                Edit
              </PrimaryBtn>
            </div>
          ) : null}
          <p>
            Already Have an account ?{" "}
            <span>
              <PrimaryBtn
                type="button"
                size="md"
                color="tranparentLinearBlue"
                onClick={() => setLogInScreeen(true)}
              >
                Log In Instead
              </PrimaryBtn>
            </span>
          </p>
        </div>
      )}
    </div>
  );
}

HeroFlowStep.propTypes = {
  for: PropTypes.oneOf(["creation", "edit"]).isRequired,
  dashboardMode: PropTypes.string.isRequired,
  inputs: PropTypes.object.isRequired,
  inputChangeHandler: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
};

export default HeroFlowStep;
