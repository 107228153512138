import React, { useState, useEffect, useRef } from "react";
import "./CollapseCard.css";
import PropTypes from "prop-types";
import Title from "../../Typography/Title/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const CollapseCard = (props) => {
  const [show, setShow] = useState(false);
  const cardEl = useRef(null);
  const cardHeadEl = useRef(null);

  const toggleCard = () => {
    if (show) {
      cardEl.current.style.height = `${cardHeadEl.current.offsetHeight + 6}px`;
    } else {
      cardEl.current.style.height = `100%`;
    }

    setShow(!show);
  };

  useEffect(() => {
    cardEl.current.style.height = `${cardHeadEl.current.offsetHeight + 6}px`;
  }, []);

  return (
    <div>
      {props.for === "rewards" ? (
        <div className={`CollapseCard collapse-reward ${show}`} ref={cardEl}>
          <div className="CollapseCard-Head" ref={cardHeadEl}>
            <div className="wrap-title">
              <p>{props.title}</p>
            </div>
            <div className="wrap-arrow" onClick={toggleCard}>
              <span className="arrow">
                <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown} />
              </span>
            </div>
          </div>
          {show ? (
            <div className="CollapseCard-Body">{props.children}</div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className={`CollapseCard ${show}`} ref={cardEl}>
          <div className="CollapseCard-Head" ref={cardHeadEl}>
            <div className="wrap-title">
              <Title size="sm" color="dark" isResponsive={true}>
                {props.title}
              </Title>
            </div>
            <div className="wrap-arrow" onClick={toggleCard}>
              <span className="arrow">
                <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown} />
              </span>
            </div>
          </div>
          <div className="CollapseCard-Body">{props.children}</div>
        </div>
      )}
    </div>
  );
};

CollapseCard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CollapseCard;
