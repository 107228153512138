import React, { useState, useEffect } from "react";
import PreviewGoal from "../../Dashboard/PreviewDetails/PreviewGoal/PreviewGoal";
import CauseWorldImpact from "../../Dashboard/PreviewDetails/WorldImpact/WorldImpact";
import DetailsSummary from "../../Dashboard/PreviewDetails/DetailsSummary/DetailsSummary";
import HeroResponsible from "../../Dashboard/PreviewDetails/HeroResponsible/HeroResponsible";
import { connect } from "react-redux";
import UpdateCause from "../../../components/Dashboard/PreviewDetails/PreviewUpdateModals/UpdateCause/UpdateCause";
import PreviewDetailsMain from "../../Dashboard/PreviewDetails/PreviewDetailsMain/PreviewDetailsMain";
import Hero from "../../../Models/Hero";

//images
import goalachieved from "../../../assets/images/goalachieved.png";
import dollarSack from "../../../assets/images/dollarsack.png";

function CauseDetailsLayout(props) {
  const [showcauseUpdateModal, setshowCauseUpdateModal] = useState(false);
  const [showShareModal, setshowShareModal] = useState(false);
  const [showGoalUpdateModal, setshowGoalUpdateModal] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(
    () =>
      Hero.fetchFundraiserCategories().then((categories) =>
        setCategories(categories)
      ),
    []
  );

  let impactParsed = null;
  if (props.selectedCause.impact) {
    impactParsed = JSON.parse(props.selectedCause.impact);
  }

  return (
    <div className="preview-details">
      <div className="previewDetails-wrap row">
        {/* udpates cause contains update modals */}
        <UpdateCause
          dashboardMode={props.dashboardMode}
          showcauseUpdateModal={showcauseUpdateModal}
          setshowCauseUpdateModal={setshowCauseUpdateModal}
          showShareModal={showShareModal}
          setshowShareModal={setshowShareModal}
          showGoalUpdateModal={showGoalUpdateModal}
          setshowGoalUpdateModal={setshowGoalUpdateModal}
          selectedCause={props.selectedCause}
          categories={categories}
        />
        <div className="previewDetails-left col-12 col-lg-9 ">
          <PreviewDetailsMain
            dashboardMode={props.dashboardMode}
            isAuthenticated={props.isAuthenticated}
            user={props.user}
            campaign={props.selectedCause}
            setshowCauseUpdateModal={setshowCauseUpdateModal}
            setshowShareModal={setshowShareModal}
            categories={categories}
          />
          {/* <p>{selectedCause.goal}</p> */}
          <div className="DetailsAmount">
            <PreviewGoal
              dashboardMode={props.dashboardMode}
              isAuthenticated={props.isAuthenticated}
              setshowGoalUpdateModal={setshowGoalUpdateModal}
              user={props.user}
              icon={dollarSack}
              editbtn="true"
              name="Goal"
              amount={"$" + props.selectedCause.goal}
              creatorId={props.selectedCause.creatorId}
              for="goal"
            />
            <PreviewGoal
              dashboardMode={props.dashboardMode}
              isAuthenticated={props.isAuthenticated}
              user={props.user}
              icon={goalachieved}
              editbtn="false"
              name="Achieved"
              amount={"$ 0"}
            />
          </div>
          <div className="WorldImpact-section">
            <CauseWorldImpact
              impact={(() => {
                let impactValues = [];
                let impactLables = [];
                for (let k in impactParsed) {
                  impactLables.push(k);
                  impactValues.push(impactParsed[k]);
                }
                return [impactLables, impactValues];
              })()}
              user={props.user}
            />
          </div>
          <div className="DetailsSummary-section">
            <DetailsSummary
              dashboardMode={props.dashboardMode}
              isAuthenticated={props.isAuthenticated}
              user={props.user}
              campaign={props.selectedCause}
            />
          </div>
        </div>
        <div className="previewDetails-right col-lg-3 col-12">
          <HeroResponsible
            dashboardMode={props.dashboardMode}
            isAuthenticated={props.isAuthenticated}
            user={props.user}
            creatorId={props.selectedCause.creatorId}
            categories={categories}
            campaignType="cause"
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    dashboardMode: state.dashboard.mode,
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
}

export default connect(mapStateToProps)(CauseDetailsLayout);
