import React, { Component } from "react";
import DashboardLayout from "../../../components/Layouts/DashboardLayout/DashboardLayout";
import TribeWorldMapV2 from "../../../components/UI/TribeWorldMapV2/TribeWorldMapV2";
// import TribeWorldMap from "../../../components/UI/TribeWorldMap/TribeWorldMap";

export default class TribeWorld extends Component {
  state = {};
  render() {
    return (
      <div>
        <DashboardLayout>
          <div
            style={{
              height: "85vh",
              width: "100%",
              position: "relative",
            }}
          >
            <TribeWorldMapV2 />
          </div>
        </DashboardLayout>
      </div>
    );
  }
}
