import React, { Component } from "react";
import "./SignupModal.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
	receiveLogin as loginUser,
	setDashboardMode,
} from "../../../../store/actions";
import { requestHandler } from "../../../../utils/RequestHandler";
import AuthModal from "../AuthModal/AuthModal";
import TribePrimaryBtn from "../../../UI/Buttons/TribePrimaryBtn/TribePrimaryBtn";
import HorizontalLine from "../../../UI/HorizontalLine/HorizontalLine";
import PrimaryAuthInput from "../../../UI/Inputs/PrimaryAuthInput/PrimaryAuthInput";
import CheckBox from "../../../UI/Inputs/CheckBox/CheckBox";
import UserModel from "../../../../Models/User";
import sweetalert from "sweetalert";

// Images
import TribeLogo from "../../../../assets/images/tribelogo.png";

class SignupModal extends Component {
	state = {
		activeScreen: 1,
		totalScreens: 3,
		showScreen1: false,
		showScreen2: false,
		showScreen3: false,
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		confirmPassword: "",
		username: "",
		nationality: "",
		promotionalEmails: false,
		terms: false,
	};

	componentDidMount() {
		this.setState({ showScreen1: this.props.showModal });
	}

	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	changeScreen = (toScreen) => {
		const { activeScreen, totalScreens } = this.state;

		if (toScreen === "next" && activeScreen < totalScreens) {
			this.setState((prevState) => {
				return {
					[`showScreen${activeScreen}`]: false,
					[`showScreen${activeScreen + 1}`]: true,
					activeScreen: prevState.activeScreen + 1,
				};
			});
		} else if (toScreen === "prev" && activeScreen > 1) {
			this.setState((prevState) => {
				return {
					[`showScreen${activeScreen}`]: false,
					[`showScreen${activeScreen - 1}`]: true,
					activeScreen: prevState.activeScreen - 1,
				};
			});
		}
	};

	screenOne = () => {
		return (
			<AuthModal
				showModal={this.props.showModal}
				toggleModal={this.props.toggleModal}
				title="Join Tribe"
			>
				<div className="SignupModal">
					<p className="screenOne-text">
						Welcome to Tribe. Before you begin your journey, as
						hero, angel or both, your tribe would like to know a
						little more about you:
					</p>
					<TribePrimaryBtn
						type="dark"
						onClick={() => {
							this.props.toggleModal();
							this.changeScreen("next");
						}}
					>
						Continue with Email
					</TribePrimaryBtn>
					<HorizontalLine />
					<div className="mb-3">
						<TribePrimaryBtn type="facebook">
							Continue with Facebook
						</TribePrimaryBtn>
					</div>
					<div className="mb-2">
						<TribePrimaryBtn type="google">
							Continue with Google
						</TribePrimaryBtn>
					</div>
				</div>
			</AuthModal>
		);
	};

	screenTwo = () => {
		const {
			firstName,
			lastName,
			email,
			password,
			confirmPassword,
			promotionalEmails,
			terms,
		} = this.state;

		return (
			<AuthModal
				showModal={this.state.showScreen2}
				title="Join Tribe"
				headerBtn="prev"
				headerBtnClick={() => {
					this.props.toggleModal();
					this.changeScreen("prev");
				}}
			>
				<div className="SignupModal">
					<PrimaryAuthInput
						borderStyle="topBordersRounded"
						type="text"
						placeholder="First name"
						value={firstName}
						name="firstName"
						onChange={this.onInputChange}
					/>
					<PrimaryAuthInput
						borderStyle="bottomBordersRounded"
						type="text"
						placeholder="Last name"
						value={lastName}
						name="lastName"
						onChange={this.onInputChange}
					/>
					<div className="my-3">
						<PrimaryAuthInput
							borderStyle="rounded"
							type="email"
							placeholder="Email"
							value={email}
							name="email"
							onChange={this.onInputChange}
						/>
					</div>
					<div className="my-3">
						<PrimaryAuthInput
							borderStyle="rounded"
							type="password"
							placeholder="Password"
							value={password}
							name="password"
							onChange={this.onInputChange}
						/>
					</div>
					<div className="my-3">
						<PrimaryAuthInput
							borderStyle="rounded"
							type="password"
							placeholder="Confirm Password"
							value={confirmPassword}
							name="confirmPassword"
							onChange={this.onInputChange}
						/>
					</div>
					<div className="mx-1">
						<CheckBox
							size="sm"
							label="I want to receive newsletters via email"
							color="dark"
							input={{
								defaultChecked: promotionalEmails,
								onChange: (e) =>
									this.setState({
										promotionalEmails:
											!this.state.promotionalEmails,
									}),
							}}
						/>
						<CheckBox
							size="sm"
							label="I agree to the Terms of Use and have read the Privacy Policy"
							color="dark"
							input={{
								defaultChecked: terms,
								onChange: (e) => {
									this.setState({ terms: !this.state.terms });
								},
							}}
						/>
					</div>
					<div className="mt-3 mb-2">
						<TribePrimaryBtn
							type="dark"
							onClick={this.validateStepTwoBody}
						>
							Continue
						</TribePrimaryBtn>
					</div>
				</div>
			</AuthModal>
		);
	};

	screenThree = () => {
		return (
			<AuthModal showModal={this.state.showScreen3} title="Join Tribe">
				<div className="SignupModal">
					<h4 className="title-screenFive">Welcome to Tribe</h4>
					<div className="center-img">
						<div className="wrap-dummyImg">
							<img src={TribeLogo} alt="TribeLogo" />
						</div>
					</div>
					<div className="my-3">
						<TribePrimaryBtn
							type="hero"
							onClick={() => {
								this.props.history.push("/hero-dashboard");
								this.props.setDashboardMode("hero");
							}}
						>
							Become a Hero
						</TribePrimaryBtn>
					</div>
					<div className="mt-3 mb-2">
						<TribePrimaryBtn
							type="angel"
							onClick={() => {
								this.props.history.push("/angel-dashboard");
								this.props.setDashboardMode("angel");
							}}
						>
							Become an Angel
						</TribePrimaryBtn>
					</div>
				</div>
			</AuthModal>
		);
	};

	validateStepTwoBody = () => {
		const { firstName, lastName, email, password, confirmPassword, terms } =
			this.state;

		if (terms) {
			if (firstName && lastName && email && password && confirmPassword) {
				if (password === confirmPassword) {
					this.onSubmitHandler();
				} else {
					sweetalert("Error", "Passwords do not match.", "error");
				}
			} else {
				sweetalert(
					"Error",
					"Kindly fill the required fields.",
					"error"
				);
			}
		} else {
			sweetalert(
				"Error",
				"Kindly check the Terms and conditions.",
				"error"
			);
		}
	};

	signIn = (email, password) => {
		return requestHandler("login", {
			email: email.toLowerCase(),
			password,
			userType: "other",
		}).then(
			(result) => {
				if (result.status === 200) {
					localStorage.setItem("auth", JSON.stringify(result.data));
					this.props.loginUser(result.data);
				} else {
					if (result.failedLoginAttempts > 4) {
						sweetalert(
							"Error",
							"Too many failed login attempts for this user. Please try again later or refresh the page",
							"error"
						);
						return;
					}
					sweetalert("Info", result.message, "info");
				}
			},
			(e) => {
				console.log("ERROR: ", e);
			}
		);
	};

	onSubmitHandler = () => {
		const newUser = {
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
			password: this.state.password,
			terms: this.state.terms,
			promotionalEmails: this.state.promotionalEmails,
		};

		UserModel.registerUser(newUser)
			.then(async (result) => {
				if (result.status === 200) {
					await this.signIn(newUser.email, newUser.password);
					this.changeScreen("next");
				} else {
					sweetalert("Error", result.message, "error");
				}
			})
			.catch((err) => {
				sweetalert(
					"Error",
					"Server side error occured while registering user",
					"error"
				);
			});
	};

	render() {
		return (
			<div className="SignupModals">
				{this.screenOne()}
				{this.screenTwo()}
				{this.screenThree()}
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		loginUser: (user) => dispatch(loginUser(user)),
		setDashboardMode: (dashboardMode) => {
			localStorage.setItem(
				"dashboardMode",
				JSON.stringify({ dashboardMode })
			);
			dispatch(setDashboardMode(dashboardMode));
		},
	};
}

export default connect(null, mapDispatchToProps)(withRouter(SignupModal));
