// Nouman's account. Remember to make Tribe Account
export const tinyCloudAPIKey =
  "mnow3bl9xbzpel00au38gdu6m6zqw8jmh3zmzgqqnl18wmep";
export const domainURL = "http://localhost:3000";

export const STRIPE_KEY = "pk_test_MpY8twqFgFB3f3bB6efWOi4A00eW8djIBv";
// export const STRIPE_KEY =
//   "pk_live_51JBcQbBOGP6zVhYPlKkgVCmdgXLD4R1bnB7juM2wq4DG4kAMtqw8gMEmWKz3BHZ4JDVDtECGBEgtRf2F75EXopFQ0061WP9Mjb";
export const ENVIRONMENT = "DEVELOPMENT";
export const BASEURL_DEVELOPMENT = "https://dev.tribeworld.org/api";
export const BASEURL_PRODUCTION = "https://tribeworld.org/api";
export const FACEBOOK_APP_ID = 245044929926284;
export const GOOGLE_AUTH_ID =
  "157430914060-c100mt3r4nfcmd1tsm57g575gg5ahtap.apps.googleusercontent.com";
export const GOOGLE_API_KEY =
  "157430914060-c100mt3r4nfcmd1tsm57g575gg5ahtap.apps.googleusercontent.com";

export const BACKEND_URL = "https://dev.tribeworld.org/api";
//export const BACKEND_URL = "https://tribeworld.org/api";
//export const BACKEND_URL = 'http://localhost:5051/api'

export const GENERATE_AVATAR_URL = "https://dev.tribeworld.org/generateavatar/";
export const GOOGLE_MAP_API = "AIzaSyDt9GY0qjMwSFvi-ODbrRJFZg3wCwtZofc";
export const IMPACT_SCALE_LABELS = {
  poverty_hunger_and_health: "Poverty, Hunger & Health",
  education_and_equality: "Education and Equality",
  climate_conservation: "Climate Conservation",
  cleanwater_and_sanitation: "clean water and sanitation",
  animal_birds_and_plants: "Animals,Birds and Plants",
  innovative_growth: "Innovative Growth",
};
