import React from "react";
import "./Faqs.css";
import questionIcon from "../../../../../assets/images/faq-questionIcon.png";

function Faqs({ faqs }) {
  return (
    <div className="preview-faqs">
      <div className="faq-block">
        {faqs ? (
          faqs.length !== 0 ? (
            faqs.map((faq) => {
              return (
                <div key={faq.id} className="faq-content">
                  <div className="faq-question">
                    <img
                      className="question-icon"
                      src={questionIcon}
                      alt="qestionIcon"
                    />
                    <p>{faq.question}</p>
                  </div>
                  <div className="faq-answer">
                    <p>{faq.answer}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="no-faqs">No Faqs</p>
          )
        ) : (
          <p className="no-faqs">No faqs</p>
        )}
      </div>
    </div>
  );
}

export default Faqs;
