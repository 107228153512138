import React, { Component } from "react";
import "./Faqs.css";
import { Link } from "react-router-dom";
import NavbarWithBackdrop from "../../../components/Sections/NavbarWithBackdrop/NavbarWithBackdrop";
import Footer from "../../../components/Sections/Footer/Footer";
import CollapseCard from "../../../components/UI/Cards/CollapseCard/CollapseCard";
import Title from "../../../components/UI/Typography/Title/Title";

// Images
import BackdropImg from "../../../assets/images/backdropTest.png";

class Faqs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: ["general", "hero", "angel"],
      activeOption: "general",
    };

    this.generalRef = React.createRef();
    this.heroRef = React.createRef();
    this.angelRef = React.createRef();
    this.optionsEl = React.createRef();

    window.onscroll = this.onScrollHandler;
  }

  onScrollHandler = () => {
    if (this.optionsEl.current) {
      if (window.scrollY >= 425) {
        this.optionsEl.current.style.position = "sticky";
        this.optionsEl.current.style.top = "1rem";
      } else {
        this.optionsEl.current.style.position = "static";
      }
    }
  };

  selectOptionHandler = (selectedOpt) => {
    const { options } = this.state;
    const doesExist = options.find((opt) => opt === selectedOpt);

    if (doesExist) {
      this.setState({ activeOption: selectedOpt });

      switch (selectedOpt) {
        case "general":
          this.generalRef.current.scrollIntoView();
          break;
        case "hero":
          this.heroRef.current.scrollIntoView();
          break;
        case "angel":
          this.angelRef.current.scrollIntoView();
          break;
        default:
          return null;
      }
    } else {
      return;
    }
  };

  render() {
    const { activeOption } = this.state;

    return (
      <main className="Faqs">
        <NavbarWithBackdrop
          navbarMode="white"
          backdropImg={BackdropImg}
          text="FAQS"
          textColor="white"
        />

        <section className="Faqs-Body">
          <div className="container">
            <div className="row">
              <div className="col-4 d-none d-lg-block">
                <div className="Faq-options" ref={this.optionsEl}>
                  <div
                    className={
                      activeOption === "general"
                        ? "Option-Box active"
                        : "Option-Box"
                    }
                    onClick={() => this.selectOptionHandler("general")}
                  >
                    <Title
                      size="md"
                      color={activeOption === "general" ? "white" : "dark"}
                      isResponsive={false}
                    >
                      General Faq
                    </Title>
                  </div>
                  <div
                    className={
                      activeOption === "hero"
                        ? "Option-Box active"
                        : "Option-Box"
                    }
                    onClick={() => this.selectOptionHandler("hero")}
                  >
                    <Title
                      size="md"
                      color={activeOption === "hero" ? "white" : "dark"}
                      isResponsive={false}
                    >
                      Hero Faq
                    </Title>
                  </div>
                  <div
                    className={
                      activeOption === "angel"
                        ? "Option-Box active"
                        : "Option-Box"
                    }
                    onClick={() => this.selectOptionHandler("angel")}
                  >
                    <Title
                      size="md"
                      color={activeOption === "angel" ? "white" : "dark"}
                      isResponsive={false}
                    >
                      Angel Faq
                    </Title>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-8">
                <div ref={this.generalRef} className="Faq-Section General-Faq">
                  <div className="section-title">
                    <Title size="md" color="dark" isResponsive={true}>
                      General FAQ
                    </Title>
                  </div>
                  <CollapseCard title="What is crowdfunding?">
                    <p>
                      Crowdfunding is the practice of funding a project or
                      venture by raising small amounts of money from a large
                      number of people. You can read more about it in our
                      Guidance for Heroes document.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="What is a Hero?">
                    <p>
                      A hero is someone who creates and manages a crowdfunding
                      campaign on Tribe
                    </p>
                  </CollapseCard>
                  <CollapseCard title="What is an angel?">
                    <p>
                      An angel is someone who donates (either money, time or
                      expertise) to a campaign through Tribe.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="I forgot my login details. How can I retrieve them?">
                    <p>
                      Retrieve your password by going to the login page and
                      clicking “forgot password”.
                    </p>
                  </CollapseCard>
                </div>
                <div ref={this.heroRef} className="Faq-Section Hero-Faq">
                  <div className="section-title">
                    <Title size="md" color="dark" isResponsive={true}>
                      Hero FAQ
                    </Title>
                  </div>

                  <CollapseCard title="When do I receive the funds donated by Angels?">
                    <p>
                      Funds will be transferred 14 days after the end of your
                      campaign. Please make sure your bank details are up to
                      date to guarantee the money is delivered on time.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="How can I share my campaign with others?">
                    <p>
                      Copy the link and paste it onto your social media. On your
                      campaign page there are options to share directly onto
                      social media.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="Can I launch multiple campaigns at once?">
                    <p>
                      Yes. You can launch and manage an unlimited number of
                      campaigns.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="How long do I need to give updates for after my campaign closes?">
                    <p>
                      Ideally you should send updates to your Angels weekly
                      until the project is completed.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="Can multiple user accounts set up and manage a single campaign?">
                    <p>
                      No, only one user account can set up and manage a campaign
                    </p>
                  </CollapseCard>
                  <CollapseCard title="What happens if my campaign does not reach its target?">
                    <p>
                      You will receive the funds you raised, less applicable
                      fees 14 days after the end of your campaign
                    </p>
                  </CollapseCard>
                  <CollapseCard title="What happens if my campaign reaches its target before the end date?">
                    <p>
                      You keep going! Your campaign can continue to raise funds
                      up until the specified end date. Your campaign can
                      continue to raise past your initial target. The grand
                      total raised will be transferred to your registered bank
                      account 14 days after the end of the campaign.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="What kind of campaigns can I create?">
                    <p>
                      All types ranging from arts to medical. Please see our{" "}
                      <a href="/page-not-built">list of fundraising</a>
                      categories for details.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="How much does it cost to fundraise on Tribe?">
                    <p>
                      Please see the fees page{" "}
                      <a href="/page-not-built">here</a> for details
                    </p>
                  </CollapseCard>
                  <CollapseCard title="My campaign is finished - how do I receive my funds?">
                    <p>
                      Ensure the bank details on your profile are correct and up
                      to date. To check:
                      <ul className="ml-4">
                        <li>Log in and go to your dashboard</li>
                        <li>Go to Hero settings</li>
                        <li>Click on update Bank Details</li>
                      </ul>
                    </p>
                    <p>
                      Tribe Elders will verify your details and once confirmed
                      you will receive an email with details of your funds
                      transfer. Please read to the{" "}
                      <a href="/page-not-built">fees page</a> to ensure you are
                      aware of the associated costs.
                    </p>
                  </CollapseCard>
                </div>
                <div ref={this.angelRef} className="Faq-Section Angel-Faq">
                  <div className="section-title">
                    <Title size="md" color="dark" isResponsive={true}>
                      Angel FAQ
                    </Title>
                  </div>

                  <CollapseCard title="How do I know if my donation has been received by the Hero?">
                    <p>
                      You will receive confirmation of your donation via email
                      each time you donate
                    </p>
                  </CollapseCard>
                  <CollapseCard title="When do I receive rewards after donating?">
                    <p>
                      Your rewards shipping timeline will be shown on the
                      campaign homepage, which you can reach from your
                      Dashboard. The Hero will also share regular updates on the
                      project - please contact them directly in the first
                      instance should you have any queries.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="How can I contact the Hero?">
                    <p>
                      You can contact your Hero by:
                      <ul className="ml-4">
                        <li>Chat - found on the campaign page</li>
                        <li>
                          Email - details sent to you in the donation
                          confirmation email
                        </li>
                        <li>
                          Call - details sent to you in the donation
                          confirmation email
                        </li>
                      </ul>
                    </p>
                  </CollapseCard>
                  <CollapseCard title="What can I do if the Hero is unresponsive?">
                    <p>
                      All Heroes agree to to address all Angel queries within 3
                      days when they create a campaign with Tribe. We are sorry
                      if this is not the case. Please do the following:
                      <ul className="ml-4">
                        <li>Contact Hero on their social media.</li>
                        <li>
                          Check the Campaign homepage to see if the Hero has
                          posted recent updates .
                        </li>
                        <li>
                          Contact Tribe Elders so that they may remind the Hero
                          of their promise. Terms & Conditions
                        </li>
                      </ul>
                    </p>
                  </CollapseCard>
                  <CollapseCard title="Do I receive rewards for every donation I make?">
                    <p>
                      You receive rewards for donations you make to enterprise
                      projects. You do not receive rewards to donations made to
                      charity projects.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="Can I request a refund?">
                    <p>
                      You can request a refund from Tribe while the campaign is
                      live. If the campaign has ended you will have to contact
                      the Hero and request the refund directly from them.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="Can I pay via bank transfer?">
                    <p>
                      Yes, please send your donation to insert Tribe bank
                      details. Once your donation amount is received we will
                      send you a confirmation email.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="My reward was destroyed before it arrived.">
                    <p>
                      Please contact the Hero who will help find a solution. If
                      you are unable to resolve the issue with the Hero, please
                      reach out to the Elders.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="Can my donation be used when filing my taxes?">
                    <p>
                      Your tax requirements will depend on the country in which
                      you reside, and on the type of campaign you are backing.
                      Please contact your local tax office or an accountant
                      registered within your jurisdiction for more information.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="Does the campaign receive my donation immediately?">
                    <p>
                      Heroes for charities will receive all donations 14 days
                      after the end of the campaign. Heroes for enterprises will
                      receive all donations periodically during their campaign
                      and the final tranche will be received within 14 days of
                      their last successful reward delivery.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="How can I verify a campaign’s authenticity?">
                    <p>
                      Each campaign and Hero undergoes a stringent
                      authentication process before being made public on Tribe.
                      To verify further please:
                      <ul className="ml-4">
                        <li>
                          Visit the Campaign homepage to see updates, number of
                          Angels and read about the campaign’s description.
                        </li>
                        <li>Read Hero’s profile</li>
                        <li>
                          Visit Campaign and Hero’s social media and additional
                          website links provided
                        </li>
                      </ul>
                    </p>
                    <p>
                      If you have concerns about the authenticity of a Hero or
                      campaign on Tribe, please reach out to the Elders.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="Can I view my entire donation history?">
                    <p>
                      Yes, you can - on your{" "}
                      <Link to="/angel-dashboard">dashboard</Link>
                    </p>
                  </CollapseCard>
                  <CollapseCard title="What are Product Stages?">
                    <p>
                      Product Stages show at which stage of product development
                      the Hero is with their enterprise product/service. There
                      are 4 main stages:
                      <ul className="ml-4">
                        <li>Concept</li>
                        <li>Prototype</li>
                        <li>Production</li>
                        <li>Shipping</li>
                      </ul>
                    </p>
                    <p>The stage is highlighted on the campaign homepage.</p>
                  </CollapseCard>
                  <CollapseCard title="How are product stages verified?">
                    <p>
                      Heroes submit a sample of their product which is then
                      reviewed and certified by Tribe Elders.
                    </p>
                  </CollapseCard>
                  <CollapseCard title="Where can I follow a campaign’s progress?">
                    <p>
                      Go to the campaign homepage and select the ‘Updates’ tab.
                      This is where the Hero posts all the latest information.
                      Additionally, when you donate you are added to the Hero’s
                      email list so that you receive updates straight to your
                      registered email address.
                    </p>
                  </CollapseCard>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    );
  }
}

export default Faqs;
