import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout/DashboardLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import ProjectCard from "../../../../components/UI/Cards/ProjectCard/ProjectCard";
import Navbar from "../../../../components/Sections/Navbar/Navbar";

function HeroEnterprises(props) {
  const {
    heroEnterprises,
    setHeroEnterprises,
    enterprises,
    hero,
    isAuthenticated,
  } = props;
  const [fullscreen, setFullscreen] = useState(true);

  let heroEnterprisesBody = (
    <main className="campaigns-container">
      <div className="row">
        {enterprises
          ? enterprises.map((enterprise) => (
              <div className="col card-wrapper mb-4" key={enterprise._id}>
                <ProjectCard
                  for="hero"
                  charity={enterprise}
                  campaign="enterprise"
                />
              </div>
            ))
          : null}
      </div>
    </main>
  );
  return (
    <>
      <Modal
        size="lg"
        fullscreen={fullscreen}
        show={heroEnterprises}
        onHide={() => setHeroEnterprises(false)}
        aria-labelledby="updateCauseModal"
      >
        <Modal.Body>
          {isAuthenticated ? (
            <DashboardLayout
              for="heroEnterprises"
              hero={hero}
              setHeroEnterprises={setHeroEnterprises}
            >
              {heroEnterprisesBody}
            </DashboardLayout>
          ) : (
            <div className="notlogged-detailsPage">
              <div className="navbar-wrap">
                <Navbar mode="dark" />
              </div>
              <div className="d-flex justify-content-between m-4">
                <p
                  className="heroCampaigns-backBtn"
                  onClick={() => {
                    setHeroEnterprises(false);
                  }}
                >
                  <FontAwesomeIcon icon={faAngleLeft} /> Back
                </p>

                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "36px",
                    textTransform: "capitalize",
                  }}
                >
                  {hero ? `${hero.firstName}'s` : ""} Causes
                </p>
              </div>
              {heroEnterprisesBody}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HeroEnterprises;
