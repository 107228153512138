import React, { useEffect, useState } from "react";
import "./CauseStepOne.css";
import LabelledInput from "../../../Inputs/LabelledInput/LabelledInput";
import LocationAutoComplete from "../../../Inputs/LocationAutoComplete/LocationAutoComplete";
import PrimaryBtn from "../../../Buttons/PrimaryBtn/PrimaryBtn";
import Hero from "../../../../../Models/Hero";
import sweetalert from "sweetalert";

const CauseStepOne = (props) => {
  const inputChangeHandler = (e) => props.inputChangeHandler(e);

  const locationInputChangeHander = (location, latLng) => {
    props.inputChangeHandler({
      target: {
        name: "location",
        value: location,
      },
    });
    if (latLng) {
      const { lat, lng } = latLng;
      props.inputChangeHandler({
        target: {
          name: "lat",
          value: lat,
        },
      });
      props.inputChangeHandler({
        target: {
          name: "lng",
          value: lng,
        },
      });
    }
  };

  const [count, setCount] = useState("0");
  const [categories, setCategories] = useState([]);

  const textAreaOnChange = (e) => {
    setCount(e.target.value.length);
    inputChangeHandler(e);
  };

  useEffect(
    () =>
      Hero.fetchFundraiserCategories().then((categories) =>
        setCategories(categories)
      ),
    []
  );
  if (!props.inputs.category && categories.length) {
    inputChangeHandler({
      target: { value: categories[0]._id, name: "category" },
    });
  }

  const validateLocation = () => {
    if (props.inputs.lat && props.inputs.lng) {
      props.changeStep("next");
    } else {
      sweetalert(
        "Location",
        "Select a valid Location from the list",
        "warning"
      );
    }
  };

  return (
    <div className="CauseStepOne">
      <div className="row">
        <div className="input-wrapper col-12 col-md-6">
          <LocationAutoComplete
            setLocatoinAutocomplete={locationInputChangeHander}
            location={props.inputs.location || ""}
          />
        </div>
        <div className="input-wrapper col-12 col-md-6">
          <LabelledInput
            size="fullWidth"
            dashboardMode={props.dashboardMode}
            inputType="input"
            label="Goal"
            inputIcon="inputIcon"
            input={{
              type: "number",
              placeholder: "1000",
              name: "goal",
              onChange: inputChangeHandler,
              value: props.inputs.goal,
            }}
          ></LabelledInput>
        </div>
      </div>
      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="input"
          label="Title"
          input={{
            type: "text",
            placeholder: "Cause Title",
            name: "title",
            onChange: inputChangeHandler,
            value: props.inputs.title,
          }}
        />
      </div>

      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="textarea"
          label="Short Description"
          input={{
            placeholder: "Describe what your cause is about",
            name: "description",
            onChange: textAreaOnChange,
            maxlength: "160",
            value: props.inputs.description,
          }}
        />
        <p className="textarea-count">{count}/160 characters left</p>
      </div>
      <div className="input-wrapper">
        <LabelledInput
          size="fullWidth"
          dashboardMode={props.dashboardMode}
          inputType="select"
          label="Cause Category"
          input={{
            placeholder: "Select a category",
            name: "category",
            onChange: inputChangeHandler,
            value: props.inputs.category,
          }}
        >
          {categories.map((cat) => (
            <option value={cat._id} key={cat._id}>
              {" "}
              {cat.name}{" "}
            </option>
          ))}
        </LabelledInput>
      </div>

      <div className="btn-wrapper">
        <PrimaryBtn
          type="button"
          size="md"
          color="LinearBlue"
          onClick={validateLocation}
        >
          Next
        </PrimaryBtn>
      </div>
    </div>
  );
};

export default CauseStepOne;
