import { requestHandler } from "../utils/RequestHandler";

class Hero {
  static fetchCauses = (creatorId) => {
    return requestHandler("getCharity", { creatorId }).then((result) => {
      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error("Request status is not 200.");
      }
    });
  };

  static fetchFundraiserCategories = () => {
    return requestHandler("getCategories").then((result) => {
      if (result.status === 200) {
        return result.data;
      } else throw new Error("Cannot get categories");
    });
  };

  static fetchEnterprises = (creatorId) => {
    return requestHandler("getEnterprise", { creatorId }).then((result) => {
      console.log(result.status);
      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error("Request status is not 200.");
      }
    });
  };

  static fetchAllCauses = () => {
    return requestHandler("getCharity").then((result) => {
      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error("Request status is not 200.");
      }
    });
  };

  static fetchAllEnterprises = () => {
    return requestHandler("getEnterprise").then((result) => {
      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error("Request status is not 200.");
      }
    });
  };

  static createCause = (
    title,
    goal,
    shortDesc,
    locationName,
    latLng,
    category,
    faqs,
    customUrl,
    story,
    impact,
    content
  ) => {
    // if (Array.isArray(content)) content = [];
    const formData = new FormData();
    formData.append("title", title);
    formData.append("goal", goal);
    formData.append("shortDescription", shortDesc);
    formData.append("locationName", locationName);
    formData.append("location", latLng);
    formData.append("categoryId", category);
    formData.append("faqs", JSON.stringify(faqs));
    formData.append("customUrl", customUrl);
    formData.append("story", story);
    formData.append("impact", JSON.stringify(impact));
    for (let files of content) {
      formData.append("content", files);
    }

    return requestHandler("createCharity", formData).then((result) => {
      if (result.status === 200) return result.data[0];
      else throw new Error(result.message);
    });
  };

  static createEnterprise = (
    title,
    goal,
    customUrl,
    category,
    locationName,
    latLng,
    story,
    duration,
    shortDescription,
    productStage,
    faqs,
    impact,
    content
  ) => {
    // if (Array.isArray(content)) content = [];
    const formData = new FormData();
    formData.append("title", title);
    formData.append("goal", Number(goal).toString());
    formData.append("story", story);
    formData.append("duration", duration);
    formData.append("customUrl", customUrl);
    formData.append("shortDescription", shortDescription);
    formData.append("categoryId", category);
    formData.append("locationName", locationName);
    formData.append("location", latLng);
    formData.append("faqs", JSON.stringify(faqs));
    formData.append("productStage", JSON.stringify(productStage));
    formData.append("impact", JSON.stringify(impact));

    for (let files of content) {
      formData.append("content", files);
    }

    return requestHandler("createEnterprise", formData).then((result) => {
      if (result.status === 200) return result.data[0];
      else throw new Error(result.message);
    });
  };
}

export default Hero;
