import React, { useState, useEffect } from "react";
import "./ProjectCardList.css";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import CardImg from "../../../../assets/images/cardImg.png";
import { requestHandler } from "../../../../utils/RequestHandler/index";
import {
  faLandmark,
  faBriefcaseMedical,
  faGraduationCap,
  faCalendarDay,
  faHandHoldingHeart,
  faAmbulance,
  faTree,
  faPalette,
  faLaptopCode,
  faFilm,
  faPizzaSlice,
  faTshirt,
  faGamepad,
  faMusic,
  faBook,
  faHeartbeat,
  faTractor,
  faBraille,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sweetalert from "sweetalert";

const ProjectCardList = (props) => {
  const [comments, setComments] = useState([]);
  const [categories, setCategories] = useState();

  const campaignId = props.charity._id;
  const categoryId = props.charity.categoryId;

  const cardImg = props.charity.content
    ? props.charity.content.length !== 0
      ? props.charity.content[0]
      : CardImg
    : CardImg;

  const progressBar = Math.floor(
    (props.charity.donationAmount / props.charity.goal) * 100
  );

  const getComments = () => {
    return requestHandler("getCampaignComments", { campaignId })
      .then((res) => {
        setComments(res.data);
      })
      .catch((err) => {
        console.log(err);
        sweetalert("Error", "Error Occured While fetching comments.", "error");
      });
  };

  const getCategory = () => {
    requestHandler("getCategories")
      .then((res) => {
        if (res.status === 200) {
          setCategories(res.data);
        }
      })
      .catch((err) => {
        sweetalert(
          "Error",
          "Error occured while fetching Category for project card.",
          "error"
        );
      });
  };

  useEffect(() => {
    getComments();
    getCategory();
  }, []);

  let icon = faBraille;
  switch (props.charity.categoryId) {
    case "5ef083558910271f55a44178": //memorial
      icon = faLandmark;
      break;
    case "5ef083558910271f55a44177": //medical
      icon = faBriefcaseMedical;
      break;
    case "5ef083558910271f55a4417b": //education
      icon = faGraduationCap;
      break;
    case "5ef083558910271f55a4417c": //special event / commemoration
      icon = faCalendarDay;
      break;
    case "5ef083558910271f55a4417a": // nonprofit / charity
      icon = faHandHoldingHeart;
      break;
    case "5ef083558910271f55a44179": //emergency
      icon = faAmbulance;
      break;
    case "5f453f943387c33f0ab79348" || "5f453fa72b62f0406986a780": //environmental
      icon = faTree;
      break;
    case "5f453fb62b62f0406986a781": //art / design"
      icon = faPalette;
      break;
    case "5f453fbe2b62f0406986a782": //technology
      icon = faLaptopCode;
      break;
    case "5f453fc52b62f0406986a783": //film
      icon = faFilm;
      break;
    case "5f453fcd2b62f0406986a784": //food & beverages
      icon = faPizzaSlice;
      break;
    case "5f453fda2b62f0406986a785": //fashion & wearables
      icon = faTshirt;
      break;
    case "5f453fe12b62f0406986a786": //games
      icon = faGamepad;
      break;
    case "5f453fe72b62f0406986a787": //music
      icon = faMusic;
      break;
    case "5f453fee2b62f0406986a788": //publishing (books)
      icon = faBook;
      break;
    case "5f453ff52b62f0406986a789": //wellness
      icon = faHeartbeat;
      break;
    case "5f453ffe2b62f0406986a78a": //farming & agriculture
      icon = faTractor;
      break;
    case "5f4540132b62f0406986a78b": //other (how would you categorise your project?)
      icon = faBraille;
      break;
    default:
      icon = faBraille;
      break;
  }
  return (
    <div
      className="ProjectCardList row"
      onClick={() =>
        props.campaign === "cause"
          ? props.history.push(`/charity/details/${props.charity._id}`)
          : props.history.push(`/enterprise/details/${props.charity._id}`)
      }
    >
      <div className="project_item category-icon col-1">
        <span>
          <FontAwesomeIcon icon={icon} />
        </span>
      </div>
      <div className="project_item image col-1">
        <img src={cardImg} alt="project image" />
      </div>
      <div className="project_item col-2">
        <span>{props.charity.title} </span>
      </div>
      <div className="project_item col-2">
        <span>{props.charity.locationName}</span>
      </div>
      <div className="project_item col-1">
        <span>{props.charity.goal}$</span>
      </div>
      <div className="project_item card-progressbar  col-3">
        <p style={{ marginTop: "14px", marginBottom: "5px" }}>
          {props.charity.donationAmount ? props.charity.donationAmount : "0"}$
        </p>
        <ProgressBar
          className={`${progressBar >= 100 ? "complete" : ""}`}
          now={progressBar}
        />
      </div>
      <div className="project_item col-1">
        <span>{comments.length}</span>
      </div>
      <div className="project_item col-1">
        <span>
          {props.charity.anglesBacked ? props.charity.anglesBacked : 0}
        </span>
      </div>
    </div>
  );
};

ProjectCardList.propTypes = {
  for: PropTypes.oneOf(["hero", "angel"]).isRequired,
  charity: PropTypes.object.isRequired,
  campaign: PropTypes.oneOf(["cause", "enterprise"]).isRequired,
};

export default withRouter(ProjectCardList);
