import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  receiveLogin as loginUser,
  setDashboardMode,
} from "../../../store/actions";
import { Modal } from "react-bootstrap";
import sweetalert from "sweetalert";
import DashboardLayout from "../../../components/Layouts/DashboardLayout/DashboardLayout";
import Navbar from "../../../components/Sections/Navbar/Navbar";
import DonationForm from "../../../components/UI/Forms/DonationForm/DonationForm";
import UserModel from "../../../Models/User";
import "./CampaignDonation.css";
import { requestHandler } from "../../../utils/RequestHandler";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

export class CampaignDonation extends Component {
  state = {
    charityId: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    message: "",
    donateAnonymously: false,
    donationAmount: "",
    totalPayment: "",
    donationForPlatform: "15%",
    percentForPlatform: ["15%", "10%", "5%"],
    checkingEmailExistenceInSystem: false,
    emailGoodToGo: false,
    registerMe: true,
    paymentMethod: "",
    successModal: false,
    copied: false,
    failedWithEmail: null,
    failedLoginAttempts: null,
    redirect: null,
  };

  successModalToggle = () => {
    this.setState({
      successModal: !this.state.successModal,
    });
  };

  componentDidMount() {
    this.presetData(this.props);
    this.generatePassword();
  }

  componentWillReceiveProps(props) {
    this.presetData(props);
  }

  presetData = (props) => {
    const { user } = props;
    const { id } = props.match.params;
    this.setState({
      charityId: id,
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      email: user.email ? user.email : "",
    });
    if (props.isAuthenticated) {
      this.setState({
        checkingEmailExistenceInSystem: false,
        emailGoodToGo: true,
      });
    }
  };
  checkingEmailTimeout = null;
  checkingEmail = null;

  inputChangeHandler = (state, event) => {
    if (event.target.name === "email") {
      this.checkingEmail = event.target.value;
      clearTimeout(this.checkingEmailTimeout);
      this.checkingEmail = event.target.value;
      const myTimeOut = setTimeout(
        () => this.checkEmailExistenceInSystem(this.checkingEmail),
        500
      );
    }

    this.setState({
      ...this.state[state],
      [event.target.name]: event.target.value,
    });
  };

  anonymousToggle = () => {
    this.setState({
      donateAnonymously: !this.state.donateAnonymously,
    });
  };

  registerMeToggle = () => {
    this.setState({
      registerMe: !this.state.registerMe,
    });
  };

  donateNow = async () => {
    const {
      firstName,
      lastName,
      email,
      charityId,
      donateAnonymously,
      donationAmount,
      donationForPlatform,
      message,
      generatedPassword,
      password,
      totalPayment,
    } = this.state;

    let donationPercentage = donationForPlatform.substring(
      0,
      donationForPlatform.length - 1
    );

    let donationToPlatformAmount = (
      donationAmount *
      (donationPercentage / 100)
    ).toFixed(2);

    this.setState({
      totalPayment: (
        parseFloat(donationAmount) + parseFloat(donationToPlatformAmount)
      ).toString(),
    });

    if (!this.props.isAuthenticated && !this.state.emailGoodToGo) {
      await this.registerUser(firstName, lastName, email, generatedPassword);
    }

    if (!this.props.isAuthenticated && this.state.emailGoodToGo) {
      await this.signInUser(email, password);
    }
    requestHandler("donateToCharity", {
      charityId,
      donationAmount,
      donationForPlatform: donationToPlatformAmount,
      totalPayment,
      donateAnonymously,
      message,
    }).then((result) => {
      if (result.status === 200) {
        this.props.setDashboardMode("angel");
        this.setState({
          step: "donationComplete",
        });
        this.successModalToggle();
        this.props.history.push("/angel-donations");
      } else if (result.status === 500) {
        console.log("RESULT: ", result.status);
        alert(result.error.message);
      }
    });
  };

  initPaypalPayment = (totalPayment) => {
    requestHandler("paypalPayment", {
      amount: totalPayment.toString(),
    }).then(
      (response) => {
        if (response.status === 200) {
          window.open(response.approval_url, "_blank");
        }
      },
      (error) => {
        console.log("ERROR: ", error);
      }
    );
  };

  donateToCampaign = async () => {
    const {
      firstName,
      lastName,
      email,
      donationAmount,
      donationForPlatform,
      paymentMethod,
      generatedPassword,
      password,
      totalPayment,
    } = this.state;

    let donationPercentage = donationForPlatform.substring(
      0,
      donationForPlatform.length - 1
    );

    let donationToPlatformAmount = (
      donationAmount *
      (donationPercentage / 100)
    ).toFixed(2);

    this.setState({
      totalPayment: (
        parseFloat(donationAmount) + parseFloat(donationToPlatformAmount)
      ).toString(),
    });

    if (!this.props.isAuthenticated && !this.state.emailGoodToGo) {
      await this.registerUser(firstName, lastName, email, generatedPassword);
    }

    if (!this.props.isAuthenticated && this.state.emailGoodToGo) {
      await this.signInUser(email, password);
    }

    if (paymentMethod === "credit_debitCard") {
      sweetalert("credit", "not implemented yet", "info");
    } else if (paymentMethod === "paypal") {
      this.initPaypalPayment(totalPayment);
    } else {
      sweetalert("Bank", "not implemented yet", "info");
    }
  };

  vaidateInputs = () => {
    const {
      charityId,
      firstName,
      lastName,
      email,
      donationAmount,
      donationForPlatform,
      paymentMethod,
    } = this.state;
    if (
      charityId.length !== 0 &&
      firstName.length !== 0 &&
      lastName.length !== 0 &&
      email.length !== 0 &&
      donationAmount.length !== 0 &&
      donationAmount > 0 &&
      donationForPlatform.length !== 0
    ) {
      if (paymentMethod.length !== 0) {
        this.donateToCampaign();
      } else {
        sweetalert("Payment", "Select Payment Method", "info");
      }
    } else {
      sweetalert(
        "Required",
        "Please fill proper data before proceeding with payment",
        "warning"
      );
    }
  };

  generatePassword = () => {
    var length = 12,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    this.setState({
      generatedPassword: retVal,
    });
  };

  registerUser = async (firstName, lastName, email, password) => {
    let newUser = {
      firstName,
      lastName,
      email: email.toLowerCase(),
      password,
      service: true,
      terms: true,
      promotionalEmails: false,
    };

    let status = false;

    await UserModel.registerUser(newUser).then(async (result) => {
      if (result.status === 200) {
        this.setState({ newAccount: true });
        status = true;
        localStorage.setItem("auth", JSON.stringify(result.data));
        this.props.loginUser(result.data);
      } else {
        alert(result.message);
      }
    });

    return status;
  };

  signInUser = (email, password) => {
    if (email === this.state.failedWithEmail) {
      sweetalert(
        "Error",
        "Too many failed login attempts for this user. Please try again later or refresh the page.",
        "error"
      );
      return;
    }

    requestHandler("login", {
      email: email.toLowerCase(),
      password,
      userType: "other",
    })
      .then((result) => {
        console.log(result);
        if (result.status === 200) {
          localStorage.setItem("auth", JSON.stringify(result.data));
          this.props.loginUser(result.data);
        } else {
          if (result.failedLoginAttempts > 4) {
            this.setState({
              fauledAttempts: result.failedLoginAttempts,
              failedWithEmail: email,
            });

            sweetalert(
              "Error",
              "Too many failed login attempts for this user. Please try again later or refresh the page.",
              "error"
            );
            return;
          }
          sweetalert("Info", result.message, "info");
        }
      })
      .catch((e) => {
        sweetalert(
          "Error",
          "Server side error occured while requesting for login.",
          "error"
        );
      });
  };

  checkEmailExistenceInSystem = async (email) => {
    let checkingEmailExistenceInSystem = true;
    let emailGoodToGo = false;
    let responseMessage = null;

    this.setState({
      checkingEmailExistenceInSystem,
      emailGoodToGo,
      responseMessage,
    });
    try {
      let response = await requestHandler("checkEmailExistance", {
        email: email.toLowerCase(),
      });
      console.log({ response });
      checkingEmailExistenceInSystem = false;

      if (response.status !== 200) {
        emailGoodToGo = false;
        responseMessage = response.message;
      } else {
        emailGoodToGo = response.email === 0 ? false : true;
      }

      this.setState({
        emailGoodToGo,
        checkingEmailExistenceInSystem,
        responseMessage,
      });
    } catch (e) {
      console.log("ERROR: ", e);
    }
  };

  handleStripeToken = async (token) => {
    const {
      firstName,
      lastName,
      email,
      donationAmount,
      donationForPlatform,
      generatedPassword,
      password,
      totalPayment,
    } = this.state;

    if (!this.props.isAuthenticated && !this.state.emailGoodToGo) {
      await this.registerUser(firstName, lastName, email, generatedPassword);
    } else if (!this.props.isAuthenticated && this.state.emailGoodToGo) {
      await this.signInUser(email, password);
    }

    let donationPercentage = donationForPlatform.substring(
      0,
      donationForPlatform.length - 1
    );
    let donationToPlatformAmount = (
      donationAmount *
      (donationPercentage / 100)
    ).toFixed(2);

    this.setState({
      totalPayment: (
        parseFloat(donationAmount) + parseFloat(donationToPlatformAmount)
      ).toString(),
    });
    requestHandler("stripePayment", {
      stripeToken: token.id,
      amount: totalPayment.toString(),
    }).then(
      (response) => {
        if (response.status === 200) {
          this.donateNow();
        }
      },
      (error) => {
        console.log("ERROR: ", error);
      }
    );
  };

  render() {
    let shareUrl = window.location.href;
    let successModal = (
      <>
        <Modal
          size="md"
          show={this.state.successModal}
          onHide={this.successModalToggle}
          aria-labelledby="successModal"
        >
          <Modal.Body>
            <div className="updateCauseModal">
              <div className="modal-top">
                <p className="modal-title">
                  Congradulation Tribe Angel, <br /> you have successfully
                  donate <br /> to the good title!
                </p>
                <button onClick={this.successModalToggle} className="close-btn">
                  X
                </button>
              </div>

              <div
                className="row modal-main-body"
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
              >
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Share your donation with your friends.
                </p>
                <div className="social-share-buttons">
                  <div className="share-btn-wrapper">
                    <FacebookShareButton url={shareUrl}>
                      <FacebookIcon round={true} size={40} />
                    </FacebookShareButton>
                  </div>
                  <div className="share-btn-wrapper">
                    <TwitterShareButton url={shareUrl}>
                      <TwitterIcon round={true} size={40} />
                    </TwitterShareButton>
                  </div>
                  <div className="share-btn-wrapper">
                    <WhatsappShareButton url={shareUrl}>
                      <WhatsappIcon round={true} size={40} />
                    </WhatsappShareButton>
                  </div>
                  <div className="share-btn-wrapper">
                    <LinkedinShareButton url={shareUrl}>
                      <LinkedinIcon round={true} size={40} />
                    </LinkedinShareButton>
                  </div>
                  <div className="share-btn-wrapper">
                    <EmailShareButton url={shareUrl}>
                      <EmailIcon round={true} size={40} />
                    </EmailShareButton>
                  </div>
                </div>

                <div className="link-copy-wrapper">
                  <p className="link-copy-text">or copy link</p>
                  <div className="link-box">
                    <p className="link-to-copy">{shareUrl}</p>
                    <CopyToClipboard
                      text={shareUrl}
                      onCopy={() => this.setState({ copied: true })}
                    >
                      <p className="copy-btn">Copy</p>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );

    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <>
        {this.props.isAuthenticated ? (
          <DashboardLayout>
            <main className="CampaignDonation">
              <DonationForm
                user={this.props.user}
                isAuthenticated={this.props.isAuthenticated}
                dashboardMode={this.props.dashboardMode}
                donateToCampaign={this.donateToCampaign}
                inputChangeHandler={this.inputChangeHandler}
                state={this.state}
                setAnonymous={this.anonymousToggle}
                validateInputs={this.vaidateInputs}
                percentForPlatform={this.state.percentForPlatform}
                handleStripeToken={this.handleStripeToken}
              />
            </main>

            {successModal}
          </DashboardLayout>
        ) : (
          <div className="notlogged">
            <div className="navbar-wrap">
              <Navbar mode="dark" />
            </div>
            <main className="CampaignDonation">
              <DonationForm
                user={this.props.user}
                isAuthenticated={this.props.isAuthenticated}
                dashboardMode={this.props.dashboardMode}
                donateToCampaign={this.donateToCampaign}
                inputChangeHandler={this.inputChangeHandler}
                state={this.state}
                setAnonymous={this.anonymousToggle}
                setRegisterMe={this.registerMeToggle}
                validateInputs={this.vaidateInputs}
                percentForPlatform={this.state.percentForPlatform}
                handleStripeToken={this.handleStripeToken}
                emailGoodToGo={this.state.emailGoodToGo}
                checkingEmailExistenceInSystem={
                  this.state.checkingEmailExistenceInSystem
                }
                responseMessage={this.state.responseMessage}
                signInUser={this.signInUser}
              />
            </main>
            {successModal}
          </div>
        )}
      </>

      // <>
      //   {this.props.isAuthenticated ? (
      //     <DashboardLayout>
      //       <main className="CampaignDonation">
      //         <DonationForm
      //           user={this.props.user}
      //           isAuthenticated={this.props.isAuthenticated}
      //           dashboardMode={this.props.dashboardMode}
      //           donateToCampaign={this.donateToCampaign}
      //           inputChangeHandler={this.inputChangeHandler}
      //           state={this.state}
      //           setAnonymous={this.anonymousToggle}
      //           validateInputs={this.vaidateInputs}
      //           percentForPlatform={this.state.percentForPlatform}
      //           handleStripeToken={this.handleStripeToken}
      //         />
      //       </main>

      //       {successModal}
      //     </DashboardLayout>
      //   ) : (
      //     <div className="notlogged">
      //       <div className="navbar-wrap">
      //         <Navbar mode="dark" />
      //       </div>
      //       <main className="CampaignDonation">
      //         <DonationForm
      //           user={this.props.user}
      //           isAuthenticated={this.props.isAuthenticated}
      //           dashboardMode={this.props.dashboardMode}
      //           donateToCampaign={this.donateToCampaign}
      //           inputChangeHandler={this.inputChangeHandler}
      //           state={this.state}
      //           setAnonymous={this.anonymousToggle}
      //           setRegisterMe={this.registerMeToggle}
      //           validateInputs={this.vaidateInputs}
      //           percentForPlatform={this.state.percentForPlatform}
      //           emailGoodToGo={this.state.emailGoodToGo}
      //           checkingEmailExistenceInSystem={
      //             this.state.checkingEmailExistenceInSystem
      //           }
      //           responseMessage={this.state.responseMessage}
      //           handleStripeToken={this.handleStripeToken}
      //           signInUser={this.signInUser}
      //         />
      //       </main>
      //       {successModal}
      //     </div>
      //   )}
      // </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    dashboardMode: state.dashboard.mode,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginUser: (user) => dispatch(loginUser(user)),
    setDashboardMode: (dashboardMode) => {
      localStorage.setItem("dashboardMode", JSON.stringify({ dashboardMode }));
      dispatch(setDashboardMode(dashboardMode));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDonation);
