import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import "./ModalEditBtn.css";

function ModalEditBtn(props) {
  if (props.isAuthenticated === true && props.userId === props.causeUserId) {
    return (
      <button className="button button-edit" onClick={props.onClick}>
        <FontAwesomeIcon icon={faPencilAlt} />
      </button>
    );
  }
  return <div></div>;
}

ModalEditBtn.propTypes = {
  isAuthenticated: PropTypes.bool,
  causeUserId: PropTypes.string,
  userId: PropTypes.string,
};

export default ModalEditBtn;
