import React, { useEffect, useState } from "react";
import Conversation from "../../../Models/Conversation";
import ChatUserCard from "../../UI/Cards/ChatUserCard/ChatUserCard";
import "./ChatContainer.css";
import ChatTimeline from "./ChatTimeline/ChatTimeline";

const ChatContainer = (props) => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  console.log(props);
  let loadAllConversations = async () => {
    let selectedConvo =
      props.match && props.match.params
        ? props.match.params.id
        : "5f86f09bb70a4551c0af87c4";
    await Conversation.FetchUserConversations()
      .then((convos) => {
        if (selectedConvo) {
          selectedConvo = convos.find((convo) => convo._id === selectedConvo);
        }
        setConversations(convos);
        setSelectedConversation(selectedConvo);
      })
      .catch((error) => {
        console.log({ error });
        alert("Could not fetch conversations at this time");
      });
  };

  useEffect(loadAllConversations, []);

  console.log({ selectedConversation: selectedConversation, conversations });
  return (
    <section className="ChatContainer">
      <div className="fluid-container">
        <div className="row">
          <div className="chat-column users-column col-12 col-lg-4 col-xl-3">
            <div className="chat-users-list">
              {conversations.map((convo) => (
                <ChatUserCard
                  key={convo._id}
                  selected={
                    selectedConversation &&
                    convo._id === selectedConversation._id
                  }
                  conversation={convo}
                  onClick={() => setSelectedConversation(convo)}
                />
              ))}
            </div>
          </div>
          <div className="chat-column timeline-cloumn col">
            {selectedConversation ? (
              <ChatTimeline
                conversation={selectedConversation}
                dashboardMode={props.dashboardMode}
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatContainer;
