import React from "react";
import "./HeroDashboard.css";
// import Radar from "../../UI/Charts/Radar/Radar";
// import BarChart from "../../UI/Charts/BarChart/BarChart";
// import ProjectCard from "../../UI/Cards/ProjectCard/ProjectCard";
// import ResponsiveTable from "../../UI/ResponsiveTable/ResponsiveTable";
// import TribeWorldMap from "../../UI/TribeWorldMap/TribeWorldMap";

const HeroDashboard = (props) => {
  return (
    <section className="HeroDashboard">
      <h1>Coming Soon</h1>
      {/* <div className="box">
        <TribeWorldMap />
      </div> */}

      {/* <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="wrap-cause">
              {props.cause ? (
                <ProjectCard for="hero" charity={props.cause} />
              ) : null}
            </div>
            <div className="mt-5">
              <ResponsiveTable
                for="angelsWhoDonated"
                headers={["Angel Name", "Donation Amonnt", "Campaign Name"]}
                records={[
                  {
                    angelName: "Tony Stark",
                    donationAmount: "$500",
                    campaignName: "Good Campaign",
                  },
                  {
                    angelName: "Tony Stark",
                    donationAmount: "$500",
                    campaignName: "Good Campaign",
                  },
                  {
                    angelName: "Tony Stark",
                    donationAmount: "$500",
                    campaignName: "Good Campaign",
                  },
                ]}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="wrap-radar">
              <Radar />
            </div>
            <div className="box">
              <BarChart />
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default HeroDashboard;
