import React, { useState, useEffect } from "react";
import PreviewGoal from "../../Dashboard/PreviewDetails/PreviewGoal/PreviewGoal";
import CauseWorldImpact from "../../Dashboard/PreviewDetails/WorldImpact/WorldImpact";
import Rewards from "../../Dashboard/PreviewDetails/Rewards/Rewards";
import DetailsSummary from "../../Dashboard/PreviewDetails/DetailsSummary/DetailsSummary";
import HeroResponsible from "../../Dashboard/PreviewDetails/HeroResponsible/HeroResponsible";
import { connect } from "react-redux";
import UpdateEnterprise from "../../Dashboard/PreviewDetails/PreviewUpdateModals/UpdateEnterprise/UpdateEnterprise";
import PreviewDetailsMain from "../../Dashboard/PreviewDetails/PreviewDetailsMain/PreviewDetailsMain";
import Hero from "../../../Models/Hero";

//images
import goalachieved from "../../../assets/images/goalachieved.png";
import dollarSack from "../../../assets/images/dollarsack.png";
import duration from "../../../assets/images/duration.png";

function EnterpriseDetailsLayout(props) {
  const [showcauseUpdateModal, setshowCauseUpdateModal] = useState(false);
  const [showShareModal, setshowShareModal] = useState(false);
  const [showGoalUpdateModal, setshowGoalUpdateModal] = useState(false);
  const [showDurationUpdateModal, setshowDurationUpdateModal] = useState(false);

  const [categories, setCategories] = useState([]);

  useEffect(
    () =>
      Hero.fetchFundraiserCategories().then((categories) =>
        setCategories(categories)
      ),
    []
  );

  console.log(props.selectedEnterprise);
  let impactParsed = null;
  if (props.selectedEnterprise.impact) {
    impactParsed = JSON.parse(props.selectedEnterprise.impact);
  }

  return (
    <div className="preview-details">
      <div className="previewDetails-wrap row">
        {/* udpates cause contains update modals */}
        <UpdateEnterprise
          dashboardMode={props.dashboardMode}
          showcauseUpdateModal={showcauseUpdateModal}
          setshowCauseUpdateModal={setshowCauseUpdateModal}
          showShareModal={showShareModal}
          setshowShareModal={setshowShareModal}
          showGoalUpdateModal={showGoalUpdateModal}
          setshowGoalUpdateModal={setshowGoalUpdateModal}
          showDurationUpdateModal={showDurationUpdateModal}
          setshowDurationUpdateModal={setshowDurationUpdateModal}
          selectedEnterprise={props.selectedEnterprise}
          categories={categories}
        />
        <div className="previewDetails-left col-12 col-lg-9 ">
          <PreviewDetailsMain
            dashboardMode={props.dashboardMode}
            isAuthenticated={props.isAuthenticated}
            user={props.user}
            campaign={props.selectedEnterprise}
            setshowCauseUpdateModal={setshowCauseUpdateModal}
            setshowShareModal={setshowShareModal}
            categories={categories}
          />

          <div className="DetailsAmount">
            <PreviewGoal
              dashboardMode={props.dashboardMode}
              isAuthenticated={props.isAuthenticated}
              setshowGoalUpdateModal={setshowGoalUpdateModal}
              user={props.user}
              icon={dollarSack}
              editbtn="true"
              name="Goal"
              amount={"$" + props.selectedEnterprise.goal}
              creatorId={props.selectedEnterprise.creatorId}
              for="goal"
            />
            <PreviewGoal
              dashboardMode={props.dashboardMode}
              isAuthenticated={props.isAuthenticated}
              user={props.user}
              icon={goalachieved}
              editbtn="false"
              name="Achieved"
              amount={"$ 0"}
            />
            <PreviewGoal
              dashboardMode={props.dashboardMode}
              isAuthenticated={props.isAuthenticated}
              setshowDurationUpdateModal={setshowDurationUpdateModal}
              user={props.user}
              icon={duration}
              editbtn="true"
              name="Duration (Days)"
              amount={props.selectedEnterprise.duration}
              creatorId={props.selectedEnterprise.creatorId}
              for="duration"
            />
          </div>
          <div className="WorldImpact-section">
            <CauseWorldImpact
              impact={(() => {
                let impactValues = [];
                let impactLables = [];
                for (let k in impactParsed) {
                  impactLables.push(k);
                  impactValues.push(impactParsed[k]);
                }
                return [impactLables, impactValues];
              })()}
              user={props.user}
            />
          </div>
          <div className="rewards-section">
            <Rewards
              isAuthenticated={props.isAuthenticated}
              user={props.user}
              enterpriseId={props.selectedEnterprise._id}
              productStage={props.selectedEnterprise.productStage}
            />
          </div>
          <div className="DetailsSummary-section">
            <DetailsSummary
              dashboardMode={props.dashboardMode}
              isAuthenticated={props.isAuthenticated}
              user={props.user}
              campaign={props.selectedEnterprise}
            />
          </div>
        </div>
        <div className="previewDetails-right col-lg-3 col-12">
          <HeroResponsible
            dashboardMode={props.dashboardMode}
            isAuthenticated={props.isAuthenticated}
            user={props.user}
            creatorId={props.selectedEnterprise.creatorId}
            categories={categories}
            campaignType="enterprise"
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    dashboardMode: state.dashboard.mode,
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
}

export default connect(mapStateToProps)(EnterpriseDetailsLayout);
