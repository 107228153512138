import React from "react";
import "./Story.css";
import ModalEditBtn from "../../../../UI/Buttons/ModalEditBtn/ModalEditBtn";
import ReactHtmlParser from "react-html-parser";

function Story(props) {
  const story = props.story;
  return (
    <div className="story-wrap">
      <div className="story-head">
        <p className="story-title">Short Summary</p>
        <ModalEditBtn />
      </div>
      <div className="story-body">{ReactHtmlParser(story)}</div>
    </div>
  );
}

export default Story;
