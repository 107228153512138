import React, { useState } from "react";
import "./CauseForm.css";
import StepsBar from "../../StepsBar/StepsBar";
import CauseStepOne from "./CauseStepOne/CauseStepOne";
import StoryForm from "../StoryForm/StoryForm";
import FAQSForm from "../FAQSForm/FAQSForm";
import CauseImpactForm from "./CauseImpactForm/CauseImpactForm";
import HeroFlowStep from "../HeroFlowStep/HeroFlowStep";

const CauseForm = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const labels = ["CAUSE DETAILS", "FAQ'S", "PHOTO,STORY,LINK", "IMPACT"];

  if (!props.isAuthenticated) {
    labels.push("SIGN UP");
  } else {
    labels.slice(-1);
  }

  console.log(props.isAuthenticated);

  function changeStep(changeType) {
    if (changeType === "next") {
      setCurrentStep(currentStep + 1);
    } else if (changeType === "prev") {
      setCurrentStep(currentStep - 1);
    }
  }

  return (
    <div className="CauseForm">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10 col-xl-10 mx-auto">
            <div className="wrapper">
              <div className="wrap-bar">
                <p className="cause-title ">Create a Cause</p>

                <StepsBar labels={labels} currentStep={currentStep} />
              </div>
              <div className="wrap-form">
                {currentStep === 1 ? (
                  <CauseStepOne
                    dashboardMode={props.dashboardMode}
                    changeStep={changeStep}
                    inputs={props.stepOneInputs}
                    inputChangeHandler={(e) =>
                      props.inputChangeHandler("stepOneInputs", e)
                    }
                  />
                ) : currentStep === 2 ? (
                  <FAQSForm
                    for="creation"
                    dashboardMode={props.dashboardMode}
                    changeStep={changeStep}
                    inputs={props.stepTwoInputs}
                    inputChangeHandler={(e) => {
                      e.persist();
                      props.inputChangeHandler("stepTwoInputs", e);
                    }}
                    createFaqHandler={props.createFaqHandler}
                    editFaqHandler={props.editFaqHandler}
                    deleteFaqHandler={props.deleteFaqHandler}
                  />
                ) : currentStep === 3 ? (
                  <StoryForm
                    for="creation"
                    dashboardMode={props.dashboardMode}
                    changeStep={changeStep}
                    inputs={props.stepThreeInputs}
                    inputChangeHandler={(e) => {
                      props.inputChangeHandler("stepThreeInputs", e);
                    }}
                    storyChangeHandler={(content) => {
                      props.storyChangeHandler(content, props.story);
                    }}
                    story={props.story}
                    // onFileInputChange={(e) =>
                    //   props.onFileInputChange(e, props.content)
                    // }
                    // image={props.image}
                    onFileInputChange={props.onFileInputChange}
                  />
                ) : currentStep === 4 ? (
                  <CauseImpactForm
                    for="creation"
                    dashboardMode={props.dashboardMode}
                    isAuthenticated={props.isAuthenticated}
                    changeStep={changeStep}
                    inputs={props.stepFourInputs}
                    onSubmitHandler={props.onSubmitHandler}
                    inputChangeHandler={(e) => {
                      if (e.target.value === "High") {
                        e.target.value = 100;
                      }
                      if (e.target.value === "Medium") {
                        e.target.value = 66.6;
                      }
                      if (e.target.value === "Low") {
                        e.target.value = 33.3;
                      }
                      if (e.target.value === "N/A") {
                        e.target.value = 0;
                      }
                      props.inputChangeHandler("stepFourInputs", e);
                    }}
                  />
                ) : currentStep === 5 ? (
                  <HeroFlowStep
                    for="creation"
                    dashboardMode={props.dashboardMode}
                    isAuthenticated={props.isAuthenticated}
                    changeStep={changeStep}
                    causeState={props.causeState}
                    onSubmitHandler={props.onSubmitHandler}
                    inputs={props.heroFlowStepInputs}
                    inputChangeHandler={(e) => {
                      props.inputChangeHandler("heroFlowStepInputs", e);
                    }}
                    createWithSignIn={props.createWithSignIn}
                    createWithRegistration={props.createWithRegistration}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CauseForm;
