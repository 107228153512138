import React from "react";
import "./RewardCard.css";

function RewardCard({ reward, onClick, isAuthenticated }) {
  return (
    <div>
      <p className="reward-description">{reward.description}</p>
      <div className="reward-quantity">
        <div className="row">
          <div className="col-4">
            <p className="quantity-headings">Quantity</p>
            <p className="quantity-values">{reward.quantity}</p>
          </div>
          <div className="col-4">
            <p className="quantity-headings">Price</p>
            <p className="quantity-values">${reward.price}</p>
          </div>
          <div className="col-4">
            <p className="quantity-headings">Retail Price</p>
            <p className="quantity-values">${reward.retailPrice}</p>
          </div>
        </div>
      </div>
      <div className="reward-bottom">
        <p className="rewards-heading">
          Delivery (in days): {reward.deliveryDays}
        </p>
        {isAuthenticated ? (
          <button className="edit-reward" onClick={onClick}>
            Edit
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default RewardCard;
