import React, { Component } from "react";
import { connect } from "react-redux";
import sweetalert from "sweetalert";
import Hero from "../../../../Models/Hero";
import DashboardLayout from "../../../../components/Layouts/DashboardLayout/DashboardLayout";
import CharitiesList from "../../../../components/Sections/CharitiesList/CharitiesList";

class ManageCauses extends Component {
  state = {
    causes: [],
  };

  componentDidMount() {
    this.getCauses();
  }

  getCauses = () => {
    Hero.fetchCauses(this.props.user._id)
      .then((data) => this.setState({ causes: data.reverse() }))
      .catch((err) =>
        sweetalert(
          "Error",
          "Error occured while fetching Manage Causes.",
          "error"
        )
      );
  };

  render() {
    return (
      <DashboardLayout>
        <main className="ManageCauses">
          <CharitiesList
            title="Manage Causes"
            charities={this.state.causes}
            campaign="cause"
          />
        </main>
      </DashboardLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(ManageCauses);
