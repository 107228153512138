import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassStart } from "@fortawesome/free-solid-svg-icons";
import "./EnterpriseMarker.css";
import { Link } from "react-router-dom";
import { Popover, Overlay } from "react-bootstrap";

function EnterpriseMarker({ enterprise }) {
  //overlay
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <>
      <div ref={ref}>
        <div className="campaign_marker" onClick={handleClick}>
          <FontAwesomeIcon
            icon={faHourglassStart}
            style={{ fontSize: "30px", color: "#0067ff" }}
          />
        </div>
        <Overlay show={show} target={target} placement="top" container={ref}>
          <Popover id="popover-contained">
            <Popover.Body>
              <div className="campaign-details ">
                <div className="about-campaign ">
                  <p className="campaign-title">
                    <span>Title</span>: {enterprise.title}
                  </p>
                  <p className="description">
                    <span>Desc</span>: {enterprise.shortDescription}
                  </p>
                  <p className="description">
                    <span>Goal</span>: ${enterprise.goal}
                  </p>

                  <p className="view-campaign">
                    <Link to={`enterprise/details/${enterprise._id}`}>
                      View Enterprise
                    </Link>
                  </p>
                </div>
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </>
  );
}

export default EnterpriseMarker;
