import React, { useState } from "react";
import "./SectionCard.css";
import PropTypes from "prop-types";
import Title from "../../Typography/Title/Title";
import PrimaryBtn from "../../Buttons/PrimaryBtn/PrimaryBtn";
import { Slide } from "react-reveal";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import HeroAngelModal from "../../Modals/HeroAngelModal/HeroAngelModal";

const SectionCard = (props) => {
  const [show, setshow] = useState(false);
  const handleShow = () => {
    setshow(!show);
  };

  const sectionClassName = [
    "SectionCard",
    props.isReversed ? "reversed" : null,
  ];
  const headSlideEffect = {};
  const bodySlideEffect = {};

  if (props.isReversed) {
    headSlideEffect.right = true;
    bodySlideEffect.left = true;
  } else {
    headSlideEffect.left = true;
    bodySlideEffect.right = true;
  }

  return (
    <section className={sectionClassName.join(" ")}>
      <HeroAngelModal handleShow={handleShow} show={show} />
      <div className="container">
        <div className="row">
          <div className="col-11 col-sm-8 col-md-12 col-lg-10 col-xl-11 mx-auto">
            <div className="wrapper">
              <div className="content">
                <Slide {...headSlideEffect}>
                  <div className="head">
                    <div className={`wrap-img ${props.imgSize}`}>
                      <img src={props.img} alt="SectionCardImg" />
                    </div>
                  </div>
                </Slide>
                <Slide {...bodySlideEffect}>
                  <div className="body">
                    <div className="title-box">
                      <Title size="md" color="dark" isResponsive={true}>
                        {props.title}
                      </Title>
                    </div>
                    <div className="text-box">{props.children}</div>
                    {props.list ? (
                      <div className="steps-list">
                        <ol>
                          {props.list.map((value) => (
                            <li>{value}</li>
                          ))}
                        </ol>
                      </div>
                    ) : null}
                    {props.btnText && props.for === "angel" ? (
                      <div className="btn-wrap mt-3">
                        <PrimaryBtn
                          type="link"
                          size="md"
                          color="yellow"
                          onClick={handleShow}
                          to="/charities"
                          effect="hvr-grow-shadow"
                        >
                          {props.btnText}
                        </PrimaryBtn>
                      </div>
                    ) : (
                      <div className="btn-wrap mt-3">
                        <PrimaryBtn
                          type="button"
                          size="md"
                          color="yellow"
                          onClick={handleShow}
                          effect="hvr-grow-shadow"
                        >
                          {props.btnText}
                        </PrimaryBtn>
                      </div>
                    )}
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SectionCard.propTypes = {
  isReversed: PropTypes.bool.isRequired,
  img: PropTypes.any.isRequired,
  imgSize: PropTypes.oneOf(["md", "lg"]).isRequired,
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string),
  btnText: PropTypes.string,
};

export default withRouter(SectionCard);
